import * as React from 'react'
import { Link } from 'react-router-dom'
import { useAppContext } from '../../AppContext'
import { IProfileMenuOptions } from './types'

export const ProfileMenuItem: React.FC<IProfileMenuOptions> = ({
    title,
    styles,
    path,
    icon,
    isAction,
    preClick,
}) => {
    const app = useAppContext()
    if (isAction && path === '/logout') {
        return (
            <button onClick={app.signOut} className={`${styles} text-left`}>
                {icon && <div className="mr-1">{icon}</div>}
                <div className="align-middle text-left">{title}</div>
            </button>
        )
    }
    return (
        <>
            <Link to={path} className={styles} 
                onClick={() => {
                    preClick
                }}>
                {icon && <div className="mr-1">{icon}</div>}
                <div className="align-middle text-left">{title}</div>
            </Link>
        </>
    )
}
