import { Suspense, useCallback } from 'react'
import React from 'react'
import { TourToolTip } from './TourToolTip'

const Joyride = React.lazy(() => import('react-joyride'))

const steps = [
    {
        target: '[data-info="search"]',
        content: [{
            text: 'Search for', subText: ['Who: People and profiles',
                'What: Projects and applications',
                ' How: Files in ASK and Innovation Pillar, or other SharePoint locations',]
        }, { text: 'Type to see suggestions, and press enter to see more results', subText: [] }
        ],
        disableBeacon: true,
        title: 'Search',
    },
    {
        target: '[data-info="notifications"]',
        content: [
            'User Notifications',
            'A Green Dot Appears on the bell icon indicating new notifications',
            'Click on the bell icon to view the notifications',
            'Click on the notification to view the details',
        ],
        disableBeacon: true,
        title: 'Notifications',
    },
    {
        target: '[data-info="messages"]',
        content: [
            'MS Teams chat',
            'Click on this icon to view the messages',
            'Click on the message to view the conversation and interact on that chat stream',
        ],
        disableBeacon: true,
        title: 'Messages',
    },
    {
        target: '[data-info="feedback"]',
        content: [
            'Application Level Feedback',
            'Click on this icon to provide Application level feedback',
            'Feedback can be provided for each page / application being accessed',
            'The Konnnect Portal provides access to multiple applications and each application has its own feedback form',
            'Like , Dislike, Comment options available to provide feedback',
        ],
        disableBeacon: true,
        title: 'Application Feedback',
    },
    {
        target: '[data-info="account"]',
        content: [
            'User Account Information',
            'View your profile, settings, and logout',
        ],
        disableBeacon: true,
        title: 'User Account',
    },
    {
        target: '[data-info="home"]',
        content: [
            'This is your initial page where you can see recent apps and search in ASK',
        ],
        disableBeacon: true,
        title: 'Home Tab',
    },
    {
        target: '[data-info="my-space"]',
        content: [
            ' Click here to see your personalized dashboard.',
        ],
        disableBeacon: true,
        title: 'My work space Tab',
    },
    {
        target: '[data-info="side-nav"]',
        content: [
            'Based on the user role, the side navigation will be different',
            'Click on the side navigation to view the respective applications you have access to',
            'Provide Application Level Feedback',
            'Access different applications in one unified portal',
            'As new applications are added, the side navigation will be updated based on the user role',
            'If there are more applications the "..." icon will appear',
            'Click on the "..." icon to view more applications you have access to',
        ],
        disableBeacon: true,
        title: 'Application Selection Navigation Side Bar',
    },
    {
        target: '[data-info="dashboard-edit"]',
        content: [
            'Multiple Widget Based Applications are provided in a dashboard view on the landing page',
            'A preconfigured dashboard with recommended widgets is provided for all users',
            'The user can customize the dashboard and the application widgets available to them',
            'Click on the edit icon to edit the dashboard',
            'After editing the dashboard, the user can explore the available widgets by pressing the "Explore Widgets" button and add them to the dashboard',
            'Use the drag and drop feature to rearrange the widgets',
            'Click on the save icon to save the changes',
            'Click on the cancel icon to discard the changes',
            'Ciick on restore to revert to the original dashboard',
        ],
        disableBeacon: true,
        title: 'My Dashboard',
    },
    {
        target: '[data-info="applications-widget"]',
        content: [
            'This widget provides a list of applications available to the user',
            'Click on the application to navigate to the application',
        ],
        disableBeacon: true,
        title: 'Application Widget',
    },
    {
        target: '[data-info="communication-widget"]',
        content: [
            'This widget provides your inbox top 10 mails',
            'Access the mail specific context menu by right-clicking on the mail',
            '"..." icon will appear on hovering on the mail',
            'Click on the "..." icon to view the mail specific context menu',
        ],
        disableBeacon: true,
        title: 'Communication Widget',
    },
    {
        target: '[data-info="mod-widget"]',
        content: [
            'This widget provides current message of the day and upcoming events',
        ],
        disableBeacon: true,
        title: 'Message Of The Day Widget',
    },
    {
        target: '[data-info="weekly-calendar-widget"]',
        content: [
            'This widget provides a calendar view of the upcoming events of the current week',
            'Access the event specific context menu by right-clicking on the mail',
            '"..." icon will appear on hovering on the event',
            'Click on the "..." icon to view the event specific context menu',
        ],
        disableBeacon: true,
        title: 'My Weekly Calendar Widget',
    },
    {
        target: '[data-info="insights-widget"]',
        content: [
            'This widget provides a list of relevent files available to the user',
            'Click on the download icon to download the respective files',
        ],
        disableBeacon: true,
        title: 'Insights Widget',
    },
    {
        target: '[data-info="tasks-widget"]',
        content: [
            'This widget provides access to the tasks assigned to the user',
            'The Task sources could be "MS Todo", "MS Planner", "Project Management"',
            'You can manage (create, edit, delete) the tasks from this widget',
        ],
        disableBeacon: true,
        title: 'My Tasks Widget',
    },
    {
        target: '[data-info="widget-feedback"]',
        content: ['Widgets'],
        disableBeacon: true,
        title: 'Give Feedback For Widgets',
    },
    {
        target: '[data-info="settings"]',
        content: [
            'View all the user preferences and feedback data for the portal',
            'Click on the specific tabs for more details',
        ],
        disableBeacon: true,
        title: 'Give Feedback For Widgets',
    },
]
export interface ProductTourProps {
    onClose: (isLastStep: boolean) => void
    run: boolean
}

export const TourDetails: React.FC<ProductTourProps> = ({ onClose, run }) => {
    const handleJoyrideCallback = useCallback((data: any) => {
        const { status, action } = data
        if (['finished', 'skipped'].includes(status) || ['close'].includes(action)) {
            const isLastStep = status === 'finished'
            onClose(isLastStep)
        }
    }, [])

    return (
        <div className="h-screen">
            <Suspense fallback={<div>Loading...</div>}>
                <Joyride
                    steps={steps}
                    run={run}
                    continuous={true}
                    showProgress={true}
                    scrollToFirstStep={true}
                    disableScrolling={true}
                    tooltipComponent={TourToolTip}
                    callback={handleJoyrideCallback}
                    styles={{
                        options: {
                            zIndex: 9999999999,
                            primaryColor: '#ff6a12',
                        },
                    }}
                />
            </Suspense>
        </div>
    )
}
