import React, { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import {
    BellIcon,
} from '@heroicons/react/outline'
import Vectornotify from '@/assets/Vectornotify.jpg'
import { useStore } from '@/store/store'
import { Notification, callMarkNoficationAsRead } from '@/services/UOPService'
import { useAppContext } from '@/AppContext'
import { useMsal } from '@azure/msal-react'
import { getAppConfig } from '@/Config'
import { formatDistanceToNowStrict } from 'date-fns'
import { getLinkType } from '@/utilities/getLinkType'
import { Avatar } from '../atoms/Avatar'
import Tippy from '@tippyjs/react'
import useAnalyticsEventTracker from '@/hooks/useAnalyticsEventTracker'
import { GAAction } from '@/types/GAnalyticsTypes'

const IntermixNotificationMenu: React.FC<any> = () => {
    const loginRequest = { scopes: getAppConfig().scopes }
    const app = useAppContext()
    const { instance, accounts } = useMsal()
    const [events, setEvents] = React.useState<Notification[]>([])
    const getUnreadNotifications = useStore(
        React.useCallback(state => state.getUnreadNotifications, []),
    )
    let notifications = useStore(state => state.notifications)

    const markReadNotification = useStore(
        React.useCallback(state => state.markReadNotification, []),
    )

    const markUnReadNotification = useStore(
        React.useCallback(state => state.markUnReadNotification, []),
    )
    const gaEventTracker = useAnalyticsEventTracker('Header Notification')

    React.useEffect(() => {
        const events = getUnreadNotifications()
        setEvents(events)
    }, [notifications])

    const handleMarkAsRead = (id: string) => {
        if (app.user) {
            try {
                gaEventTracker(
                    GAAction.COMPONENT_USED,
                    'Notification Mark Read',
                )
                markReadNotification(id)
                instance
                    .acquireTokenSilent({
                        ...loginRequest,
                        account: accounts[0],
                    })
                    .then((response: { accessToken: string }) => {
                        callMarkNoficationAsRead(response.accessToken, id).then(
                            response => {
                                if (response.originalError) {
                                    markUnReadNotification(id)
                                }
                            },
                            error => {
                                console.log(error)
                                markUnReadNotification(id)
                            },
                        )
                    })
            } catch (err: any) {
                console.log(err)
                markUnReadNotification(id)
            }
        }
    }

    const handleMarkAllAsRead = () => {
        notifications.map(notification => {
            try {
                if (!notification.read) {
                    markReadNotification(notification.id)
                }
            } catch (err: unknown) {
                console.log(err)
            }
        })
        notifications = []
    }

    return (
        <div className="relative inline-block" data-info="notifications">
            <Menu as="div" className="text-left ">
                {({ open }) => (
                    <>
                        <Tippy content="Notifications">
                            <Menu.Button className="flex p-1 bg-baby-gray-200 shadow-md relative text-baby-gray-50 hover:text-primary dark:text-secondary-100 focus:outline-none align-middle h-9 rounded-full hover:bg-baby-gray-200  dark:bg-gray-800 transition duration-300 ease-in-out transform hover:scale-105" style={{ marginTop: "4px" }}>
                                <span
                                    className="p-1"
                                    onClick={() => {
                                        gaEventTracker(
                                            GAAction.COMPONENT_USED,
                                            'Clicked',
                                        )
                                    }}
                                >
                                    <NotificationIcon
                                        hasNotifications={
                                            events && events.length > 0
                                        }
                                    />
                                </span>
                            </Menu.Button>
                        </Tippy>
                        {notifications && (
                            <Transition
                                as={Fragment}
                                show={open}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items className="absolute  right-0 w-96 mt-3 origin-top-right bg-white dark:bg-gray-800 divide-y divide-gray-100 rounded shadow focus:outline-none">
                                    <div className="flex flex-row justify-between items-center px-3 py-3">
                                        <p className="text-xl font-bold">
                                            Notifications
                                        </p>
                                        {/* <button
                                            className="text-sm text-gray-500 hover:bg-gray-200 rounded p-1"
                                            onClick={handleMarkAllAsRead}
                                        >
                                            Clear
                                        </button> */}
                                    </div>
                                    <div className="text-xs">
                                        {notifications
                                            .sort((a, b) =>
                                                a.read === b.read
                                                    ? 0
                                                    : a.read
                                                        ? 1
                                                        : -1,
                                            )
                                            .map(
                                                ({
                                                    id,
                                                    type,
                                                    read,
                                                    title,
                                                    from,
                                                    description,
                                                    link,
                                                    triggerDate,
                                                }) => {
                                                    const linkType =
                                                        getLinkType(link)
                                                    return (
                                                        <Menu.Item key={id}>
                                                            {({ active }) => (
                                                                <div
                                                                    key={id}
                                                                    className="w-full border-b py-3 pl-3 hover:bg-gray-50 dark:hover:bg-gray-800 dark:bg-gray-900 dark:text-gray-100 dark:border-gray-800 transition-all cursor-pointer"
                                                                    onClick={() => {
                                                                        handleMarkAsRead(
                                                                            id,
                                                                        )
                                                                    }}
                                                                >
                                                                    <div className="flex flex-row justify-between items-center w-full">
                                                                        <div className="text-sm flex flex-row items-center w-9/12">
                                                                            <div className="flex flex-row items-center mr-2 relative">
                                                                                <div className="flex flex-row justify-center items-center w-14 h-14 text-left text-sm text-orange-900 font-bold 2 relative rounded-full p-2">
                                                                                    <Avatar
                                                                                        name={
                                                                                            from
                                                                                        }
                                                                                        userId={
                                                                                            id
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                                {!read && (
                                                                                    <>
                                                                                        <div className="absolute top-1 left-1 w-2 h-2 bg-orange-600 rounded-full animate-ping"></div>
                                                                                        <div className="absolute top-1 left-1 w-2 h-2 bg-orange-600 rounded-full"></div>
                                                                                    </>
                                                                                )}
                                                                            </div>
                                                                            <div className="flex flex-col items-start">
                                                                                <p>
                                                                                    {
                                                                                        title
                                                                                    }
                                                                                </p>
                                                                                <p className="text-sm text-gray-500">
                                                                                    {
                                                                                        description
                                                                                    }
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="text-sm w-3/12 text-right mr-2 text-gray-500">
                                                                            {formatDistanceToNowStrict(
                                                                                new Date(
                                                                                    triggerDate,
                                                                                ),
                                                                                {
                                                                                    addSuffix:
                                                                                        true,
                                                                                },
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Menu.Item>
                                                    )
                                                },
                                            )}
                                    </div>
                                    {notifications.length == 0 && (
                                        <div>All clear!</div>
                                    )}
                                </Menu.Items>
                            </Transition>
                        )}
                    </>
                )}
            </Menu>
        </div>
    )
}

const NotificationIcon: React.FC<any> = React.memo(({ hasNotifications }) => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" fill="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
            </svg>

            {/* <img src={Vectornotify} alt="logo" className=" w-3 h-4 " /> */}
            {hasNotifications && (
                <>
                    <div className="absolute bottom-0.5 right-0.5 w-2 h-2 bg-primary rounded-full animate-ping"></div>
                    <div className="absolute bottom-0.5 right-0.5 w-2 h-2 bg-primary rounded-full"></div>
                </>
            )}
        </>
    )
})

export default IntermixNotificationMenu
