import { useAppContext } from '@/AppContext'
import { getAppConfig } from '@/Config'
import {
    callCreateChat,
    callGetConversations,
    callGetMessages,
    callSendMessage,
} from '@/services'
import { AuthenticatedTemplate, useMsal } from '@azure/msal-react'
import { differenceInSeconds } from 'date-fns/esm'
import { useEffect, useState } from 'react'
import { Conversation } from './Conversation'
import { MessagesContext } from './MessageContext'
import { Chat, Message, MessagesList } from './MessagesList'

export const MessagesWidget: React.FC = () => {
    const [chats, setChats] = useState<Chat[]>([])
    const [loading, setLoading] = useState<boolean>(false)

    const ctxChat = (chatId: Chat[`id`]): Chat => {
        return chats.find(c => c.id === chatId)!
    }
    const openChat = (chatId: Chat[`id`]): void => {
        setChats(
            chats.map(c => {
                if (c.id === chatId) {
                    c.opened = true
                    c.minimized = false
                }
                return c
            }),
        )
    }
    const closeChat = (chatId: Chat[`id`]): void => {
        setChats(
            chats.map(c => {
                if (c.id === chatId) {
                    c.opened = false
                    c.minimized = false
                }
                return c
            }),
        )
    }
    const maximizeChat = (chatId: Chat[`id`]): void => {
        setChats(
            chats.map(c => {
                if (c.id === chatId) c.minimized = false
                return c
            }),
        )
    }
    const minimizeChat = (chatId: Chat[`id`]): void => {
        setChats(
            chats.map(c => {
                if (c.id === chatId) c.minimized = true
                return c
            }),
        )
    }
    const loadMessages = (chatId: Chat[`id`]): void => {
        try {
            msal.acquireTokenSilent({
                ...loginRequest,
                account: accounts[0],
            }).then(response => {
                callGetMessages(chatId, response.accessToken).then(
                    response => {
                        if (response) {
                            setChats(
                                chats.map(c => {
                                    if (c.id === chatId) c.messages = response
                                    return c
                                }),
                            )
                        }
                    },
                    error => {
                        console.error(error)
                    },
                )
            })
        } catch (err: any) {
            console.log(err)
        }
    }
    const getMessages = (chatId: Chat[`id`]): Message[] => {
        return ctxChat(chatId).messages.sort(
            (a, b) =>
                new Date(b.createdDateTime).getTime() -
                new Date(a.createdDateTime).getTime(),
        )
    }
    const sendMessage = (chatId: Chat[`id`], message: string): void => {
        try {
            msal.acquireTokenSilent({
                ...loginRequest,
                account: accounts[0],
            }).then(response => {
                callSendMessage(chatId, message, response.accessToken).then(
                    response => {
                        loadMessages(chatId)
                        return response
                    },
                    error => {
                        console.error(error)
                    },
                )
            })
        } catch (err: any) {
            console.log(err)
        }
    }
    const createChat = (userId: string): void => {
        try {
            msal.acquireTokenSilent({
                ...loginRequest,
                account: accounts[0],
            }).then(response => {
                callCreateChat(userId, response.accessToken).then(
                    response => {
                        loadChats()
                        return response
                    },
                    error => {
                        console.error(error)
                    },
                )
            })
        } catch (err: any) {
            console.log(err)
        }
    }

    const app = useAppContext()
    const { instance: msal, accounts } = useMsal()
    const loginRequest = { scopes: getAppConfig().scopes }
    useEffect(() => {
        if (app.user) {
            loadChats()
        }
    }, [app.user])

    const loadChats = async () => {
        try {
            if (!chats) setLoading(true)
            msal.acquireTokenSilent({
                ...loginRequest,
                account: accounts[0],
            }).then(response => {
                callGetConversations(response.accessToken).then(
                    response => {
                        if (response && response.length > 0) {
                            const chatsResponse: Chat[] = response
                                ?.map(c => {
                                    return {
                                        ...c,
                                        messages:
                                            chats?.find(
                                                chat => chat.id === c.id,
                                            )?.messages ?? c.messages,
                                        opened:
                                            chats?.find(
                                                chat => chat.id === c.id,
                                            )?.opened ?? c.opened,
                                        minimized:
                                            chats?.find(
                                                chat => chat.id === c.id,
                                            )?.minimized ?? c.minimized,
                                    }
                                })
                                .sort((a, b) => {
                                    let aDate, bDate
                                    if (b.messages.length > 0) {
                                        bDate = new Date(
                                            b.messages[0].lastEditedDateTime,
                                        )
                                    } else {
                                        bDate = new Date(b.lastUpdatedDateTime)
                                    }
                                    if (a.messages.length > 0) {
                                        aDate = new Date(
                                            a.messages[0].lastEditedDateTime,
                                        )
                                    } else {
                                        aDate = new Date(a.lastUpdatedDateTime)
                                    }

                                    return differenceInSeconds(aDate, bDate)
                                })

                            setChats(chatsResponse)
                            setLoading(false)
                        }
                    },
                    error => {
                        console.error(error)
                    },
                )
            })
        } catch (err: any) {
            console.log(err)
        }
    }

    return (
        <AuthenticatedTemplate>
            <MessagesContext.Provider
                value={{
                    chats,
                    setChats,
                    ctxChat,
                    loading,
                    setLoading,
                    openChat,
                    closeChat,
                    maximizeChat,
                    minimizeChat,
                    loadMessages,
                    getMessages,
                    sendMessage,
                    createChat,
                }}
            >

                {
                    chats.filter(chat => chat.opened).length ? <div className="w-11/12  fixed bottom-0 right-4 flex flex-row justify-end items-end transition-all  ">
                        <div className=' flex flex-row  overflow-x-scroll '>
                            {chats
                                .filter(chat => chat.opened)
                                .map((chat, index) => {
                                    return <Conversation key={index} chatId={chat.id} />
                                })}
                        </div>
                    </div> : null
                }
                <MessagesList />
            </MessagesContext.Provider>
        </AuthenticatedTemplate>
    )
}
