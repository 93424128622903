import React, { useEffect } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { useProfileStore, useSearchKeywordsStore } from '@/store/store'
import { getSuggestion, getSearchResults, abortSuggestionAPI } from '../services/SearchService'
import { useAppContext } from '../AppContext'
import { getAppConfig } from '@/Config'
import { useMsal } from '@azure/msal-react'
import { minDisplayRecordLength, maxDisplayRecordLength, searchDataType } from '../utilities/SearchResults';
import { getUserDetails } from '@/services'

export const useAutoComplete = (minKeywordLenght = 3) => {
    const [keyword, setKeyword] = React.useState<string>('')
    const [results, setResults] = React.useState<any[]>([])
    const [searching, setSearching] = React.useState<boolean>(false)
    const searchKeywordsStore = useSearchKeywordsStore()
    const [showSavedKeywords, setShowSavedKeywords] = React.useState(false)
    const [showSearchedResults, setSearchedResults] = React.useState([])
    const [isSuggestionSearch, setIsSuggestionSearch] = React.useState(false)
    const [isResultSearch, setIsResultSearched] = React.useState(false)
    const [users, setUsers] = React.useState<any[]>([])
    const [showUsers, setShowUsers] = React.useState(false)
    const loginRequest = { scopes: getAppConfig().scopes }
    const { user, authProvider } = useAppContext()
    const [advanceSearchedResults, setAdvancedSearchedResults] = React.useState<searchDataType[]>([])
    const [advanceSearchedLoader, setAdvancedSearchedLoader] = React.useState(false)
    const [apiCounter,setApiCounter] = React.useState<number>(0);

    const setLoading = (isLoading: boolean) =>{
        if(isLoading){
            setApiCounter(count=>count+1)
        }else{
            setApiCounter(count=>count> 0 ? count-1 : 0)
        }
    }

    useEffect(()=>{
        setSearching(apiCounter!==0);
    },[apiCounter,setSearching])

    const restoreDefaults = useProfileStore(
        React.useCallback(state => state.restoreDefaults, []),
    )
    const { instance, accounts } = useMsal()

    const handleSavedListEvent = (
        e:
            | React.MouseEvent<HTMLInputElement, MouseEvent>
            | React.KeyboardEvent<HTMLInputElement>,
    ) => {
        if (
            e.type === 'mousedown' ||
            (e as React.KeyboardEvent)?.key === 'ArrowDown'
        ) {
            if (showSearchedResults.length == 0) setShowSavedKeywords(true)
        }
    }

    const updateQuery = (query: string, update = false) => {
        setShowSavedKeywords(update)
        updateField('keyword', query, update)
        if (!update) {
            updateField('results', [])
        }
    }

    const cancelSearch = () => {
        setShowSavedKeywords(false)
        updateField('keyword', '')
        setSearchedResults([])
        setIsSuggestionSearch(false)
        restoreDefaults()
        setLoading(false);
    }

    const profileSearch = (text?: string) => {
        if (text) setKeyword(text)
        setShowSavedKeywords(false)
        setIsSuggestionSearch(false)
        setIsResultSearched(false)
    }

    const updateField = async (field: any, value: any, update = true) => {
        setSearchedResults([])
        setIsResultSearched(false)
        if (field === 'keyword') {
            setKeyword(value)
        } else if (field === 'results') {
            setResults(value)
        }
         if (update)  await onSearch(value)
    }

    const onSearch = useDebouncedCallback(async (text: string) => {
        let results: any[] = []
        if (text !== '' && !isResultSearch) {
            try {
                setLoading(true);
                const token: string = await getToken()
                results = await getSuggestion(token, text, authProvider!)
                setIsSuggestionSearch(true)
            } catch (err) {
                console.log((err as any).message)
            }
            setLoading(false)
        }
        setResults(results)
    }, 500)

    const showResult = async (text: string, page: number, email?: string) => {
        try {
            abortSuggestionAPI()
            setIsSuggestionSearch(false)
            setIsResultSearched(true)
            setKeyword(text)
            setShowSavedKeywords(false)
            setResults([])
            setLoading(true)
            page = page || 1
            const token: string = await getToken()
            const results: any = await getSearchResults(
                token,
                text,
                page,
                authProvider!,
                email || ''    
            )
            if (results.length > 0 && text.length >= minKeywordLenght) {
                searchKeywordsStore.addKeyword(text, email || text)
            }
            setLoading(false)
            setSearchedResults(results)
        } catch (err) {
            console.log((err as any).message)
        }
    }

    const getToken = (): Promise<string> => {
        return new Promise(async (resolve, reject) => {
            if (user) {
                try {
                    instance
                        .acquireTokenSilent({
                            ...loginRequest,
                            account: accounts[0],
                        })
                        .then(response => {
                            resolve(response.accessToken)
                        })
                } catch (err) {
                    reject(err)
                }
            } else {
                resolve('')
            }
        })
    }

    const searchResultExpandCollapseHandler = (category: string, operation: string) => {
        const newData: any = [...showSearchedResults];
        const index = newData.findIndex((item: any) => item.category === category);
        switch (operation) {
            case 'more':
                newData[index].displayRecordLength = maxDisplayRecordLength;
                break;
            case 'less':
                newData[index].displayRecordLength = minDisplayRecordLength;
                break;
        }
        setSearchedResults(newData);
    }


    /** find users */

    const onUserSearch = useDebouncedCallback(async (text: string) => {
        let results: any[] = []
        if (text !== '') {
            try {
                const searchTextArr = text.split(";");
                let searchText = text;
                if (searchTextArr.length > 1) {
                    if (searchTextArr[searchTextArr.length - 1] === "") {
                        setShowUsers(false);
                        return;
                    }
                    searchText = searchTextArr[searchTextArr.length - 1]
                }
                setLoading(true);
                results = await getUserDetails(
                    authProvider!,
                    searchText,
                    ['givenName', 'surname', 'userPrincipalName']
                )
                setLoading(false);
                setUsers(results);
                results.length ? setShowUsers(true) : setShowUsers(false)
            } catch (err) {
                console.log((err as any).message)
            }
        } else {
            setUsers([]);
            setShowUsers(false);
        }
    }, 500)

    const resetUserSearch = () => {
        setShowUsers(false);
    }

    const advanceSearch = async (text: string, page: number, email: string, type: string) => {
        const token: string = await getToken()
        setAdvancedSearchedLoader(true);
        const results: searchDataType[] = await getSearchResults(
            token,
            text,
            page,
            authProvider!,
            email || '',
        )
        setAdvancedSearchedLoader(false);
        setAdvancedSearchedResults(results);
    }

    const updateAdvancedSearch = (data: searchDataType[]) => {
        setAdvancedSearchedResults(data);
    }

    return {
        results,
        keyword,
        searching,
        showSavedKeywords,
        updateQuery,
        cancelSearch,
        updateField,
        handleSavedListEvent,
        showSearchedResults,
        showResult,
        isSuggestionSearch,
        isResultSearch,
        profileSearch,
        searchResultExpandCollapseHandler,
        onUserSearch,
        users,
        resetUserSearch,
        showUsers,
        advanceSearchedResults,
        advanceSearch,
        updateAdvancedSearch,
        advanceSearchedLoader,
        getToken
    }
}
