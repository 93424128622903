import { useEffect, useRef, useState } from 'react'
import { DotsVerticalIcon, XIcon, MenuAlt1Icon, ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/solid'
import { IntermixLinkProps, ISideNavMenuOptions } from './types'
import Portal from '@/hoc/Portal'
import { IntermixAdditionalLinks } from './IntermixAdditionalLinks'
import { useApplicationPreferencesStore } from '@/store/store'
import { useDebounce } from '@/hooks/useDebounce'
import { useDebouncedCallback } from 'use-debounce'
import eventEmitter from '@/utilities/eventEmitter'
import { getLeftMenuOrder, setLeftMenuOrder } from '@/services/AuthService'
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { VisiableNavList } from './VisiableNavList'
import { LeftBottomMenu } from '../icons/LeftBottomMenu'
import { BottomRightmenu } from '../icons/BottomRightmenu'


const DISPLAY_MENU_ID = "displayMenu";
const ADDITIONAL_MENU_ID = "additionalMenu";

export interface IntermixSideNavListProps {
    sideNavLinks: IntermixLinkProps[]
    SideNavComponent: React.ComponentType<ISideNavMenuOptions>
    open: boolean
    linkUri: string
    onSidebarOpen: (flag: boolean) => void
    onToggleSideNav: () => void

}
export const IntermixSideNavList: React.FC<IntermixSideNavListProps> = ({
    sideNavLinks,
    SideNavComponent,
    open,
    linkUri,
    onSidebarOpen,
    onToggleSideNav,

}) => {

    const ref = useRef(null);
    const refMoreLinks = useRef<HTMLDivElement>(null);
    const debouncedOpen = useDebounce(open, 300)
    const currentAppViewed = useApplicationPreferencesStore(
        store => store.currentAppViewed,
    )
    const [displayLinks, setDisplayLinks] = useState<IntermixLinkProps[]>([])
    const [additionalLinks, setAdditionalLinks] = useState<IntermixLinkProps[]>(
        [],
    )
    const [openAdditionalLinks, setOpenAdditionalLinks] =
        useState<boolean>(false)

    const additionalMenuActive = () => {
        return additionalLinks.find(item => decodeURI(linkUri.toLowerCase(),
        ).startsWith(item.path.toLowerCase()))
    }
    const [sideNavLists, setSideNavLists] = useState(sideNavLinks);

    const [hiddenApps, setHiddenApps] = useState<any[]>([]);

    const [count, setCount] = useState(false);
    const [visibleAppCount, setVisibleAppCount] = useState<number>(1)

    useEffect(() => {
        manageSideLinks()

    }, [sideNavLists])

    useEffect(() => {
        eventEmitter.addListener("closeMoreApps", handleClickOutside);
        return () => {
            eventEmitter.removeListener("closeMoreApps", handleClickOutside);
        }
    }, [])

    useEffect(() => {

        (async () => {
            const leftMenu: any = await getLeftMenuOrder();
            if (leftMenu && leftMenu.content && leftMenu.content.length) {
                const copyNavLink = [...sideNavLinks];
                const sortingMenu = leftMenu.content;
                sortingMenu.reverse();
                const sortedNavlinks = copyNavLink.sort(
                    (a, b) =>
                        sortingMenu.findIndex((item: any) => item.appId === b.id) -
                        sortingMenu.findIndex((item: any) => item.appId === a.id)
                )
                /** store the hidden apps */
                setHiddenApps(
                    sortingMenu
                        .filter((item: any) => item.hide === true || item.hide === 1)
                        .map((item: any) => item.appId)
                )
                /** map hide and show */
                sortedNavlinks.map((item: any) => {
                    const findIndex = sortingMenu.findIndex((data: any) => data.appId === item.id)
                    if (findIndex > -1) {
                        item.hide = (sortingMenu[findIndex].hide === true || sortingMenu[findIndex].hide === 1) ? true : false;
                    }
                    return item;
                })
                setSideNavLists(sortedNavlinks);
            } else {
                setSideNavLists(sideNavLinks);
            }
        })()
        //store the hidden app id
    }, [sideNavLinks])

    useEffect(() => {
        if (!count) {
            if (displayLinks.length > 0)
                setCount(true);
            handleDimension();
            window.addEventListener(
                'resize',
                handleDimension);
        }
    }, [displayLinks])

    const handleDimension: any = useDebouncedCallback(() => {
        const applicationHeight = window.innerHeight;

        const pageHeight = applicationHeight - 56; // remove header height
        const eachAppSize = 56;
        const maximumApps = Math.floor(pageHeight / eachAppSize) - 2;
        setVisibleAppCount(maximumApps)
        /** calculation */
        let copyNavLink = [...sideNavLists];
        const moreApps = copyNavLink.filter((item: any) => hiddenApps.indexOf(item.id) > -1);
        copyNavLink = copyNavLink.filter((item: any) => hiddenApps.indexOf(item.id) === -1);
        if (applicationHeight >= pageHeight) {
            /** allow maximum */
            if (sideNavLists.length > maximumApps) {
                for (let i = copyNavLink.length - 1; i >= 0; i--) {

                    if (i >= maximumApps)
                        copyNavLink[i].hide = true;
                    else
                        copyNavLink[i].hide = false;
                }
            }
        } else {
            /** if application height is less than page height */
            let should_extra_hidden = parseInt(((pageHeight - applicationHeight) / eachAppSize).toString());
            should_extra_hidden = (displayLinks.length - maximumApps) + should_extra_hidden;


            for (let i = copyNavLink.length - 1; i >= 0; i--) {

                if (i >= displayLinks.length - should_extra_hidden)
                    copyNavLink[i].hide = true;
                else
                    copyNavLink[i].hide = false;
            }

        }
        setSideNavLists([...copyNavLink, ...moreApps]);
    }, 1000);

    const manageSideLinks = () => {
        const displayLinks = []
        const additionalLinks = []
        for (let i = 0; i < sideNavLists.length; i++) {
            if (sideNavLists[i].hide && sideNavLists[i].hide === true) {
                additionalLinks.push(sideNavLists[i])
            } else {
                displayLinks.push(sideNavLists[i])
            }
        }
        setAdditionalLinks(additionalLinks)
        setDisplayLinks(displayLinks)
    }

    const dataSaveHandler = (data: any, hiddenMenu: any) => {
        const payload: any[] = [];
        for (let i = 0; i < data.length; i++) {
            const hide = hiddenMenu.indexOf(data[i].id) > -1 ? 1 : 0
            payload.push({
                "appId": data[i].id,
                "hide": hide
            })
        }
        setLeftMenuOrder(payload);
    }

    const handleClickOutside = () => {
        setOpenAdditionalLinks(false);
    }

    const onDragEnd = (result: DropResult) => {
        const { source, destination, draggableId } = result
        if (!destination) {
            return
        }
        if (
            destination.droppableId === DISPLAY_MENU_ID &&
            source.droppableId === ADDITIONAL_MENU_ID &&
            displayLinks.length === visibleAppCount
        ) {
            return
        }
        const portalIndex = displayLinks.findIndex(p => p.title === 'Konnect Portal');
        const [firstLink] = displayLinks.splice(portalIndex, 1)

        const tempDisplayNav = [...displayLinks]
        const tempAdditionalNavs = [...additionalLinks]

        const sourceList =
            source.droppableId === DISPLAY_MENU_ID
                ? tempDisplayNav
                : tempAdditionalNavs
        const destinationList =
            destination.droppableId === DISPLAY_MENU_ID
                ? tempDisplayNav
                : tempAdditionalNavs
        const targetNav = sourceList.find(p => p.title === draggableId)

        if (targetNav) {
            if (
                destination.droppableId === DISPLAY_MENU_ID &&
                source.droppableId === ADDITIONAL_MENU_ID
            ) {
                targetNav.hide = false
            }
            if (
                destination.droppableId === ADDITIONAL_MENU_ID &&
                source.droppableId === DISPLAY_MENU_ID
            ) {
                targetNav.hide = true
            }
            sourceList.splice(source.index, 1)
            destinationList.splice(destination.index, 0, targetNav)
        }
        const finalMenuList = [firstLink, ...tempDisplayNav, ...tempAdditionalNavs]
        const hiddenIds = finalMenuList.filter(p => !!p.hide).map(m => m.id)
        setSideNavLists(finalMenuList)
        dataSaveHandler(finalMenuList, hiddenIds)
    }

    const useOutsideComponent = (ref: any, cb: () => void) => {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event: any) {
                if (event.target?.id === 'moreappsbutton' || event.target?.parentNode?.id === 'moreappsbutton')
                    return;
                const inMoreApps = refMoreLinks.current && refMoreLinks.current.contains(event.target)
                const inSidebar = ref.current && ref.current.contains(event.target)
                if ((!inSidebar && !inMoreApps)) {
                    cb()
                }
            }

            // Bind the event listener
            document.addEventListener('click', handleClickOutside)
            document.addEventListener('mousedown', handleClickOutside)

            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener('click', handleClickOutside)
                document.addEventListener('mousedown', handleClickOutside)
            }
        }, [ref])
    }

    useOutsideComponent(ref, handleClickOutside);

    return (
        <div
            ref={ref}
            className={`absolute z-30 inset-y-0 inset-x-0 left-0 h-full bg-baby-black-50 transform dark:bg-gray-900 text-primary overflow-y-hidden overflow-x-hidden shadow-md w-14 transition-all  ${debouncedOpen
                ? 'w-52 translate-x-0 ease-out duration-500'
                : 'w-14 ease-in duration-300'
                }
`}
            onMouseEnter={() => onSidebarOpen(true)}
            onMouseLeave={() => {
                if (!openAdditionalLinks) {
                    onSidebarOpen(false)
                }

            }}
            style={{ zIndex: 9999 }}
        >
            <DragDropContext onDragEnd={onDragEnd}>
                <nav className="flex flex-col text-center text-sm space-y-0.5 mt-4">
                    <div className='bg-baby-black-50'>
                        <VisiableNavList
                            SideNavComponent={SideNavComponent}
                            droppableId={DISPLAY_MENU_ID}
                            displayLinks={displayLinks}
                            linkUri={linkUri}
                            onToggleSideNav={onToggleSideNav}
                        />
                    </div>
                    <div>{additionalLinks.length > 0 && (
                        <div className=" flex row justify-center align-middle w-10  border-t ml-2 mt-1 pt-1"
                            style={{ borderTopColor: '#99999A' }}
                        ></div>
                    )}</div>

                    <div className="relative flex-1 w-350 flex flex-col rounded-md">
                        {additionalLinks.length > 0 && (
                            <button
                                id="moreappsbutton"
                                className={`flex item-center  h-12 pl-2.5 pr-3 py-3 align-middle text-center bg-baby-black-50 transition-all duration-200  dark:hover:bg-primary dark:text-gray-200 bg-transparent text-gray-700 font-bold hover:bg-baby-orange-50 disabled:opacity-50 rounded mr-2 ml-2
                                ${additionalMenuActive() || openAdditionalLinks
                                        ? `bg-baby-gray-100 dark:bg-primary text-gray-700`
                                        : `bg-transparent text-gray-700 `
                                    }
                                 `}


                                onClick={() => {
                                    if (!openAdditionalLinks) {
                                        setOpenAdditionalLinks(
                                            !openAdditionalLinks,
                                        )
                                    }
                                }}
                            >
                                <div
                                    id="moreappsbutton"
                                    className="filter invert-50 group-hover:filter-none"
                                >
                                    <DotsVerticalIcon
                                        className="w-6 h-6"
                                        id="moreappsbutton"
                                    />
                                </div>
                                <div
                                    id="moreappsbutton"
                                    className="mx-4 items-center text-left text-md mt-0.5 rounded-md"
                                >
                                    {'More Apps'}
                                </div>
                            </button>
                        )}
                        {open && openAdditionalLinks && (
                            <Portal>
                                <div
                                    ref={refMoreLinks}
                                    className=" w-96   h-64 mt-28 ml-1.5 absolute flex flex-col top-1/4 left-52 transition-all duration-200 delay-500 bg-baby-black-50  shadow-lg rounded border border-baby-black-50 text-gray-700 "

                                    onMouseEnter={() =>
                                        setOpenAdditionalLinks(true)
                                    }
                                >
                                    <div className="relative flex flex-col w-full h-52 gap-1 bg-baby-black-50 rounded"
                                    >
                                        <Droppable
                                            droppableId={ADDITIONAL_MENU_ID}
                                        >
                                            {provided => (
                                                <div
                                                    className="p-1  bg-baby-black-50 h-64"
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                >
                                                    <IntermixAdditionalLinks
                                                        links={additionalLinks}
                                                        linkUri={linkUri}
                                                        onToggleSideNav={
                                                            onToggleSideNav
                                                        }
                                                    />
                                                    {provided.placeholder}
                                                    {additionalLinks.length > 8 && provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                        <button
                                            className="absolute right-1 top-0 m-1 w-5 h-5 transition-colors hover:text-baby-orange-50"
                                            onClick={() => {
                                                setOpenAdditionalLinks(false)
                                                onToggleSideNav()
                                            }}
                                        >
                                            <XIcon />
                                        </button>
                                    </div>
                                </div>
                            </Portal>
                        )}
                    </div>
                    <div className=' absolute left-0 bottom-0  right-0 w-full flex  flex-col'>
                        <button
                            className={`flex p-4  w-24 mt-5 align-middle text-center transition-all duration-200   dark:text-gray-200 bg-transparent text-gray-700 font-bold  disabled:opacity-50 rounded `}
                            onClick={() => {
                                setOpenAdditionalLinks(false)
                                onToggleSideNav()
                            }}
                        >
                            {!open ? <LeftBottomMenu /> : <BottomRightmenu />
                            }
                        </button>
                    </div>
                </nav>
            </DragDropContext>
        </div>
    )
}
