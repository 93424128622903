import Portal from '@/hoc/Portal'
import useAnalyticsEventTracker from '@/hooks/useAnalyticsEventTracker'
import { useApplicationPreferencesStore } from '@/store/store'
import { GAAction, GAEventCategory } from '@/types/GAnalyticsTypes'
import React, { useCallback } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { Link } from 'react-router-dom'
import { IntermixLinkProps, ISideNavMenuOptions } from './types'
import { SearchIcon } from '@/components/icons/SearchIcon'
import { useAppLinkOpener } from '@/hooks/useAppLinkOpener'
import { SearchCategory } from '../blocks/SearchInput/SearchCategory'
import { useAppContext } from '@/AppContext'
import { PinnedApp } from '../blocks/HomeTab/PinnedApp'
import Tippy from '@tippyjs/react'

export interface IntermixAdditionalLinksProps {
    links: IntermixLinkProps[]
    linkUri: string
    onToggleSideNav: () => void
}

export const AdditionalLinkComponent: React.FC<ISideNavMenuOptions> =
    React.memo(({ title, path, active, icon, onClick, isExternalApp, openInNewTab, pinned, resourceId, name }) => {
        const setCurrentAppViewed = useApplicationPreferencesStore(
            useCallback(state => state.setCurrentAppViewed, []),
        )
        const { navLinks } = useAppContext()
        const appList = navLinks.map(i => i.shortName).join(",")
        if (isExternalApp) {
            return (
                <a

                    target={openInNewTab ? '_blank' : '_self'}
                    href={`${path}?floatingMenu=true&apps=${appList}`}
                    className={`flex group h-12 pl-2.5 pr-3  w-36 text-sm align-middle items-center text-center bg-baby-black-100 border border-baby-black-100 transition-all 
                    duration-200 hover:bg-baby-orange-50 dark:hover:bg-primary hover:text-white rounded-md flex-wrap justify-center
                    dark:text-gray-200 ${active
                            ? `bg-baby-gray-100 dark:bg-primary text-gray-700 font-bold`
                            : `bg-transparent text-gray-700 font-bold`
                        } `}
                    onClick={() => {
                        onClick && onClick()
                    }}
                ><div
                    className="flex flex-1  items-center justify-items-center "
                    style={{ minHeight: '42px' }}
                >
                        <PinnedApp category={SearchCategory.LEFT_MENU_APP}
                            url={path} name={name} id={resourceId} pinned={pinned}
                            app={"AdditionalLink"} />
                        <div className="filter invert-50 group-hover:filter-none">{icon}</div>
                        {(title && title?.length > 16) ?
                            <Tippy zIndex={99999} content={title}>
                                <div className="mx-1 text-left ml-3 truncate w-24">
                                    {title}
                                </div>
                            </Tippy>
                            : <div className="mx-1 text-left ml-3 w-24">
                                {title}
                            </div>
                        }

                    </div>
                </a>
            )
        }
        return (
            <Link
                id={title}
                to={path}
                onClick={() => {
                    if (onClick) {
                        setCurrentAppViewed(title)
                        onClick()
                    }
                }}
                className={`flex group h-12 pl-2.5 pr-3  w-36 text-sm align-middle items-center text-center bg-baby-black-100 border border-baby-black-100 transition-all 
                duration-200 hover:bg-baby-orange-50 dark:hover:bg-primary hover:text-white rounded-md flex-wrap justify-center
                dark:text-gray-200 ${active
                        ? `bg-baby-gray-100 dark:bg-primary text-gray-700 font-bold`
                        : `bg-transparent text-gray-700 font-bold`
                    } `}
            >
                <div
                    className="flex flex-1  items-center justify-items-center "
                    style={{ minHeight: '42px' }}
                >
                    <PinnedApp category={SearchCategory.LEFT_MENU_APP}
                        url={path} name={name} id={resourceId} pinned={pinned}
                        app={"AdditionalLink"} />
                    <div className="filter invert-50 group-hover:filter-none">{icon}</div>
                    {(title && title?.length > 16) ?
                            <Tippy content={title} zIndex={99999}>
                                <div className="mx-1  text-left ml-3 truncate w-24">
                                    {title}
                                </div>
                            </Tippy>
                            : <div className="mx-1  text-left ml-3 w-24">
                                {title}
                            </div>
                        }
                </div>
            </Link>
        )
    })

export const IntermixAdditionalLinks: React.FC<IntermixAdditionalLinksProps> =
    ({ links, linkUri, onToggleSideNav }) => {
        const [search, setSearch] = React.useState('')
        const [addtLinks, setAddLinks] = React.useState(links)

        React.useEffect(() => {
            setAddLinks(links)
        }, [links])

        const searchHandler = (value: string) => {
            setSearch(value)
            const filteredLinks = links.filter(app => {
                if (value?.length > 2) {
                    return app.title.toLowerCase().includes(value.toLowerCase())
                } else {
                    return true
                }
            })
            setAddLinks(filteredLinks)
        }
        const { recordUseHistory } = useAppLinkOpener()
        const gaEventTracker = useAnalyticsEventTracker(GAEventCategory.LEFT_MENU_APP_CLICK);

        return (
            <div className="flex flex-col m-2 p-2 h-56" id="Moreapps">
                <div className="flex ml-0 relative w-full flex-wrap items-stretch mb-2">
                    <input
                        type="text"
                        placeholder="Find An App..."
                        value={search}
                        onChange={e => searchHandler(e.target.value)}
                        className="my-1 block text-xs w-full text-baby-gray-50 rounded-md bg-baby-black-50 border-baby-gray-50 shadow-sm focus:border-baby-orange-50 focus:ring focus:ring-baby-orange-50 focus:ring-opacity-50"

                    />
                    <button
                        className={`absolute top-3 right-1 w-4 h-4 bg-baby-black-50 hover:text-primary ${search.length === 0 && 'hidden'
                            }`}
                        onClick={() => searchHandler('')}
                    >
                        <SearchIcon className="h-3 w-3  hover:text-baby-orange-50 text-o" />

                    </button>

                </div>
                {addtLinks.length > 0 ? (
                    <div className='scrollbar-thin scrollbar-thumb-rounded-2xl scrollbar-track-rounded-2xl scrollbar-thumb-baby-gray-500 scrollbar-track-baby-gray-600'>
                        <div
                            className={`grid grid-cols-2 gap-3 pb-4 bg-baby-black-50 ml-0`}

                        >

                            {addtLinks.map(
                                (
                                    {
                                        title,
                                        id,
                                        path,
                                        icon,
                                        appName,
                                        iframe,
                                        iframeURL,
                                        isExternalApp,
                                        openInNewTab,
                                        pinned
                                    },
                                    index,
                                ) => {
                                    const finalPath =
                                        iframe && iframeURL && appName
                                            ? path + '?app=' + appName
                                            : path

                                    return (
                                        <Draggable
                                            key={title}
                                            draggableId={title}
                                            index={index}
                                        >
                                            {(provided, snapshot) => {
                                                const { style, ...rest } =
                                                    provided.draggableProps
                                                const dragStyle = {
                                                    ...style,

                                                    zIndex: 99999,
                                                }
                                                const row = (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...rest}
                                                        {...provided.dragHandleProps}
                                                        style={dragStyle}
                                                    >
                                                        <AdditionalLinkComponent
                                                            title={title}
                                                            path={finalPath}
                                                            key={title}
                                                            active={decodeURI(
                                                                linkUri,
                                                            ).startsWith(finalPath)}
                                                            icon={icon}
                                                            pinned={pinned}
                                                            resourceId={id.toString()}
                                                            name={appName}
                                                            isExternalApp={isExternalApp}
                                                            openInNewTab={openInNewTab}
                                                            onClick={() => {
                                                                recordUseHistory({ id, name: appName, url: path, openInNewTab, category: SearchCategory.LEFT_MENU_APP })
                                                                onToggleSideNav()
                                                                gaEventTracker(GAAction.OPEN_APP, title)
                                                            }}
                                                        />
                                                    </div>
                                                )
                                                if (snapshot.isDragging) {
                                                    return <Portal>{row}</Portal>
                                                } else {
                                                    return row
                                                }
                                            }}
                                        </Draggable>
                                    )
                                },
                            )}
                        </div>
                    </div>
                ) : (
                    <div className="text-primary text-sm font-sans text-center">
                        No App found
                    </div>
                )}
            </div>
        )
    }
