export enum GAEventCategory {
    LEFT_MENU_APP_CLICK = 'Left menu App click',
    RECENT_APPS_CLICK = 'Recent App Click',
    APP_SUGGESTION_CLICK = 'App Suggestion Click',
    FLOAT_MENU_APP_CLICK = 'Float Menu App Click',
}

export enum GAAction {
    OPEN_APP = 'Open App',
    WIDGET_USED = 'Widget Used',
    COMPONENT_USED = 'Component Used',
}
