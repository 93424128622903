import { useEffect } from 'react'
import { Transition } from '@headlessui/react'
export interface FeedbackMessageProps {
    onClose: (show?: boolean) => void
    show: boolean
}
export const FeedbackMessage: React.FC<FeedbackMessageProps> = ({
    onClose,
    show,
}) => {
    useEffect(() => {
        const interval = setTimeout(() => {
            onClose(false)
        }, 2000)
        return () => {
            clearInterval(interval)
        }
    }, [])
    return (
        <Transition
            appear={true}
            show={show}
            enter="transition-opacity duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
        >
            <div
                className="flex items-center text-gray-600 p-1 pl-3  transition duration-300 ease-in-out transform"
                onClick={() => onClose(false)}
            >
                <p>Thanks for your feedback 😉</p>
            </div>
        </Transition>
    )
}
