import * as React from 'react'
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
} from '@azure/msal-react'
import { useLocation, Redirect } from 'react-router-dom'
import { IntermixDashboardShell } from '@components/intermix/IntermixDashboardShell'
import { ISideNavContainer } from '@components/intermix/types'
import { sideNavConfig } from '@components/intermix/SideNavMenu'
import { useAppContext } from '@/AppContext'
import { useAppConfig } from '@/hooks/useAppConfig'

const Layout: React.FC<unknown> = props => {
    const { byPassAuth } = useAppConfig()
    const location = useLocation()
    const { navLinks, user } = useAppContext()
    const [navConfig, setNavConfig] = React.useState<ISideNavContainer>()

    React.useEffect(() => {
        if (navLinks) {
            setNavConfig({
                ...sideNavConfig,
                menu: {
                    ...sideNavConfig.menu,
                    links: navLinks,
                },
            })
        }
    }, [navLinks])
    return (
        <>
            {!byPassAuth && (
                <>
                    <UnauthenticatedTemplate>
                        <Redirect to="/login" />
                    </UnauthenticatedTemplate>
                    <AuthenticatedTemplate>
                        <IntermixDashboardShell
                            sideNav={navConfig}
                            currentPath={location.pathname}
                            currentSearch={location.search}
                            hasNotifications
                        >
                            {user && props.children}
                        </IntermixDashboardShell>
                    </AuthenticatedTemplate>
                </>
            )}
            {byPassAuth && (
                <IntermixDashboardShell
                    sideNav={navConfig}
                    currentPath={location.pathname}
                    currentSearch={location.search}
                    hasNotifications
                >
                    {props.children}
                </IntermixDashboardShell>
            )}
        </>
    )
}

export default React.memo(Layout)
