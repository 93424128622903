class AuthImg extends HTMLImageElement {
    constructor() {
        super();
        this._lastUrl = '';
    }

    static get observedAttributes() {
        return ['authSrc'];
    }

    connectedCallback() {
        let url = this.getAttribute('authSrc');
        if (url !== this._lastUrl) {
            this._lastUrl = url;
            this.requestImage(url, this);
        }
    }
    requestImage(url, element) {

        const request = new XMLHttpRequest();
        const accessToken = localStorage.getItem('idToken') ?? ''
        request.responseType = 'blob';
        request.open('get', url, true);
        request.setRequestHeader('Authorization', `Bearer ${accessToken}`);
        request.onreadystatechange = e => {
            if (request.readyState == XMLHttpRequest.DONE && request.status == 200) {
                element.src = URL.createObjectURL(request.response);
                element.onload = () => {
                    URL.revokeObjectURL(element.src);
                }
            }
        };
        request.send(null);
    }
}
export default AuthImg;
