import { Avatar } from '@/components/atoms/Avatar'
import { useMsal } from '@azure/msal-react'
import { MessageMember } from './MessageMembersTitle'
import { Chat, Message } from './MessagesList'

type messageMembersAvatarProps = {
    chat: Chat
    className?: string
}

export const MessageMembersAvatar: React.FC<messageMembersAvatarProps> = ({
    chat,

    className,
}: messageMembersAvatarProps) => {
    const userOid = useMsal().accounts[0].localAccountId ?? ``

    const getAvatarName = (): string => {
        if (chat.members.length === 1 && chat.chatType !== `meeting`) {
            return chat.messages[0]?.from?.application?.displayName
        } else if (chat.chatType === `meeting`) {
            return `Meet`
        } else {
            return chat.members[0].userId == userOid
                ? chat.members[1].displayName
                : chat.members[0].displayName
        }
    }

    const getAvatarUserId = (): string => {
        if (chat.members.length === 1 && chat.chatType !== `meeting`) {
            return chat.messages[0]?.from?.application?.displayName ?? userOid
        } else if (chat.chatType === `meeting`) {
            return `Meet`
        } else {
            return chat.members[0].userId == userOid
                ? chat.members[1].userId
                : chat.members[0].userId
        }
    }

    return (
        <span className={`flex mr-2 ${className ?? 'w-12 h-12'}`}>
            {chat.members.length <= 2 && (
                <Avatar name={getAvatarName()} userId={getAvatarUserId()} />
            )}

            {chat.members.length > 2 && (
                <Avatar members={chat.members} userId={chat.id} />
            )}
        </span>
    )
}
