import { ApplicationMessage, useApplicationShellStore } from '@/store/store';
import { CheckIcon, XIcon, XCircleIcon } from '@heroicons/react/solid';
import { useCallback, useEffect } from 'react';
import toast, { Toaster } from 'react-hot-toast';

export const ToasterDisplay: React.FC<any> = () => {
    const messages = useApplicationShellStore(
        state => state.messages
    )
    const removeMessage = useApplicationShellStore(
        useCallback(state => state.removeMessage, [])
    )

    const preToastCleanup = (id: string): Promise<boolean> => new Promise((resolve) => {
        removeMessage(id)
    });
    const createToast = (message: ApplicationMessage) => {
        toast(
            (t) => (
                <div className='flex font-sans justify-between bg-white text-gray-700'>
                    <span className='text-sm'>
                        {message.message}
    
                    </span>
                    <button className='ml-2' onClick={() => toast.dismiss(t.id)}>
                        <XIcon className='font-semibold w-4 h-4 hover:text-gray-900' />
                    </button>
                </div>
            ),
            {
                icon: message.messageType === 'error' ? <XCircleIcon className='w-8 h-8 font-extrabold rounded-full text-red-400' /> :  <CheckIcon className='w-6 h-6 p-1 font-extrabold rounded-full bg-green-400' />,
                duration: message.messageType === 'error' ? 4000 : 2000
            }
        );
    }


    useEffect(() => {
        if (messages.length > 0) {
            messages.map(m => {
                preToastCleanup(m.id)
                createToast(m)})
        }
    }, [messages])

    return <><Toaster
        position="bottom-right"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
            // Define default options
            // className: '',
            // duration: 3000,
            // style: {
            //     background: '#363636',
            //     color: '#fff',
            // },
            // Default options for specific types
            success: {
                duration: 2000,
                className: '',
                style: {
                    background: '#ff6a12',
                    color: 'white'
                }
            },
            error: {
                duration: 2000,
                className: '',
                style: {
                    background: 'red',
                    color: 'white'
                }
            }
        }} /></>

}