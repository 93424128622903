import { getAppConfig } from '@/Config'
import {
    randomPastelColor,
    textColorForPastelColor,
} from '@/services/ColorService'
import { getInitials } from '@/utilities/contents'
import { AccountInfo } from '@azure/msal-common'
import { useMsal } from '@azure/msal-react'
import { useEffect, useState } from 'react'
import { MessageMember } from '../blocks/messages/MessageMembersTitle'
import { Loading } from './Loading'

type avatarProps = {
    name?: string | null
    userId?: AccountInfo[`localAccountId`] | null
    members?: MessageMember[] | null
    className?: string
}

export const Avatar: React.FC<avatarProps> = ({
    name = null,
    userId = null,
    members = null,
    className,
}: avatarProps) => {
    const displayName = name
        ? getInitials(name)
        : getInitials(useMsal().accounts[0].name ?? `...`)
    const msal = useMsal()
    const [avatarAvailable, setAvatarAvailable] = useState<boolean>(true)
    const [loading, setLoading] = useState(true)
    const [avtarUrl, setAvtarUrl] = useState('')

    useEffect(() => {
        const photoUrl = `https://graph.microsoft.com/v1.0/users/${userId}/photo/$value`
        if (userId) {
            loadImage(photoUrl)
        }
        return () => {
            URL.revokeObjectURL(avtarUrl)
        }
    }, [userId])

    const loadImage = async (url: string) => {
        try {
            setLoading(true)
            const { accessToken } = await msal.instance.acquireTokenSilent({
                scopes: getAppConfig().scopes,
            })
            const response = await fetch(url, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            })

            const responseAsBlob = await response.blob()
            const imgUrl = URL.createObjectURL(responseAsBlob)
            setAvtarUrl(imgUrl)
            setLoading(false)
            setAvatarAvailable(true)
        } catch (err) {
            setLoading(false)
            setAvatarAvailable(false)
        }
    }

    const storageColors = JSON.parse(
        localStorage.getItem(`usersColors`) ?? '[]',
    )
    const userColor = storageColors[`${userId}`]
    const bgColor = userColor ?? randomPastelColor()
    localStorage.setItem(
        `usersColors`,
        JSON.stringify({ ...storageColors, [`${userId}`]: bgColor }),
    )

    return (
        <div
            className={`flex justify-center items-center text-white text-sm ${
                !avatarAvailable ?? 'p-1'
            } rounded-full ${className ?? 'w-full h-full'} ${
                loading ?? 'bg-transparent'
            } transition-all`}
            style={{
                backgroundColor: loading ? 'transparent' : bgColor,
                color: textColorForPastelColor(bgColor),
            }}
        >
            {avatarAvailable && (
                <img
                    className={`w-full h-full rounded-full ${
                        loading && 'opacity-0'
                    } `}
                    src={avtarUrl}
                    alt={displayName}
                    onError={() => {
                        setLoading(false)
                        setAvatarAvailable(false)
                    }}
                    onLoad={() => {
                        setLoading(false)
                    }}
                />
            )}
            {!avatarAvailable && !loading && !members && <p>{displayName}</p>}
            {members && members.length > 2 && (
                <div className="flex flex-column flex-grow h-full overflow-hidden rounded-full">
                    <span
                        className="border-r border-white w-6/12 h-full flex flex-row justify-center items-center"
                        style={{
                            backgroundColor:
                                storageColors[`${members[0].userId}`],
                            color: textColorForPastelColor(
                                storageColors[`${members[0].userId}`],
                            ),
                        }}
                    >
                        <p>{members[0].displayName[0]}</p>
                    </span>
                    <div className="flex flex-column flex-wrap w-6/12">
                        <span
                            className={`border-l border-b border-white w-full h-3/6 flex flex-row justify-center items-center`}
                            style={{
                                backgroundColor:
                                    storageColors[`${members[1].userId}`],
                                color: textColorForPastelColor(
                                    storageColors[`${members[1].userId}`],
                                ),
                            }}
                        >
                            <p>{members[1].displayName[0]}</p>
                        </span>
                        <span
                            className={`border-l border-b border-white w-full h-3/6 flex flex-row justify-center items-center`}
                            style={{
                                backgroundColor:
                                    storageColors[`${members[2].userId}`],
                                color: textColorForPastelColor(
                                    storageColors[`${members[2].userId}`],
                                ),
                            }}
                        >
                            <p>{members[2].displayName[0]}</p>
                        </span>
                    </div>
                </div>
            )}
            {loading && <Loading />}
        </div>
    )
}
