export interface MenuIconProps {
    base64: string | undefined
    width?: string | undefined
    height?: string | undefined
}
export const SideMenuIcon: React.FC<MenuIconProps> = ({ base64, width, height }) => {
    const imageType = base64?.startsWith('data:') ? '' : 'data:image/svg+xml;base64,';
    return (
        <div className={`flex justify-center ${width || 'w-6'} ${height || 'h-6'}`}>
            <img
                src={`${imageType}${base64
                    ? base64
                    : 'PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGFyaWEtaGlkZGVuPSJ0cnVlIiBmb2N1c2FibGU9ImZhbHNlIiBkYXRhLXByZWZpeD0iZmFzIiBkYXRhLWljb249ImNvZyIgcm9sZT0iaW1nIiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiI+PHJlY3QgaWQ9ImJhY2tncm91bmRyZWN0IiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiB4PSIwIiB5PSIwIiBmaWxsPSJub25lIiBzdHJva2U9Im5vbmUiLz48ZyBjbGFzcz0iY3VycmVudExheWVyIiBzdHlsZT0iIj48dGl0bGU+TGF5ZXIgMTwvdGl0bGU+PHBhdGggZmlsbD0iI2ZmZmZmZiIgZD0iTTQ4Ny40IDMxNS43bC00Mi42LTI0LjZjNC4zLTIzLjIgNC4zLTQ3IDAtNzAuMmw0Mi42LTI0LjZjNC45LTIuOCA3LjEtOC42IDUuNS0xNC0xMS4xLTM1LjYtMzAtNjcuOC01NC43LTk0LjYtMy44LTQuMS0xMC01LjEtMTQuOC0yLjNMMzgwLjggMTEwYy0xNy45LTE1LjQtMzguNS0yNy4zLTYwLjgtMzUuMVYyNS44YzAtNS42LTMuOS0xMC41LTkuNC0xMS43LTM2LjctOC4yLTc0LjMtNy44LTEwOS4yIDAtNS41IDEuMi05LjQgNi4xLTkuNCAxMS43Vjc1Yy0yMi4yIDcuOS00Mi44IDE5LjgtNjAuOCAzNS4xTDg4LjcgODUuNWMtNC45LTIuOC0xMS0xLjktMTQuOCAyLjMtMjQuNyAyNi43LTQzLjYgNTguOS01NC43IDk0LjYtMS43IDUuNC42IDExLjIgNS41IDE0TDY3LjMgMjIxYy00LjMgMjMuMi00LjMgNDcgMCA3MC4ybC00Mi42IDI0LjZjLTQuOSAyLjgtNy4xIDguNi01LjUgMTQgMTEuMSAzNS42IDMwIDY3LjggNTQuNyA5NC42IDMuOCA0LjEgMTAgNS4xIDE0LjggMi4zbDQyLjYtMjQuNmMxNy45IDE1LjQgMzguNSAyNy4zIDYwLjggMzUuMXY0OS4yYzAgNS42IDMuOSAxMC41IDkuNCAxMS43IDM2LjcgOC4yIDc0LjMgNy44IDEwOS4yIDAgNS41LTEuMiA5LjQtNi4xIDkuNC0xMS43di00OS4yYzIyLjItNy45IDQyLjgtMTkuOCA2MC44LTM1LjFsNDIuNiAyNC42YzQuOSAyLjggMTEgMS45IDE0LjgtMi4zIDI0LjctMjYuNyA0My42LTU4LjkgNTQuNy05NC42IDEuNS01LjUtLjctMTEuMy01LjYtMTQuMXpNMjU2IDMzNmMtNDQuMSAwLTgwLTM1LjktODAtODBzMzUuOS04MCA4MC04MCA4MCAzNS45IDgwIDgwLTM1LjkgODAtODAgODB6IiBpZD0ic3ZnXzEiIGNsYXNzPSJzZWxlY3RlZCIgZmlsbC1vcGFjaXR5PSIxIi8+PC9nPjwvc3ZnPg=='
                    }`}
            ></img>
        </div>
    )
}
