import React from 'react'
import { IconDisplay } from '../HomeTab/IconDisplay'

interface Props {
    searchHistoryList: any[]
    redirectToAdvanceSearch: (text: string) => void
}

const SearchHistoryList = (props: Props) => {
    return (
        <>
            <div className=" px-3 pt-3 ml-8 mb-1 text-xs text-gray-600 font-bold">
                Search history
            </div>
            {props.searchHistoryList
                .slice(0, 4)
                .map((item: any) => {
                    return (
                        <div
                            className="hover:bg-gray-100 mb-1 cursor-pointer"
                            onClick={() => {
                                props.redirectToAdvanceSearch(
                                    item.searchText,
                                )
                            }}
                        >
                            <div className="flex pl-3  py-1">
                                {IconDisplay(
                                    'History',
                                )}
                                <div className="text-xs  text-gray-500 font-normal ml-4">
                                    {item.searchText}
                                </div>
                            </div>

                        </div>

                    )
                })

            }
        </>
    )
}

export default SearchHistoryList
