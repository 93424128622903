import React, { useContext, createContext, useState, useEffect } from 'react'
import { getAppConfig } from './Config'
import { AuthCodeMSALBrowserAuthenticationProvider } from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser'
import { InteractionType, PublicClientApplication } from '@azure/msal-browser'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import { getUser } from './services/GraphService'
import { IntermixLinkProps } from './components/intermix/types'
import { SideMenuIcon } from './components/icons/MenuIcon'
import { callLoginAndGetLeftMenuList, callPostAuthLogin, LeftMenu } from './services/AuthService'
import { clearAuthenticatedUserfromLS } from './utilities/localstorage'
import { datadogRum } from '@datadog/browser-rum'
import { findWindows } from 'windows-iana'

const config = getAppConfig()
// <AppContextSnippet>
export interface AppUser {
    displayName?: string
    email?: string
    role?: string
    avatar?: string
    timeZone?: string
    timeFormat?: string
    permissions?: any[]
}

export interface AppError {
    message: string
    debug?: string
}

type AppContext = {
    user?: AppUser
    menuLoaded?: boolean
    hasInnovatorPrivilege?: boolean
    signIn?: () => void
    signOut?: () => void
    getURLDetailsByAppName?: (
        appName: string,
    ) => { url: string; title: string, showSideTopBar: boolean } | null
    authProvider?: AuthCodeMSALBrowserAuthenticationProvider
    navLinks: IntermixLinkProps[]
    updatePinStatus?: (menu: any) => void
}


const appContext = createContext<AppContext>({
    user: undefined,
    menuLoaded: false,
    hasInnovatorPrivilege: false,
    signIn: undefined,
    signOut: undefined,
    getURLDetailsByAppName: undefined,
    authProvider: undefined,
    navLinks: [],
    updatePinStatus: undefined
})

export function useAppContext(): AppContext {
    return useContext(appContext)
}

interface ProvideAppContextProps {
    children: React.ReactNode
}

export default function ProvideAppContext({
    children,
}: ProvideAppContextProps): React.ReactElement {
    const auth = useProvideAppContext()
    return <appContext.Provider value={auth}>{children}</appContext.Provider>
}
// </AppContextSnippet>
export const SIDE_MENU_DISPLAY_LIMIT = 7
// <AppContextSnippet>
function useProvideAppContext() {
    const isAuthenticated = useIsAuthenticated()
    const [user, setUser] = useState<AppUser | undefined>(undefined)
    const [menuLoaded, setMenuLoaded] = useState<boolean>(false)
    const [navLinks, setNavLinks] = useState<IntermixLinkProps[]>([])
    const [hasInnovatorPrivilege, setHasInnovatorPrivilege] =
        useState<boolean>(false)
    const msal = useMsal()
    const updatePinStatus = (menu: any) => {
        const newNavs = navLinks.map(nav => {
            if (nav.id == menu.resourceId) {
                return { ...nav, pinned: menu.pinned }
            }
            else {
                return nav
            }
        })
        setNavLinks(newNavs)
    }

    // Used by the Graph SDK to authenticate API calls
    const authProvider = React.useMemo(
        () =>
            new AuthCodeMSALBrowserAuthenticationProvider(
                msal.instance as PublicClientApplication,
                {
                    account: msal.instance.getActiveAccount()!,
                    scopes: config.scopes,
                    interactionType: InteractionType.Popup,
                },
            ),
        [msal.instance],
    )

    type InternalAppConfigType = {
        name: string
        title: string
    }

    // Internal App configuration
    const internalAppConfig: InternalAppConfigType[] = [
        {
            name: 'SettingsPage',
            title: 'settings',
        },
        {
            name: 'HomePage',
            title: 'konnect portal',
        },
        {
            name: 'PowerBIReportPage',
            title: 'reports',
        },
    ]

    const findInternalAppMatch = (
        title: string,
    ): InternalAppConfigType | undefined => {
        return internalAppConfig.find(app => {
            return app.title.toLowerCase().includes(title.toLowerCase())
        })
    }

    const getURLDetailsByAppName = (
        appName: string,
    ): { url: string; title: string, showSideTopBar: boolean } | null => {
        const app = navLinks.find(
            app => app.appName?.toLowerCase() === appName.toLowerCase(),
        )
        if (app) {
            return {
                title: app.title,
                url: app.iframeURL || '',
                showSideTopBar: (app.sideTopBar === undefined) ? true : app.sideTopBar
            }
        }
        return null
    }

    const getLeftMenuList = async (): Promise<LeftMenu[]> => {
        return new Promise(async resolve => {
            const tokenResponse = await msal.instance.acquireTokenSilent({
                scopes: config.scopes,
                account: msal.instance.getActiveAccount()!,
            })

            if (tokenResponse && tokenResponse.accessToken) {
                // Saving idToken to localStorage because the Iframes need it to make calls to the API;
                localStorage.setItem(`idToken`, `${tokenResponse.accessToken}`)
                const result = await callPostAuthLogin( tokenResponse.accessToken)
                let permissionsArr: any[]=[]
                result.arr_profiles.forEach((obj: { arr_privileges: any }) => {
                    permissionsArr=[ ...permissionsArr,...obj.arr_privileges]
                })
                setUser({
                    ...user,
                    permissions: permissionsArr
                })
                const leftMenuList = await callLoginAndGetLeftMenuList(
                    tokenResponse.accessToken,
                    result
                )
                resolve(leftMenuList.content)
            } else resolve([])
        })
    }


    const transformLeftMenuList = (
        leftMenuList: LeftMenu[],
    ): IntermixLinkProps[] => {
        const portalBaseURL = getAppConfig().portalBaseURL
        const result: IntermixLinkProps[] = leftMenuList
            //    .filter(item => enabledApps.includes(item.str_title.toLowerCase()))
            .map(
                (
                    {
                        id_system,
                        str_title,
                        str_hyperlink,
                        str_privilege,
                        str_image_base64,
                        sideTopBar,
                        openInNewTab,
                        isExternalApp,
                        shortName,
                        pinned
                    },
                    index,
                ) => {
                    const internalAppMatch = findInternalAppMatch(str_title)
                    const isSG4 = str_title.toLowerCase().includes('sg4')

                    const commonProps =
                    {
                        id: id_system,
                        title: str_title,
                        path: str_hyperlink,
                        iframe: false,
                        privilege: str_privilege,
                        appName: str_title,
                        iconBase64: str_image_base64,
                        openInNewTab,
                        shortName,
                        icon: <SideMenuIcon base64={str_image_base64} height='h-5' width='w-5' />,
                        hide: false,
                        iframeURL: `${portalBaseURL}${str_hyperlink}`,
                        isExternalApp,
                        pinned


                    }
                    if (isExternalApp) {
                        return {
                            ...commonProps,
                            path: str_hyperlink,
                            iframe: false,
                           openInNewTab,
                            isExternalApp,
                            shortName,
                            pinned
                        }
                    }
                    if (internalAppMatch) {
                        return {
                            ...commonProps,
                            name: internalAppMatch.name,
                        }
                    }

                    if (str_title.toLowerCase().trim().includes('innovator')) {
                        setHasInnovatorPrivilege(true)
                        return {
                            ...commonProps,
                            path: '/innovator',
                            name: 'InnovatorProfileIFramePage',
                            iframe: true,
                        }
                    }
                    if (str_title.toLowerCase().trim().includes('ipm')) {
                        return {
                            ...commonProps,
                            path: '/apps',
                            name: 'IFramePage',
                            iframe: true,
                        }
                    }
                    return {
                        ...commonProps,
                        path: '/apps',
                        name: 'IFramePage',
                        iframe: true,
                        iframeURL: encodeURI(
                            `${portalBaseURL}${isSG4 ? `${str_hyperlink}/main` : str_hyperlink
                            }?contentOnly=true`,
                        ),
                    }
                },
            )

        return result
    }

    // <UseEffectSnippet>
    useEffect(() => {
        // Ideally this could come from an API call
        // for now hard coding it
        const loadMenu = async () => {
            const leftMenuList: LeftMenu[] = await getLeftMenuList()
            const allLinks: IntermixLinkProps[] = [
                ...transformLeftMenuList(leftMenuList),
            ].sort((a, b) => a.id - b.id)
            setNavLinks(allLinks)
            setMenuLoaded(true)
        }

        const storeIdToken = async () => {
            const idToken = (
                await msal.instance.acquireTokenSilent({
                    scopes: getAppConfig().scopes,
                })
            ).accessToken

            localStorage.setItem(`idToken`, `${idToken}`)
        }

        const setDatadogUser = async () => {
            const user = await msal.instance.acquireTokenSilent({
                scopes: getAppConfig().scopes,
            })

            datadogRum.setUser({
                id: user.account?.localAccountId,
                name: user.account?.name,
                email: user.account?.username,
            })
        }

        if (user?.email) {
            loadMenu()
            storeIdToken()
            setDatadogUser()
        }
    }, [user?.email, msal.instance])

    const checkUser = async () => {
        if (!user) {
            try {
                // Check if user is already signed in
                const account = msal.instance.getActiveAccount()
                const localTimeZone = findWindows(Intl.DateTimeFormat().resolvedOptions().timeZone);
                if (account) {
                    // Get the user from Microsoft Graph
                    const user = await getUser(authProvider)
                    setUser({
                        displayName: user.displayName || '',
                        email: user.mail || user.userPrincipalName || '',
                        timeFormat: 'hh:mm tt',
                        timeZone: localTimeZone[0] || 'UTC',
                        role: 'admin',
                    })
                }
            } catch (err: any) {
                console.log(err.message)
            }
        }
    }

    // </UseEffectSnippet>
    useEffect(() => {
        if (isAuthenticated && !user) {
            checkUser()
        }
    }, [isAuthenticated, user])
    // <UseEffectSnippet>

    // <SignInSnippet>
    const signIn = async () => {
        await msal.instance.loginRedirect({
            scopes: config.scopes,
            //prompt: 'select_account',
        })

        // Get the user from Microsoft Graph
        const user = await getUser(authProvider)
        setHasInnovatorPrivilege(false)
        const localTimeZone = findWindows(Intl.DateTimeFormat().resolvedOptions().timeZone);
        setUser({
            displayName: user.displayName || '',
            email: user.mail || user.userPrincipalName || '',
            timeFormat: 'hh:mm tt',
            timeZone: localTimeZone[0] || 'UTC',
            role: 'admin',
        })
    }
    // </SignInSnippet>

    // <SignOutSnippet>
    const signOut = async () => {
        setUser(undefined)
        setHasInnovatorPrivilege(false)
        clearAuthenticatedUserfromLS()
        await msal.instance.logoutRedirect()
    }
    // </SignOutSnippet>



    return {
        hasInnovatorPrivilege,
        user,
        menuLoaded,
        signIn,
        signOut,
        getURLDetailsByAppName,
        authProvider,
        navLinks,
        updatePinStatus
    }
}
