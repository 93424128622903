import { projectname } from '@/utilities/project'
import React from 'react'
import { IconDisplay } from '../HomeTab/IconDisplay'
import { SearchCategory } from './SearchCategory'

type PropType = {
    suggestionList: any[]
    seeMoreClickHandler: (category: string) => void
    navigateHandler: (item: any, type: string) => void
    category: string
}

const SuggestionList: React.FC<PropType> = ({
    suggestionList,
    seeMoreClickHandler,
    navigateHandler,
    category,
}) => {
    return (
        <>
            <div className="flex">
                <div className="px-3 pt-3 ml-8 mb-1 text-xs text-gray-600 font-bold w-6/12">
                    {category}
                </div>
                <div
                    className="text-xs text-primary pt-3 cursor-pointer w-6/12 text-right mr-5"
                    onClick={() =>
                        seeMoreClickHandler(category)
                    }
                >
                    See more
                </div>
            </div>

            {suggestionList.length ? suggestionList.map((item: any) => {
                return (
                    <>
                        <div>
                            <div
                                className="flex pl-3 py-1 cursor-pointer hover:bg-gray-100"
                                onClick={() =>
                                    navigateHandler(
                                        item,
                                        'sugg',
                                    )
                                }
                            >
                                <span className="mr-4">
                                    {IconDisplay(category)}
                                </span>
                                <span className="text-xs text-gray-500 font-normal">
                                    {category === SearchCategory.RELEVENT_PROJECT ? projectname(item.src, item.name) : item.name}
                                </span>
                            </div>
                        </div>

                    </>
                )
            }) : null}
        </>
    )
}

export default SuggestionList
