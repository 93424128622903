import { PinIcon } from "@/components/icons/PinIcon";
import { UnpinIcon } from "@/components/icons/UnpinIcon";
import { useAutoComplete } from "@/hooks/useAutoComplete";
import { SearchCategory } from "../SearchInput/SearchCategory";
import { recordHistory } from '@/services/SearchService'
import { useAppLinkOpener } from '@/hooks/useAppLinkOpener'
import { useAppContext } from "@/AppContext";

type Props = {
    category?: any,
    url: any,
    name?: string,
    id: string,
    pinned?: boolean
    app?: string
}
export enum pinUI {
    recentApp = "recentApp",
    AdditionalLink = "AdditionalLink"
}

export const PinnedApp = (pinnedAppProps: Props) => {
    const { app, pinned } = pinnedAppProps;
    const { getToken } = useAutoComplete()
    const { getResourceHistory } = useAppLinkOpener()
    const { updatePinStatus } = useAppContext()

    const pinUnpinHandler = (info: Props, pinned: boolean) => {
        recordUseHistory(info, pinned)

    }

    const recordUseHistory = async (info: any, pin: boolean) => {
        const id = info.resourceId ? info.resourceId : info.id
        const displayName = info.name ? info.name : info.text
        const { name, category } = info
        let { openInNewTab } = info
        const url = info.url
        openInNewTab = openInNewTab ? 1 : 0
        const pinned = pin ? 1 : 0
        const pinOperation = true

        const token = await getToken()
        await recordHistory(token, {
            resourceId: id.toString(),
            name: displayName,
            category,
            url,
            openInNewTab,
            pinned,
            pinOperation
        })
        getResourceHistory()
        if (category == SearchCategory.LEFT_MENU_APP) {
            updatePinStatus && updatePinStatus({ resourceId: id, pinned })
        }
    }

    const pinUnpin = (app: any) => {
        switch (app) {
            case pinUI.recentApp:
                return 'ml-5 filter invert-50'
            case pinUI.AdditionalLink:
                return 'ml-122px pr-2 -mt-6 filter invert'
            default:
                return 'ml-165px pt-1 filter invert'
        }
    }

    return (
        <div className={`${pinUnpin(app)} -mt-3 absolute group-hover:visible invisible`} >
            {
                pinned ?
                    <div
                        onClick={(e) => {
                            e.stopPropagation()
                            e.preventDefault()
                            pinUnpinHandler(pinnedAppProps, false)
                        }}>
                        <UnpinIcon width="13" height="13" />
                    </div>
                    :
                    <div onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        pinUnpinHandler(pinnedAppProps, true)
                    }}>
                        <PinIcon width="13" height="13" /></div>
            }
        </div>



    )



}