import { SideMenuIcon } from '@/components/icons/MenuIcon'
import { useAppLinkOpener } from '@/hooks/useAppLinkOpener'
import { SearchCategory } from './SearchCategory'
import { useHistory } from 'react-router-dom'
import { GAEventCategory } from '@/types/GAnalyticsTypes'

interface Props {
    appList: []
    heading: string
    seeMore: boolean
    keyword: string
    calledBy: string
    closeSuggBox: any
}

const AppList = (props: Props) => {
    const { openApplication } = useAppLinkOpener()
    const history = useHistory()
    const displaySearchList = (category: string) => {
        history.push(
            `/search?type=${category.toLowerCase()}&text=${props.keyword}`,
        )
        if (props.calledBy === 'homeSearchBar') {
            props.closeSuggBox(false)
        }
    }

    const appClick = (item: any) => {
        openApplication(item, GAEventCategory.APP_SUGGESTION_CLICK)
        if (props.calledBy === 'homeSearchBar') {
            props.closeSuggBox(false)
        }
    }
    return (
        <>
            <div className="flex">
                <div className=" px-3 pt-3 ml-8 mb-1 text-xs text-gray-600 font-bold w-6/12">
                    {props.heading}
                </div>
                {props.seeMore ? (
                    <div
                        className="text-xs text-primary pt-3 cursor-pointer w-6/12 text-right mr-5"
                        onClick={() =>
                            displaySearchList(SearchCategory.APPLICATION)
                        }
                    >
                        See more
                    </div>
                ) : null}
            </div>
            <div className="flex px-3 ml-6 mb-1">
                <div className="flex flex-wrap mt-1 mb-2">
                    {props.appList.slice(0, 6).map((item: any) => {
                        const iconStyle =
                            item.category === SearchCategory.EXTERNAL_APP
                                ? {}
                                : { filter: 'invert(.5)' }
                        return (
                            <div
                                onClick={() => {
                                    appClick(item)
                                }}
                                className="cursor-pointer"
                            >
                                <div className="flex flex-col justify-center items-center">
                                    <div className="p-4 m-2 rounded border-gray-300 border-2">
                                        <div style={iconStyle}>
                                            <SideMenuIcon
                                                base64={item.icon}
                                                width="w-8"
                                                height="h-8"
                                            />
                                        </div>
                                    </div>
                                    <div className="mx-1 font-sans text-xs font-light text-gray-600 hover:text-gray-600">
                                        {`${
                                            item.name.length > 15
                                                ? `${item.name.substring(
                                                      0,
                                                      12,
                                                  )}...`
                                                : item.name
                                        }`}
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    )
}

export default AppList
