import * as React from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import Layout from '@components/layout/Layout'
import { PrivateRoute } from '@helpers/PrivateRoute'
import { useAppContext } from '@/AppContext'
import { Loader } from './components/blocks/Loader'
import { getRedirectUrl, setRedirectUrl } from './utilities/localstorage'
import { useApplicationPreferencesStore } from './store/store'

export const AppRoutes: React.FC<unknown> = () => {
    const { navLinks } = useAppContext()
    const setCurrentAppViewed = useApplicationPreferencesStore(
        React.useCallback(state => state.setCurrentAppViewed, []),
    )
    const location = useLocation()
    const [savedRedirect, setSavedRedirect] = React.useState<any>()

    React.useEffect(() => {
        // Redirect only if not the base URL
        const redirectUrl = getRedirectUrl()
        if (redirectUrl !== '/') {
            setSavedRedirect(redirectUrl)
        }
    }, [])

    React.useEffect(() => {
        if (location.pathname !== '/' && location.pathname !== '/login') {
            setRedirectUrl(location.pathname + location.search)
            setSavedRedirect(location.pathname + location.search)
        }

        if (location.pathname !== '/apps') {
            setCurrentAppViewed(undefined)
        }
    }, [location])

    return (
        <Layout>
            <React.Suspense fallback={<Loader />}>
                <Switch>
                    {navLinks
                        .filter(({ name }) => name !== 'IFramePage')
                        .map(({ path, name }) => (
                            <PrivateRoute
                                path={path}
                                component={React.lazy(
                                    () => import(`@pages/${name}`),
                                )}
                                key={name}
                            />
                        ))}
                    {navLinks
                        .filter(({ name }) => name === 'IFramePage')
                        .slice(0, 1)
                        .map(({ path, name }) => (
                            <PrivateRoute
                                path={path}
                                component={React.lazy(
                                    () => import(`@pages/${name}`),
                                )}
                                key={name}
                            />
                        ))}
                    {navLinks && navLinks.length > 0 && (
                        <Route
                            exact
                            path="/"
                            render={() => {
                                return <Redirect to={navLinks[0].path} />
                            }}
                        />
                    )}
                    <Route
                        path="/search"
                        component={React.lazy(
                            () => import('@pages/SearchList'),
                        )}
                    />
                    <Route
                        path="/savedsearch"
                        component={React.lazy(
                            () => import('@pages/SearchSaved'),
                        )}
                    />
                    <Route
                        path="*"
                        component={React.lazy(
                            () => import('@pages/NotFoundPage'),
                        )}
                    />
                </Switch>
            </React.Suspense>
            {savedRedirect && <Redirect to={savedRedirect} />}
        </Layout>
    )
}
