import { useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useAppContext } from '@/AppContext'
import { SearchCategory } from '@/components/blocks/SearchInput/SearchCategory'
import { useAutoComplete } from './useAutoComplete'
import { getResourceHistoryList, recordHistory } from '@/services/SearchService'
import { useResourceHistory } from '@/store/store'
import useAnalyticsEventTracker from './useAnalyticsEventTracker'
import { GAAction, GAEventCategory } from '@/types/GAnalyticsTypes'
import { projecturl } from '@/utilities/project'

export const useAppLinkOpener = () => {
    const history = useHistory()
    const { navLinks } = useAppContext()
    const { getToken } = useAutoComplete()
    const setApplicationList = useResourceHistory(
        useCallback(state => state.setApplicationList, []),
    )
    const setResourceList = useResourceHistory(
        useCallback(state => state.setResourceList, []),
    )

    useEffect(() => {
        getResourceHistory()
    }, [])

    
    const openApplication = (app: any, gaCategory = GAEventCategory.FLOAT_MENU_APP_CLICK) => {
        const gaEventTracker = useAnalyticsEventTracker(gaCategory);
        const appId = app.resourceId ? app.resourceId : app.id
        const item = navLinks.find(a => a.id === Number(appId))
        recordUseHistory(app)
        gaEventTracker(GAAction.OPEN_APP, app.title || app.name)
        if (item && !app.url.startsWith('http')) {
            let redirectUrl = '/portal'
            if (item.path === '/apps' || item.path === '/innovator') {
                redirectUrl = `${item.path}?app=${item.title}`
            } else {
                redirectUrl = `${item.path}`
            }
            history.push(encodeURI(redirectUrl))
        } else {
            const { url, openInNewTab } = app
            const appList = navLinks.map(i => i.shortName).join(",")
            const urlWithParams = `${url}?floatingMenu=true&apps=${appList}`
            if (openInNewTab) {
                window.open(urlWithParams, '_blank')
            } else {
                window.open(urlWithParams,'_self')
            }
        }

    }

    const recordUseHistory = async (info: any) => {
        const id = info.resourceId ? info.resourceId : info.id
        const displayName = info.name ? info.name : info.text
        const {name, category } = info
        let { openInNewTab, url = '' } = info
        openInNewTab = openInNewTab ? 1 : 0
        if (
            [SearchCategory.HOW, SearchCategory.RELEVENT_PROJECT].includes(
                category,
            )
        ) {
            openInNewTab = 1
        }
        if(category === SearchCategory.RELEVENT_PROJECT && info.calledFrom === "sugg"){
            url= projecturl(info.src,info.id, info.phaseId, info.pmisProjectId )
        }
        const token = await getToken()
        await recordHistory(token, {
            resourceId: id.toString(),
            name: displayName ,
            category,
            url,
            openInNewTab,
        })

        getResourceHistory()
    }

    const getResourceHistory = async () => {
            try {
                const token = await getToken()
                const response = await getResourceHistoryList(
                    token
                )
                if (response.length) {
                    const resHisList = response.filter(item => {
                        return (
                            item.category !== SearchCategory.LEFT_MENU_APP && item.category !== SearchCategory.EXTERNAL_APP
                        )
                    })
                    const appHisList = response.filter(item => {
                        return (
                            item.category === SearchCategory.LEFT_MENU_APP || item.category === SearchCategory.EXTERNAL_APP
                        )
                    })
                    setApplicationList(appHisList)
                    setResourceList(resHisList)
                }
            } catch (err: any) {
                console.log(err)
            }
        }
    
    return { openApplication, recordUseHistory, getResourceHistory }
}
