import * as React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { IPublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
// import { history } from './helpers'
import LoginPage from './pages/LoginPage'
import ProvideAppContext from './AppContext'
import { AppRoutes } from './AppRoutes'
import './App.scss'
import { ToasterDisplay } from './components/blocks/AppToast/Toaster'
import { ProductTour } from './components/blocks/ProductTour'
import { datadogRum } from '@datadog/browser-rum'

const queryClient = new QueryClient()
// <AppPropsSnippet>
type AppProps = {
    pca: IPublicClientApplication
}
// </AppPropsSnippet>

function App({ pca }: AppProps) {
    datadogRum.init({
        applicationId: 'c071b8e7-335b-4a5d-9de7-d0922707493c',
        clientToken: 'pubf4371ef634686ba10078e9cc849d98b8',
        site: 'datadoghq.com',
        service: 'gitec-konnect-portal-front',
        env: process.env.REACT_APP_NODE_ENV,
        // version: '1.0.0',
        trackInteractions: true,
    })

    return (
        <QueryClientProvider client={queryClient}>
            <MsalProvider instance={pca}>
                <ProvideAppContext>
                    <Router basename={process.env.PUBLIC_URL}>
                        <AppRoutes />
                        <ProductTour />
                        <ToasterDisplay />
                        <Route path="/login" component={LoginPage} />
                    </Router>
                </ProvideAppContext>
            </MsalProvider>
        </QueryClientProvider>
    )
}

export default React.memo(App)
