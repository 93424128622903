import { SearchCategory } from "@/components/blocks/SearchInput/SearchCategory"

export interface SearchItemType {
    phaseId: string,
    id: string,
    name: string
    url?: string
    description?: string
    icon?: string
    type: string
    category: string
    email?: string
    projects?: string[]
    skills?: string[]
    src?: string
    pmisProjectId?:string
    
}

export interface searchDataType {
    checked?: boolean;
    category: string
    displayRecordLength: number
    data: SearchItemType[]
}

export const minDisplayRecordLength = 3;
export const maxDisplayRecordLength = 10;

export const searchInfo = (data: SearchItemType[], peopleData: any[]): searchDataType[] => {
    const searchData: searchDataType[] = [];
    if (data.length) {
        for (let i = 0; i < data.length; i++) {
            const category = [
                SearchCategory.EXTERNAL_APP.toString(),
                SearchCategory.LEFT_MENU_APP.toString(),
            ].includes(data[i].category)
                ? 'Application'
                : data[i].category

            const indexOfCategory = searchData.findIndex(
                item => item.category === category,
            )
            
            if (indexOfCategory > -1) {
                searchData[indexOfCategory].data.push(data[i]);
            } else {
                searchData.push({
                    category: category,
                    displayRecordLength: minDisplayRecordLength,
                    data: [data[i]]
                })
            }
        }
    }
    if (peopleData.length) {
        const PeopleArray: SearchItemType[] = [];
        peopleData.map((item) => {
            PeopleArray.push({
                phaseId:item.phaseId,
                id: item.id,
                name: `${item.givenName} ${item.surname}`,
                category: 'Who',
                type: 'People',
                url: '',
                email: item.userPrincipalName
            })
        });
        searchData.push({ category: 'Who', displayRecordLength: minDisplayRecordLength, data: PeopleArray });
    }
    return searchData;
}