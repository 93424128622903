import { useState, useEffect, useRef } from 'react'
import useOnClickOutside from '../../hooks/useOnClickOutside'
import { INavDropDownProps, IntermixLinkProps } from './types'

export const NavDropDown: React.FC<INavDropDownProps> = ({
    open,
    setOpen,
    component,
    links,
    styles,
    onClose,
}) => {
    const NavItemComponent = component
    const ref = useRef(null);
    const [isModalOpen, setModalOpen] = useState(open);
    const handleClickOutside = () => {
        setModalOpen(false)
        setOpen(false)
    }
    useOnClickOutside(ref, handleClickOutside)
  
    return (
        <div className="flex flex-col text-left content-end border border-1 rounded-md px-1 py-1" ref={ref}>
            {isModalOpen &&
                NavItemComponent &&
                links.map(
                    ({ title, path, isAction, icon }: IntermixLinkProps) => {
                        return (
                            <NavItemComponent
                                title={title}
                                key={title}
                                styles={styles}
                                path={path}
                                isAction={isAction}
                                icon={icon}
                                preClick={() => onClose()}
                            />
                        )
                    },
                )}
        </div>
    )
}