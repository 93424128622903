import { projectname, ProjectType } from '@/utilities/project'
import { IconDisplay } from '../HomeTab/IconDisplay'
import { SearchCategory } from './SearchCategory'

interface Props {
    resHistoryList: any[]
    navigateHandler: (item: any, calledBy: string) => void
}

const ResourceHistoryList = (props: Props) => {
    const appendName = (name: string, id: string) => {
        if (id.includes("_cif"))
            return projectname(ProjectType.CIF, name)
        if (id.includes("_tif"))
            return projectname(ProjectType.TIF, name)
        else return projectname(ProjectType.IPM, name)
    }
    return (
        <>
            <div className=" px-3 pt-3 ml-8 mb-1 text-xs text-gray-600 font-bold">
                Recent resources
            </div>
            {
                props.resHistoryList.slice(0, 4).map((item: any) => {
                    return (
                        <div className="hover:bg-gray-100 mb-1 cursor-pointer" onClick={() => { props.navigateHandler(item, "recentResouces") }}>
                            <div className="flex pl-3  py-1">
                                {IconDisplay(item.category)}
                                <div className=" text-xs text-gray-500 font-normal ml-4">
                                    {item.category === SearchCategory.RELEVENT_PROJECT ? appendName(item.name, item.resourceId) : item.name}
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </>
    )
}

export default ResourceHistoryList
