export const getLinkType = (link: string): 'external' | 'internal' | 'text' => {
    if (link) {
        if (link.startsWith('http')) {
            return 'external'
        }
        if (link.startsWith('/')) {
            return 'internal'
        }
    }
    return 'text'
}
