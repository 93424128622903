import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useNavHistory } from '@store/store'

const NavigationHistory: React.FC<unknown> = () => {
    const history = useHistory()
    const [actionEvent, _setActionEvent] = useState<string>('')

    const forwardCount = useNavHistory(state => state.forwardCount)
    const backwardCount = useNavHistory(state => state.backwardCount)
    const updatePushState = useNavHistory(state => state.updatePushState)
    const updateForwardSate = useNavHistory(state => state.updateForwardState)
    const updateBackwardState = useNavHistory(
        state => state.updateBackwardState,
    )

    const actionEventRef = React.useRef(actionEvent)
    const setActionEvent = (data: any) => {
        actionEventRef.current = data
        _setActionEvent(data)
    }

    useEffect(() => {
        const unlisten = history.listen((location, action, ...arg) => {
            if (action === 'PUSH') {
                updatePushState()
            } else if (action === 'POP') {
                if (actionEventRef.current === 'Forward') {
                    updateForwardSate()
                } else {
                    updateBackwardState()
                }
            }
        })
        return function cleanup() {
            unlisten()
        }
    }, [])

    return (
        <div className="flex p-1">
            {backwardCount < 1 ? (
                <button className="opacity-50 cursor-default">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="gray"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M15 19l-7-7 7-7"
                        />
                    </svg>
                </button>
            ) : (
                <button
                    onClick={() => {
                        setActionEvent('Backward')
                        history.goBack()
                    }}
                    className='focus:outline-none bg-white dark:bg-gray-900 text-gray-700 select-none" data-info="side-nav'
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M15 19l-7-7 7-7"
                        />
                    </svg>
                </button>
            )}
            {forwardCount < 1 ? (
                <button className="opacity-50 cursor-default">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="gray"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M9 5l7 7-7 7"
                        />
                    </svg>
                </button>
            ) : (
                <button
                    onClick={() => {
                        setActionEvent('Forward')
                        history.goForward()
                    }}
                    className="h-4 w-4 focus:outline-none bg-white dark:bg-gray-900 text-gray-700 select-none"
                    data-info="side-nav"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M9 5l7 7-7 7"
                        />
                    </svg>
                </button>
            )}
        </div>
    )
}

export default NavigationHistory
