import React, { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react'
import { useAutoComplete } from '@hooks/useAutoComplete'
import { useOutsideComponent } from '@/hooks/useOutsideComponent'
import { ChevronRightIcon } from '@heroicons/react/solid'
import { useProfileStore, useResourceHistory } from '@/store/store'
import { convertUnicode } from '@/utilities/contents'
import { useHistory, useLocation } from 'react-router-dom'
import { SearchCategory } from './SearchCategory'
import { useAppLinkOpener } from '@/hooks/useAppLinkOpener'
import SuggestionList from '../SearchInput/SuggestionList'
import SearchHistoryList from '../SearchInput/SearchHistoryList'
import ResourceHistoryList from './ResourceHistoryList'
import AppList from './AppList'
import { useAppContext } from '@/AppContext'
import { getSearchHistoryList, getSearchResults, postRecentSearch } from '@/services/SearchService'
import { SearchIcon } from '@/components/icons/SearchIcon'
import { SpinnerCircleSVG } from '@/components/icons/SpinnerCircleSVG'
import eventEmitter from '@/utilities/eventEmitter'
import useAnalyticsEventTracker from '@/hooks/useAnalyticsEventTracker'
import { GAAction } from '@/types/GAnalyticsTypes'
import { useDebouncedCallback } from 'use-debounce/lib'
import { projecturl } from '@/utilities/project'

const COMPONENT_IDENTIFIER = 'Search Input'

interface Props {
    maxHeight: string
    setAnimation?: Dispatch<SetStateAction<boolean>>
}

export const SearchInputBox: React.FC<Props> = React.memo(({ maxHeight, setAnimation }: Props) => {
    const appHistoryList = useResourceHistory(store => store.appList)
    const resHistoryList = useResourceHistory(store => store.resourceList)
    const searchHistoryList = useResourceHistory(store => store.searchHistory);
    const [showSuggestionsBox, setShowSuggestionsBox] = useState(false)
    const [searching, setSearching] = useState(false)
    const [suggestionList, setSuggestionList] = useState<any>([])
    const [appSuggList, setAppSuggList] = useState<any>([])
    const wrapperRef = useRef<HTMLDivElement>(null)

    const setSearchHistory = useResourceHistory(
        useCallback(state => state.setSearchHistory, []),
    )

    const {
        updateField,
        showResult: showSearchedResult,
        profileSearch,
        keyword,
        getToken,
    } = useAutoComplete()
    const app = useAppContext()

    const handdlerOutsideClick = () => {
        profileSearch()
        setShowSuggestionsBox(false)
        if (setAnimation)
            setAnimation(false)
        // updateField('keyword', '', false)
    }
    useOutsideComponent(wrapperRef, handdlerOutsideClick)
    const history = useHistory()
    const location = useLocation()

    const { recordUseHistory, getResourceHistory } = useAppLinkOpener()

    const setSelectedInnovatorProfileEmail = useProfileStore(
        useCallback(state => state.setSelectedInnovatorProfileEmail, []),
    )

    useEffect(() => {
        setSuggestionList([])
        if (keyword.length > 0) {
            getSuggestionList()
        }
    }, [keyword])

    useEffect(() => {
        getResourceHistory()
        getSearchHistory()
    }, [app.user])

    useEffect(() => {
        eventEmitter.addListener("closeMoreApps", handdlerOutsideClick);
        return () => {
            eventEmitter.removeListener("closeMoreApps", handdlerOutsideClick);
        }
    }, [])
    useEffect(() => {
        const params = new URLSearchParams(history.location.search)
        const searchTerm = params.get("text") || ""
        updateField('keyword', searchTerm, false)
    }, [history.location.search])


    const getSearchHistory = async () => {
        if (app.user) {
            try {
                const token = await getToken()
                const response = await getSearchHistoryList(token)
                if (response.length) {
                    setSearchHistory(response)
                }
            } catch (err: any) {
                console.log(err)
            }
        }
    }
    const getSuggestionList = useDebouncedCallback(async () => {
        const catArr = [SearchCategory.HOW.toLowerCase(), SearchCategory.WHO.toLowerCase(), "relevant_projects", SearchCategory.APPLICATION.toLowerCase()]
        const suggList: any = []
        if (app.user) {
            try {
                const token: string = await getToken()
                const searchPromise: any = [];
                setSearching(true)
                catArr.map((item) => {
                    const searchRes = getSearchResults(
                        token,
                        keyword,
                        1,
                        app.authProvider!,
                        '',
                        item,
                        3
                    )
                    searchPromise.push(searchRes)
                    searchRes.then((res: any) => {
                        let suggCategory = {}
                        if (res.length && res[0]?.category !== SearchCategory.APPLICATION) {
                            suggCategory = { category: res[0]?.category, list: res[0]?.data }
                            suggList.push(suggCategory)
                            setSuggestionList([...suggList])
                        }
                        if (res.length && res[0]?.category === SearchCategory.APPLICATION) {
                            setAppSuggList(res[0]?.data)
                        }

                    })

                })
                await Promise.all(searchPromise).then((values) => {
                    setSearching(false)
                });
            } catch (err) {
                console.log((err as any).message)
            }
        }
    }, 500)
    const gaEventTracker = useAnalyticsEventTracker(COMPONENT_IDENTIFIER);
    const debounceGATracker = useDebouncedCallback(gaEventTracker, 3000);
    const onSubmitKeyword = async (text: string) => {
        try {
            gaEventTracker(GAAction.COMPONENT_USED, "Searched for: " + text)
            const payload: any = {
                searchText: text,
            }
            const token: string = await getToken()
            const res = await postRecentSearch(token, payload)
            getSearchHistory()
        } catch (err) {
            console.log((err as any).message)
        }
    }
    const redirectToAdvanceSearch = (text: string) => {
        onSubmitKeyword(text)
        setShowSuggestionsBox(false)
        if (setAnimation)
            setAnimation(false)
        updateField('keyword', text, false)

        history.push(`/search?&text=${text}`)
    }

    const searchBarStyle = showSuggestionsBox
        ? 'border-t border-r border-l rounded-t-md overflow-hidden flex bg-baby-gray-950 border-gray-400  '
        : 'border border-gray-400 hover:border-primary rounded-md border-solid overflow-hidden flex bg-baby-gray-950 dark:bg-baby-gray-900 dark:text-gray-50 flex-2  align-middle'

    const serachClickHanddler = () => {
        let time = 0
        if (setAnimation) {
            setAnimation(true)
            time = 500
        }
        setTimeout(() => setShowSuggestionsBox(true), time)
    }

    const navigateHandler = (item: any, calledBy: string) => {
        const nameWithEmail =
            calledBy === 'sugg' && item.category === SearchCategory.WHO
                ? { ...item, name: item.name + ',' + item.email }
                : calledBy === 'sugg' && item.category === SearchCategory.RELEVENT_PROJECT ? { ...item, calledFrom: "sugg" } : item
        recordUseHistory(nameWithEmail)
        if (item.category === SearchCategory.HOW) {
            if (item.url) {
                window.open(`${item.url}?web=1`, '_blank')
            }
        } else if (item.category === SearchCategory.RELEVENT_PROJECT) {
            const id = calledBy === 'recentResouces' ? item.resourceId : item.id
            const url = calledBy === 'recentResouces' ? item.url : projecturl(item.src, id, item.phaseId, item.pmisProjectId)
            if (url && item.openInNewTab === 0) {
                history.push(url)
            } else {
                window.open(url, '_blank')
            }
        } else if (item.category === SearchCategory.WHO) {
            const email =
                calledBy === 'recentResouces'
                    ? item.name.split(',')[1]
                    : item.email
            if (app.hasInnovatorPrivilege && email) {
                setSelectedInnovatorProfileEmail(email)
                history.push(encodeURI(`/innovator?app=Innovator Profile`))
            }
        }
        setShowSuggestionsBox(false)
        updateField('keyword', "", false)
        if (setAnimation)
            setAnimation(false)
    }

    const displaySearchList = (category: string) => {
        history.push(`/search?type=${category.toLowerCase()}&text=${keyword}`)
        setShowSuggestionsBox(false)
    }

    const showResult = (text: string, page: number, email?: string) => {
        history.push(`/search?type=&text=${text}`)
        showSearchedResult(text, page, email)
        setShowSuggestionsBox(false)
    }

    const seeMoreOptionsHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.code === "Enter" && keyword.length > 1) {
            redirectToAdvanceSearch(keyword)
        }
    }

    const appList = keyword.length > 0 ? appSuggList : appHistoryList

    return (
        <div
            className="flex flex-col flex-1 mx-5 caret-transparent"
            ref={wrapperRef}
            data-info="search"
        >
            <form
                className="relative"
                onSubmit={e => {
                    e.preventDefault()
                }}
                autoComplete="off"
            >
                <div className={searchBarStyle}>
                    <input
                        type="text"
                        name="search"
                        placeholder="ASK me a technical topic to search for..."
                        value={convertUnicode(keyword)}
                        onChange={e => {
                            updateField('keyword', e.target.value, false)
                            debounceGATracker(GAAction.WIDGET_USED, `Searched for: ${e.target.value}`)
                        }}
                        onKeyDown={e => {   
                            seeMoreOptionsHandler(e)
                        }}
                        onClick={() => serachClickHanddler()}
                        className="text-sm text-gray-700 font-medium font-sans bg-baby-gray-950 flex-1 focus:ring-0 dark:bg-gray-800 dark:text-gray-50 dark:border-gray-600 border-0"
                    />
                    <button
                        className="mr-2 bg-baby-gray-950"
                        onClick={() => {
                            if (keyword.length) {
                                showResult(keyword, 1)
                            }
                        }}
                    >
                        {keyword.length > 0 && !searching ? (
                            <ChevronRightIcon className="w-6 h-6 text-primary dark:text-gray-50" />
                        ) : keyword.length > 0 && searching ? (
                            <SpinnerCircleSVG />
                        ) : (
                            <SearchIcon className="h-5 w-5 text-baby-gray-50 hover:text-primary text-o" />
                        )}
                    </button>
                </div>

                {showSuggestionsBox ? (
                    <div
                        style={{ zIndex: 99999, maxHeight }}
                        className="absolute border-gray-400 border-r border-l border-b rounded-b-md bg-baby-gray-900 shadow flex flex-col w-full  overflow-y-auto"
                    >
                        <div style={{ position: "sticky", top: 0 }} className=" border-t border-gray-400 mx-3 fixed "></div>
                        {keyword.length > 0 && suggestionList.length ? (
                            <>
                                {
                                    suggestionList.map((item: { list: any[]; category: string }) => {
                                        return (
                                            <>
                                                <SuggestionList
                                                    suggestionList={item.list}
                                                    navigateHandler={navigateHandler}
                                                    seeMoreClickHandler={displaySearchList}
                                                    category={item.category}
                                                />
                                                <div className=" border-t border-gray-400 mr-3 ml-3"></div>
                                            </>
                                        )
                                    })
                                }
                            </>

                        ) : null}
                        {(keyword.length === 0) && searchHistoryList.length > 0 && (
                            <>
                                <SearchHistoryList
                                    searchHistoryList={searchHistoryList}
                                    redirectToAdvanceSearch={
                                        redirectToAdvanceSearch
                                    }
                                />
                                <div className=" border-t border-gray-400 mr-3 ml-3"></div>
                            </>
                        )}
                        {appList.length > 0 && (
                            <>
                                <AppList
                                    appList={appList}
                                    heading={
                                        keyword.length
                                            ? 'App Suggestions'
                                            : 'Recent Apps'
                                    }
                                    seeMore={keyword.length ? true : false}
                                    keyword={keyword}
                                    calledBy="homeSearchBar"
                                    closeSuggBox={setShowSuggestionsBox}
                                />
                                <div className=" border-t border-gray-400 mr-3 ml-3"></div>
                            </>
                        )}
                        {(searchHistoryList.length > 0 && appSuggList.length > 0) && (
                            <>
                                <SearchHistoryList
                                    searchHistoryList={searchHistoryList}
                                    redirectToAdvanceSearch={
                                        redirectToAdvanceSearch
                                    }
                                />
                                <div className=" border-t border-gray-400 mr-3 ml-3"></div>
                            </>
                        )}

                        {resHistoryList.length > 0 && (
                            <ResourceHistoryList
                                resHistoryList={resHistoryList}
                                navigateHandler={navigateHandler}
                            />
                        )}
                    </div>
                ) : null}
            </form>
        </div>
    )
})
