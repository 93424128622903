import * as React from 'react'
import { useHistory } from 'react-router-dom'
// import {UserIcon}from '@heroicons/react/solid'
import {
    IntermixDashboardShellProps,
    IntermixBaseProfileShellProps,
    IntermixBaseSideNavShellProps,
    IntermixLinkProps,
    IHeaderProfileMenu,
    IProfileMenu,
} from './types'
import { NavDropDown } from './NavDropDown'
import { SearchInput } from '@components/blocks/SearchInput'
import { SearchInputBox } from '@components/blocks/SearchInput/SearchInputBox'
import { callGetAllNotifications, Notification } from '@/services'
import { BulbSVG } from '../icons/BulbSVG'
import { UserIcon, ArrowSmLeftIcon, ArrowSmRightIcon, SearchIcon } from '@heroicons/react/solid'
import { LogoutIcon } from '@heroicons/react/outline'
import { ProfileMenuItem } from './ProfileMenuItem'
import { useAppContext } from '../../AppContext'
import { Transition } from '@headlessui/react'
import { NotificationType } from '@/services/UOPService'
import IntermixNotificationMenu from './IntermixNotificationMenu'
import { useMsal } from '@azure/msal-react'
import { useApplicationPreferencesStore, useStore } from '@/store/store'
import { getAppConfig } from '@/Config'
import { MessagesWidget } from '../blocks/messages/MessagesWidget'
import Tippy from '@tippyjs/react'

import { IntermixSideNavList } from './IntermixSideNavList'
import { FeedbackWidget } from '../blocks/FeedbackWidget'
import { Link } from 'react-router-dom'
import NavigationHistory from './NavigationHistory'
import eventEmitter from '@/utilities/eventEmitter'
import useAnalyticsEventTracker from '@/hooks/useAnalyticsEventTracker'
import { GAAction } from '@/types/GAnalyticsTypes'
import { MenuIcon } from '../icons/Menulogo'

export const baseSideNavConfig: IntermixBaseSideNavShellProps = {
    margin: 'mt-2',
    activeStyles:
        'mt-1 py-1 bg-primary text-sm text-white dark:hover:text-secondary-100  dark:hover:bg-gray-800 rounded',
    styles: 'mt-1 py-1 bg-gray-50 text-sm text-gray-500 dark:text-gray-400 hover:text-white dark:hover:text-secondary-100  hover:bg-primary dark:bg-gray-700 dark:hover:bg-gray-800 rounded',
}

export const baseProfileConfig: IntermixBaseProfileShellProps = {
    styles: 'inline-flex px-4 py-2 text-sm text-start text-gray-500 dark:text-gray-400 hover:text-white dark:hover:text-secondary-100  hover:bg-primary dark:bg-gray-700 dark:hover:bg-gray-800 overflow-hidden rounded-md',
}

const profileLinks: IntermixLinkProps[] = [
    {
        id: 1,
        title: 'My Profile',
        icon: <UserIcon className="h-4 w-4" />,
        path: encodeURI(`/innovator?app=Innovator Profile&profile=me`),
    },
    {
        id: 2,
        title: 'Log Out',
        path: '/logout',
        icon: <LogoutIcon className="h-4 w-4" />,
        isAction: true,
    },
    {
        id: 3,
        title: 'Saved Search',
        path: encodeURI(`/savedsearch`),
        icon: <SearchIcon className="h-4 w-4" />

    }
]

export const profileMenuConfig: IProfileMenu = {
    ...baseProfileConfig,
    links: profileLinks,
    component: ProfileMenuItem,
    avatar: 'https://avatars.githubusercontent.com/u/846306?s=460&u=93c59f5b56005a771470afd453aff70e248ae3e3&v=4',
    name: 'John Doe',
}

export const getInitials = (fullName: string): string => {
    const namesArray = fullName.trim().split(' ')
    if (namesArray.length === 1) return `${namesArray[0].charAt(0)}`
    else
        return `${namesArray[0].charAt(0)}${namesArray[
            namesArray.length - 1
        ].charAt(0)}`
}

export const IntermixDashboardShell: React.FC<IntermixDashboardShellProps> = ({
    children,
    sideNav,
    currentPath,
    currentSearch = '',
    searchHandler,
}) => {
    const loginRequest = { scopes: getAppConfig().scopes }
    const app = useAppContext()
    const themeSelection = useApplicationPreferencesStore(
        store => store.themeSelection,
    )
    const setNotifications = useStore(
        React.useCallback(state => state.setNotifications, []),
    )

    const setInfoMode = useStore(
        React.useCallback(state => state.setInfoMode, []),
    )
    const infoMode = useStore(state => state.infoMode)

    const { instance, accounts } = useMsal()
    const [header, setHeader] = React.useState<IHeaderProfileMenu>({
        title: 'K',
        title2: 'onnect',
        profileMenu: {
            ...profileMenuConfig,
            name: '',
        },
    })
    const [sidebarOpen, setSidebarOpen] = React.useState(false)

    const [darkMode, setDarkMode] = React.useState(
        localStorage.getItem('darkMode') === 'true' ?? false,
    )
    const [profile, setProfile] = React.useState(false)

    React.useEffect(() => {
        if (app.user && app.user.displayName) {
            const newHeader: IHeaderProfileMenu = {
                title: 'K',
                title2: 'onnect',
                profileMenu: {
                    ...profileMenuConfig,
                    name: app.user.displayName,
                    initials: app.user.displayName
                        ? getInitials(app.user.displayName)
                        : '',
                },
            }

            setHeader(newHeader)
            loadNotifications()
        }
    }, [app.user])

    React.useEffect(() => {
        setThemeOnHTML()
        localStorage.setItem('darkMode', darkMode ? 'true' : 'false')
    }, [darkMode])

    const setThemeOnHTML = () => {
        document.documentElement.setAttribute(
            'data-theme',
            darkMode ? 'dark' : 'light',
        )
        document.body?.classList.remove(darkMode ? 'light' : 'dark')
        document.body?.classList.add(darkMode ? 'dark' : 'light')
    }

    const getNotificationType = (category: string): NotificationType => {
        let result = NotificationType.GeneralEvent
        if (category.toLowerCase().includes('task')) {
            result = NotificationType.TaskEvent
        } else if (category.toLowerCase().includes('project')) {
            result = NotificationType.ProjectEvent
        } else if (category.toLowerCase().includes('calendar')) {
            result = NotificationType.CalendarEvent
        }
        return result
    }

    const processNotifications = (notifications: Notification[]) => {
        if (notifications && Array.isArray(notifications)) {
            return notifications.map(notification => {
                return {
                    ...notification,
                    type: getNotificationType(notification.category || ''),
                }
            })
        }
        return notifications
    }

    const loadNotifications = async () => {
        if (app.user) {
            try {
                instance
                    .acquireTokenSilent({
                        ...loginRequest,
                        account: accounts[0],
                    })
                    .then(response => {
                        callGetAllNotifications(response.accessToken).then(
                            response => {
                                const notifications =
                                    processNotifications(response)
                                //   console.log(notifications)
                                setNotifications(notifications)
                            },
                            error => {
                                console.log(error)
                            },
                        )
                    })
            } catch (err: any) {
                console.log(err)
            }
        }
    }

    const handleDropDownEvent = (name: string) => {
        if (name === 'profile') {
            setProfile(profile => !profile)
        } else {
            setProfile(false)
        }
    }

    const [feedbackIdentifier, setFeedbackIdentifier] =
        React.useState<string>('')

    React.useEffect(() => {
        if (currentSearch.length > 0) {
            setFeedbackIdentifier(currentSearch.split('=')[1] ?? currentSearch)
        } else if (currentPath && currentPath.length > 0) {
            setFeedbackIdentifier(currentPath.split('/')[1] ?? currentPath)
        } else {
            setFeedbackIdentifier('')
        }
    }, [currentPath, currentSearch])

    window.addEventListener('message', function (event: MessageEvent) {
        if (event.data == 'logout') {
            instance.logout()
            window.location.reload()
        }
    })

    const history = useHistory()
    const [navHistoryPosition, setNavHistoryPosition] = React.useState(3)
    // const [nextButtonEnabled, setNextButtonEnabled] = React.useState(false)
    // const [previousButtonEnabled, setPreviousButtonEnabled] = React.useState(false)

    // React.useEffect(() => {
    //     (navHistoryPosition < 4) ? setPreviousButtonEnabled(false) : setPreviousButtonEnabled(true);
    //     (navHistoryPosition > history.length - 2) ? setNextButtonEnabled(false) : setNextButtonEnabled(true);
    // }, [navHistoryPosition])

    const [ifSidebar, setIfSidebar] = React.useState(true)
    const [ifHeader, setIfHeader] = React.useState(true)

    const toggleSideBar_Header = (params: any) => {
        setIfSidebar(params.status)
        setIfHeader(params.status)
    }
    React.useEffect(() => {
        eventEmitter.addListener("sideTopBar", toggleSideBar_Header);
        return () => {
            eventEmitter.removeListener("sideTopBar", toggleSideBar_Header)
        }
    }, [])

    return (
        <div
            className={`${darkMode ? 'dark' : ''
                } flex flex-col overflow-hidden ${infoMode ? '' : 'h-screen'} caret-transparent select-none`}
        >

            {ifHeader && header && (

                <header className="flex w-full shadow-md h-14 justify-between items-center border-b dark:border-gray-900 z-max bg-white dark:bg-gray-900">

                    <div className="flex items-center h-full mr-2">

                        <div className="flex items-center h-11 mr-2">
                            <Link
                                to={'/'}
                                className="focus:outline-none mt-6 pl-3 pr-2 pt-1 bg-baby-black-50 dark:bg-gray-900 text-gray-700 items-center select-none w-14 h-20"
                            >
                                <MenuIcon width="30" height="50" />
                            </Link>
                            <NavigationHistory />
                        </div>
                        <Link
                            to={'/'}
                            draggable={false}
                            className="text-black font-extrabold font-sans text-xl dark:text-secondary-100 flex ml-4"
                        >
                            <p>Konnect</p>
                        </Link>
                    </div>
                    <div
                        className=" flex-1 w-full hidden md:flex"
                    >
                        {/* <SearchInput /> */}
                        <SearchInputBox maxHeight={`calc(100vh - 150px)`} />
                    </div>
                    <div className="flex mb-3 mt-2">
                        <div className="flex flex-1 w-full justify-end align-middle space-x-2 space-y-0 top-2 bottom-2.5">
                            <button
                                className="flex text-primary dark:text-secondary-100 focus:outline-none md:hidden rounded-full p-2 bg-gray-50 hover:bg-gray-100 dark:bg-gray-500 transition duration-300 ease-in-out transform hover:scale-105"
                                onClick={() => searchHandler && searchHandler()}
                            >
                                <svg
                                    className="h-6 w-6"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                >
                                    <path
                                        d="M21 21L15 15M17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10Z"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </button>
                            <Tippy content="Portal Tour">
                                <button
                                    className="flex p-1.5 shadow-md bg-baby-gray-200 relative text-baby-gray-50 hover:text-primary dark:text-secondary-100 focus:outline-none align-middle rounded-full h-9  hover:bg-baby-gray-200  dark:bg-gray-800 transition duration-300 ease-in-out transform hover:scale-105  disabled:opacity-40" style={{ marginTop: "4px" }}
                                    onClick={() => {
                                        setInfoMode(!infoMode)
                                        useAnalyticsEventTracker('Portal Tour')(GAAction.COMPONENT_USED, 'Prtal tour click')
                                    }}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-6 w-6"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </button>
                            </Tippy>
                            {themeSelection && (
                                <Tippy content="Theme">
                                    <button
                                        onClick={() => setDarkMode(!darkMode)}
                                        className="text-gray-400 hover:text-primary dark:text-secondary-100 focus:outline-none align-middle rounded-full p-2 bg-gray-50 hover:bg-gray-100 hidden md:flex dark:bg-gray-800 transition duration-300 ease-in-out transform hover:scale-105"
                                        aria-label="Color Mode"
                                    >
                                        {darkMode && (
                                            <svg
                                                className="h-6 w-6"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
                                                    clipRule="evenodd"
                                                />
                                            </svg>
                                        )}
                                        {!darkMode && (
                                            <svg
                                                className="h-6 w-6"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                            >
                                                <path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z" />
                                            </svg>
                                        )}
                                    </button>
                                </Tippy>
                            )}

                            <IntermixNotificationMenu />

                            <MessagesWidget />
                            <FeedbackWidget
                                identifier={`${feedbackIdentifier} Page`}
                            />
                        </div>

                        {header && header.profileMenu && (
                            <div className="flex relative mx-2 text-gray-400 rounded-full hover:bg-baby-gray-200 
                            bg-baby-gray-200 shadow-md hover:text-primary dark:bg-baby-gray-200
                             transition duration-300 ease-in-out  mr-6 h-9 p-1.5" style={{ marginTop: '4px' }}>
                                <Tippy content="My Account">
                                    <button
                                        onClick={() =>
                                            handleDropDownEvent('profile')
                                        }
                                        className="flex bg-baby-gray-200 relative text-baby-gray-50 hover:text-primary dark:text-secondary-100 focus:outline-none align-middle rounded-full hover:bg-baby-gray-200  dark:bg-gray-800 transition duration-300 ease-in-out transform hover:scale-105  disabled:opacity-40 "
                                        data-info="account"
                                    >
                                        <UserIcon className='w-6 h-6 left-2' />
                                    </button>
                                </Tippy>
                                <div className="absolute right-0 mt-12 w-48 bg-white dark:bg-gray-800 rounded-md overflow-hidden shadow-xl z-10 ">
                                    {profile && (
                                        <Transition
                                            appear={true}
                                            show={profile}
                                            enter="transition-opacity duration-500 ease-out"
                                            enterFrom="opacity-0"
                                            enterTo="opacity-100"
                                            leave="transition-opacity duration-50 ease-out"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0"
                                        >
                                            <NavDropDown
                                                open={profile}
                                                setOpen={() => setProfile(!open)}
                                                styles={
                                                    header.profileMenu.styles
                                                }
                                                links={header.profileMenu.links}
                                                component={
                                                    header.profileMenu.component
                                                }
                                                onClose={() =>
                                                    handleDropDownEvent(
                                                        'profile',
                                                    )
                                                }

                                            />
                                        </Transition>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </header>
            )}
            <div className="flex flex-1 overflow-x-hidden overflow-y-auto relative">
                <div className="flex-1 h-auto bg-baby-gray-900 dark:bg-gray-800 overflow-y-auto">
                    {ifSidebar && sideNav && (
                        <IntermixSideNavList
                            sideNavLinks={sideNav.menu.links}
                            open={sidebarOpen}
                            onSidebarOpen={(flag: boolean) =>
                                setSidebarOpen(flag)
                            }
                            onToggleSideNav={() =>
                                setSidebarOpen(sideBar => !sideBar)
                            }
                            linkUri={currentPath + currentSearch}
                            SideNavComponent={sideNav.menu.component}
                        />
                    )}

                    <div className="flex-1 flex flex-col overflow-y-auto h-full">
                        <main className={`flex-1 ${infoMode ? '' : 'overflow-x-hidden overflow-y-auto'}  
                        ${ifSidebar ? 'ml-14' : ""}`}>
                            {children}
                        </main>
                    </div>
                </div>
            </div>
        </div>
    )
}
