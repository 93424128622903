import {
    DocumentIcon,
    AcademicCapIcon,
    UserIcon,
} from '@heroicons/react/solid'
import { SearchCategory } from '../SearchInput/SearchCategory'

export const IconDisplay = (type: string) => {
    if (type === SearchCategory.HOW) {
        return <DocumentIcon className="w-4 h-4 text-blue-300 " />
    } else if (type === SearchCategory.RELEVENT_PROJECT) {
        return <AcademicCapIcon className="w-4 h-4 text-blue-300 " />
    } else if (type === SearchCategory.WHO) {
        return <UserIcon className="w-4 h-4 text-blue-300 " />
    } else if (type === "History") {
        return <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="grey" stroke-width="2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
    }
return null
}