import { MessageMember, MessageMembersTitle } from './MessageMembersTitle'
import { MessageMembersAvatar } from './MessageMembersAvatar'
import { LastMessage } from './LastMessage'
import { formatDistanceToNowStrict } from 'date-fns'
import { MessagesContext } from './MessageContext'
import { Menu } from '@headlessui/react'
import { ChatIcon } from '@heroicons/react/solid'
import Tippy from '@tippyjs/react'
import { useEffect, useState } from 'react'
import { callSearchPeople } from '@/services/UOPService'
import { useMsal } from '@azure/msal-react'
import { getAppConfig } from '@/Config'
import { Avatar } from '@/components/atoms/Avatar'
import useAnalyticsEventTracker from '@/hooks/useAnalyticsEventTracker'
import { GAAction } from '@/types/GAnalyticsTypes'
import Vectormessage from '@/assets/Vectormessage.svg'
import { MessageIcon } from '@/components/icons/Messagelogo'

export const MessagesList: React.FC = () => {
    const [prevSearch, setPrevSearch] = useState<string>(' ')
    const [search, setSearch] = useState<string>('')
    const [searchResults, setSearchResults] = useState([])
    const gaEventTracker = useAnalyticsEventTracker('Header Messages')

    const { instance: msal, accounts } = useMsal()
    const loginRequest = { scopes: getAppConfig().scopes }
    useEffect(() => {
        if (prevSearch !== search) {
            searchPeople(search)
            setPrevSearch(search)
        }
    }, [search])

    const searchPeople = (search: string) => {
        if (search.length > 0) {
            msal.acquireTokenSilent({
                ...loginRequest,
                account: accounts[0],
            })
                .then(response => {
                    callSearchPeople(search, response.accessToken).then(res => {

                        setSearchResults(res)
                    })
                })
                .catch(err => {
                    console.error(err)
                })
        }
    }

    return (
        <Menu
            as="div"
            className="relative inline-block text-left"
            data-info="messages"
        >
            {({ open }) => (
                <>
                    <Tippy content="Messages">
                        <Menu.Button
                            as="button"
                            className="flex bg-baby-gray-200 shadow-md p-1 relative text-baby-gray-50 hover:text-primary dark:text-secondary-100 focus:outline-none align-middle rounded-full  h-9  hover:bg-baby-gray-200  dark:bg-gray-800 transition duration-300 ease-in-out transform hover:scale-105" style={{ marginTop: "4px" }}
                        >
                            <span
                                className="p-1 hover:text-primary"
                                onClick={() => {
                                    gaEventTracker(
                                        GAAction.COMPONENT_USED,
                                        'Clicked',
                                    )
                                }}
                            >

                                <MessageIcon width="20" height="20" />
                            </span>
                        </Menu.Button>
                    </Tippy>
                    <MessagesContext.Consumer>
                        {({ chats, openChat, loading, createChat }) => {
                            return (
                                open && (
                                    <div
                                        className=" absolute right-0 w-96 mt-3 origin-top-right focus:outline-none"
                                        style={{ height: '83.333vh' }}
                                    >
                                        <Menu.Items as="ul" className="h-full">
                                            <div className="flex   flex-col justify-start items-end shadow dark:bg-gray-900 dark:text-gray-100 rounded transition-all bg-white h-full">
                                                <div className="w-full  flex flex-col justify-start items-start overflow-y-auto divide-y divide-gray-100">
                                                    <div className="flex flex-col justify-center items-start px-3 pt-3 pb-1 w-full">
                                                        <div className="flex flex-row justify-between items-center w-full mb-2">
                                                            <p className="text-xl font-bold mr-2">
                                                                Messages
                                                            </p>
                                                            <input
                                                                type="text"
                                                                placeholder="Type to search"
                                                                onChange={e =>
                                                                    setSearch(
                                                                        e.target
                                                                            .value,
                                                                    )
                                                                }
                                                                className="my-1 block text-sm w-full rounded-md border-gray-300  focus:border-primary focus:ring focus:ring-primary focus:ring-opacity-50"
                                                            />
                                                        </div>
                                                        <div className="flex w-full "></div>
                                                    </div>
                                                    {searchResults &&
                                                        searchResults.length >
                                                        0 &&
                                                        search &&
                                                        search.length > 0 && (
                                                            <>
                                                                <div className=" flex justify-start  items-center w-full py-2 px-3 font-bold">
                                                                    Search
                                                                    results
                                                                </div>
                                                                {searchResults
                                                                    .slice(
                                                                        0,
                                                                        10,
                                                                    )
                                                                    .map(
                                                                        (
                                                                            user: userSearchResult,
                                                                        ) => (
                                                                            <Menu.Item
                                                                                as="li"
                                                                                className="w-full py-3 pl-3 hover:bg-gray-50  dark:hover:bg-gray-800 dark:border-gray-800 dark:text-gray-100 transition-all cursor-pointer"
                                                                                key={
                                                                                    user.id
                                                                                }
                                                                                onClick={() => {
                                                                                    gaEventTracker(
                                                                                        GAAction.COMPONENT_USED,
                                                                                        'Open Chat',
                                                                                    )
                                                                                    createChat(
                                                                                        user.id,
                                                                                    )
                                                                                    setSearch(
                                                                                        '',
                                                                                    )
                                                                                    setSearchResults(
                                                                                        [],
                                                                                    )
                                                                                }}
                                                                            >
                                                                                <div className="flex flex-row justify-between items-center w-full">
                                                                                    <div className="text-sm flex flex-row items-center">
                                                                                        <Avatar
                                                                                            className="w-12 h-12 mr-2"
                                                                                            name={
                                                                                                user.displayName
                                                                                            }
                                                                                            userId={
                                                                                                user.id
                                                                                            }
                                                                                        />
                                                                                        <div className="flex flex-col items-start">
                                                                                            {
                                                                                                user.displayName
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </Menu.Item>
                                                                        ),
                                                                    )}
                                                                <div className="flex justify-start items-center w-full py-2 px-3 font-bold">
                                                                    Existing
                                                                    conversations
                                                                </div>
                                                            </>
                                                        )}
                                                    {chats &&
                                                        !loading &&
                                                        chats.map(
                                                            (
                                                                chat: Chat,
                                                                index,
                                                            ) => (
                                                                <Menu.Item
                                                                    as="li"
                                                                    className="w-full  py-3 pl-3 hover:bg-gray-50 dark:hover:bg-gray-800 dark:border-gray-800 dark:text-gray-100 transition-all cursor-pointer"
                                                                    key={index}
                                                                    onClick={() => {
                                                                        gaEventTracker(
                                                                            GAAction.COMPONENT_USED,
                                                                            'Open Chat',
                                                                        )
                                                                        openChat(
                                                                            chat.id,
                                                                        )
                                                                        open =
                                                                            false
                                                                    }}
                                                                >
                                                                    <div className="  flex flex-row justify-between items-center w-full">
                                                                        <div className="  text-sm flex flex-row items-center w-9/12">
                                                                            <MessageMembersAvatar
                                                                                chat={
                                                                                    chat
                                                                                }
                                                                            />
                                                                            <div className=" flex flex-col items-start">
                                                                                {chat.topic ?? (
                                                                                    <MessageMembersTitle
                                                                                        members={
                                                                                            chat.members
                                                                                        }
                                                                                        lastMessage={
                                                                                            chat
                                                                                                .messages[0]
                                                                                        }
                                                                                        chatType={
                                                                                            chat.chatType
                                                                                        }
                                                                                    />
                                                                                )}

                                                                                <LastMessage
                                                                                    messages={
                                                                                        chat.messages
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="text-sm w-3/12">
                                                                            {formatDistanceToNowStrict(
                                                                                chat
                                                                                    .messages
                                                                                    ?.length >
                                                                                    0
                                                                                    ? new Date(
                                                                                        chat.messages[0].lastModifiedDateTime,
                                                                                    )
                                                                                    : new Date(
                                                                                        chat.lastUpdatedDateTime,
                                                                                    ),
                                                                                {
                                                                                    addSuffix:
                                                                                        true,
                                                                                },
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </Menu.Item>
                                                            ),
                                                        )}
                                                    {chats.length === 0 && !loading && (
                                                        <div className="  w-full flex justify-center items-center mt-3">
                                                            <p className="text-sm">
                                                                No messages
                                                            </p>
                                                        </div>
                                                    )}
                                                    {loading && (
                                                        <div className="w-full flex justify-center items-center mt-3">
                                                            <p className="text-sm">
                                                                loading...
                                                            </p>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </Menu.Items>
                                    </div>
                                )
                            )
                        }}
                    </MessagesContext.Consumer>
                </>
            )}
        </Menu>
    )
}

export type Chat = {
    id: string
    opened: boolean
    minimized: boolean
    chatType: string
    createdDateTime: string
    lastUpdatedDateTime: string
    topic: string
    messages: Message[]
    members: MessageMember[]
}

export type Message = {
    attachments: any[]
    body: {
        contentType: `html` | `text`
        content: string
    }
    channelIdentity: string
    chatId: string
    createdDateTime: string
    deletedDateTime: string
    etag: string
    eventDetail: {
        '@odata.type': string
        chatDisplayName?: string
        chatId?: string
        callEventType?: string
        callDuration?: string

        initiator: MessageFrom
        members?: MessageMember[]
    }
    from: MessageFrom
    id: string
    importance: `normal` | `high` | `low`
    lastEditedDateTime: string
    lastModifiedDateTime: string
    locale: string
    mentions: any[]
    messageType: string
    policyViolation: any
    reactions: any[]
    replyToId: any
    subject: any
    summary: any
    webUrl: any
}

type MessageFrom = {
    application: {
        applicationIdentityType: string
        displayName: string
        id: string
    }
    device: any
    user: {
        id: string
        displayName: string
        userIdentityType: string
    }
}

type userSearchResult = {
    id: string
    displayName: string
    givenName: string
    businessPhones: string[]
    jobTitle: string
    mail: string
    mobilePhone: string
    officeLocation: string
    preferredLanguage: string
    surname: string
    userPrincipalName: string
}
