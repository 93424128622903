export interface Message {
    width: string,
    height: string
}

export const MessageIcon: React.FC<Message> = ({ width, height }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 16 16" fill="currentcolor"
         xmlns="http://www.w3.org/2000/svg">
            <path d="M3.65 9.3125H12.65V8.1875H3.65V9.3125ZM3.65 6.875H12.65V5.75H3.65V6.875ZM3.65 4.4375H12.65V3.3125H3.65V4.4375ZM15.5 15.5L12.5 12.5H1.625C1.3375 12.5 1.07813 12.3844 0.846875 12.1531C0.615625 11.9219 0.5 11.6625 0.5 11.375V1.625C0.5 1.3375 0.615625 1.07813 0.846875 0.846875C1.07813 0.615625 1.3375 0.5 1.625 0.5H14.375C14.675 0.5 14.9375 0.615625 15.1625 0.846875C15.3875 1.07813 15.5 1.3375 15.5 1.625V15.5Z" />
        </svg>
    )
}