type loadingProps = {
    dotColor?: string
    dotWidth?: string
    dotHeight?: string
    width?: string
    height?: string
}

export const Loading: React.FC<loadingProps> = ({
    dotColor,
    dotWidth,
    dotHeight,
    width,
    height,
}: loadingProps) => {
    return (
        <div
            className={`flex items-center justify-center space-x-2 animate-pulse m-2 ${width} ${height}`}
        >
            <div
                className={`${dotWidth ?? 'w-2'} ${dotHeight ?? 'h-2'} ${
                    dotColor ?? 'bg-primary'
                } rounded-full`}
            ></div>
            <div
                className={`${dotWidth ?? 'w-2'} ${dotHeight ?? 'h-2'} ${
                    dotColor ?? 'bg-primary'
                } rounded-full`}
            ></div>
            <div
                className={`${dotWidth ?? 'w-2'} ${dotHeight ?? 'h-2'} ${
                    dotColor ?? 'bg-primary'
                } rounded-full`}
            ></div>
        </div>
    )
}
