import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

const Portal: React.FC<unknown> = ({ children }) => {
    const mount = document.getElementById('portal-root')
    const [el] = useState(document.createElement('div'))

    useEffect(() => {
        if (mount && el) {
            mount.appendChild(el)
        }
        return () => {
            if (mount) {
                mount.removeChild(el)
            }
        }
    }, [el, mount])

    return createPortal(children, el)
}

export default Portal
