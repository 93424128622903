import { useMsal } from '@azure/msal-react'
import { Chat, Message } from './MessagesList'

type messageMembersTitleProps = {
    members: MessageMember[]
    lastMessage: Message
    chatType: Chat[`chatType`]
}

export const MessageMembersTitle: React.FC<messageMembersTitleProps> = ({
    members,
    lastMessage,
    chatType,
}: messageMembersTitleProps) => {
    const getNameSplited = (name: string) => {
        const splited = name.split(' ')
        return splited.length > 1
            ? splited[0] +
                  ' ' +
                  (splited[1].length > 2 ? splited[1][0] : splited[2][0]) +
                  '.'
            : splited[0]
    }

    const userOid = useMsal().accounts[0].localAccountId ?? ``

    return (
        <>
            {members.length === 1 && chatType !== `meeting` && (
                <span>
                    {lastMessage.from?.application?.displayName ?? 'Only Me'}
                </span>
            )}
            {members.length === 1 && chatType === `meeting` && (
                <span>Meeting with {members[0].displayName}</span>
            )}
            {members.length == 2 && (
                <span>
                    {getNameSplited(
                        members[0].userId == userOid
                            ? members[1].displayName
                            : members[0].displayName,
                    )}
                </span>
            )}
            {members.length > 2 && (
                <span>
                    {getNameSplited(
                        members[0].userId == userOid && members.length > 1
                            ? members[1].displayName
                            : members[0].displayName,
                    )}{' '}
                    +{members.length - 1}
                </span>
            )}
        </>
    )
}

export type MessageMember = {
    displayName: string
    email: string
    id: string
    roles: string[]
    tenantId: string
    userId: string
    visibleHistoryStartDateTime: Date
}
