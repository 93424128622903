import { getAppConfig } from '@/Config'
import { setAuthenticatedUserinLS } from '@/utilities/localstorage'
import defaultMenuListJSON from './defaultMenuList.json'
const authAPIBaseURL = process.env.REACT_APP_AUTH_API_BASE_URL || ''
const authAPIURL = process.env.REACT_APP_AUTH_API_URI || ''
const graphConfig = {
    postUserAuthLogin: authAPIBaseURL + authAPIURL + '/auth/v1/login',
    getLeftMenuList: authAPIBaseURL + authAPIURL + '/auth/v1/get-left-menu-list',
    getLeftMenuOrder: authAPIBaseURL + authAPIURL + '/auth/v1/get-left-menu-list-order',
    setLeftMenuOrder: authAPIBaseURL + authAPIURL + '/auth/v1/set-left-menu-list-order'
}

export type LeftMenu = {
    id: number
    id_system: number
    str_title: string
    str_privilege: string
    str_hyperlink: string
    str_image_width: string
    str_image_base64: string
    dat_ins: string
    sideTopBar?: boolean
    openInNewTab?: boolean
    isExternalApp?: boolean
    shortName?: string
    pinned?:boolean

}

export const callPostAuthLogin = async (accessToken: string) => {
    if (authAPIBaseURL === '') {
        return null
    }
    return fetch(graphConfig.postUserAuthLogin, {
        method: 'POST',
        headers: {
            Authorization: accessToken,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    })
        .then(response => response.json())
        .catch(error => console.log(error))
}

export const callGetLeftMenuList = async (accessToken: string) => {
    if (authAPIBaseURL === '') {
        return null
    }
    return fetch(graphConfig.getLeftMenuList, {
        method: 'GET',
        headers: {
            Authorization: accessToken,
        },
    })
        .then(response => response.json())
        .catch(error => {
            console.log(error)
            // TODO: remove once there is a real menu list
            return []
        })
}

export const callLoginAndGetLeftMenuList = async (accessToken: string, result :any) => {
    if (result && result.id) {

        // successful login 
        setAuthenticatedUserinLS(result)
        const leftMenuList = await callGetLeftMenuList(accessToken)
        return leftMenuList
    } else {
        const mocks = getAppConfig().mocks
        if (mocks) {
            return defaultMenuListJSON
        } else {
            const emptyList = { type: 'success', code: 888, content: [] }
            return emptyList
        }
    }
}


export const getLeftMenuOrder = async () => {
    return fetch(graphConfig.getLeftMenuOrder, {
        method: 'GET',
        headers: {
            Authorization: localStorage.getItem(`idToken`) || "",
        },
    })
        .then(response => response.json())
        .catch(error => {
            console.log(error)
            return []
        })
}

export const setLeftMenuOrder = async (payload: any) => {
    return fetch(graphConfig.setLeftMenuOrder, {
        method: 'POST',
        headers: {
            Authorization: localStorage.getItem(`idToken`) || "",
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ "data": payload })
    })
        .then(response => response.json())
        .catch(error => console.log(error))
}
