import { Message } from './MessagesList'

type lastMessageProps = {
    messages: Message[]
}

export const LastMessage: React.FC<lastMessageProps> = ({
    messages,
}: lastMessageProps) => {
    return (
        <p className="text-gray-500 text-sm">
            {messages && messages.length > 0 ? (
                messages[0].body.content
                    .replace(/<[^>]+>/g, '')
                    .substring(0, 20)
            ) : (
                <span>No messages</span>
            )}
        </p>
    )
}
