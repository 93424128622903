import React, { useState, useRef } from 'react'
import useOnClickOutside from '../../hooks/useOnClickOutside'
import {
    ArrowNarrowLeftIcon,
    CheckIcon,
    RefreshIcon,
    ThumbUpIcon,
    ThumbDownIcon
} from '@heroicons/react/solid'
import Tippy from '@tippyjs/react'
import { Feedback, callPostFeedback } from '@/services/UOPService'
import { useAppContext } from '@/AppContext'
import { useMsal } from '@azure/msal-react'
import { getAppConfig } from '@/Config'
import { FeedbackMessage } from './FeedbackMessage'
import { Transition } from '@headlessui/react'
import { useApplicationPreferencesStore } from '@/store/store'
import { capitalizeWords } from '@/utilities/contents'

export interface FeedbackWidgetProps {
    identifier: string
}

export const FeedbackWidget: React.FC<FeedbackWidgetProps> = ({
    identifier,
}) => {
    const pageFeedbackExpanded = useApplicationPreferencesStore(
        store => store.pageFeedbackExpanded,
    )
    const app = useAppContext()
    const loginRequest = { scopes: getAppConfig().scopes }
    const { instance, accounts } = useMsal()
    const [show, setShow] = useState(false)
    const [page, setPage] = useState(1)
    const [comment, setComment] = useState<any>()
    const [commentHandler, setCommentHandler] = useState(false)
    React.useEffect(() => {
        if (page === 1) {
            setShow(pageFeedbackExpanded)
        }
    }, [pageFeedbackExpanded, page])
    const inputHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setComment(event.target.value)
    }
    const handleFeedback = async (
        type: 'negative' | 'positive' | 'comment',
    ) => {
        // API CALL TODO
        if (app.user) {
            const feedback: Feedback = {
                identifier,
                type,
                userEmail: app.user.email,
                comment: comment || '',
            }
            const response = await instance.acquireTokenSilent({
                ...loginRequest,
                account: accounts[0],
            })
            setCommentHandler(true)
            setPage(0)
            const feedbackResponse = await callPostFeedback(
                response.accessToken,
                feedback,
            )
            setCommentHandler(false)
            if (feedbackResponse && feedbackResponse.status === 200) {
                setPage(3)
            }
            setShow(true)
            setPage(3)
        }
    }

    const resetState = (show?: boolean) => {
        setPage(1)
        setComment(undefined)
        if (typeof show !== 'undefined') {
            setShow(show)
        }
    }

    const handleFeedBackForm = (type?: string) => {
        if (type === 'comment') {
            setPage(2)
        } else {
            setShow(false)
            setPage(1)
        }
    }
    const widgetHandler = () => {
        setShow(show => !show)
    }

    const ref = useRef(null);
    const handleClickOutside = () => {
        setPage(1)
    }
    useOnClickOutside(ref, handleClickOutside)

    return (
        <div
            className={`flex  bg-baby-gray-200 shadow-md relative p-0.5 text-baby-gray-50 h-9 hove:bg-gray-100 rounded-full  hover:text-primary dark:bg-gray-800 transition duration-300 ease-in-out ${show ? 'p-1' : 'p-2'
                }`} style={{ marginTop: '4px' }}
            data-info="feedback"
            ref={ref}
        >
            {show && (
                <>
                    <Transition
                        appear={true}
                        show={true}
                        enter="transition-opacity duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity duration-150"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Transition.Child>
                            {page === 1 && (
                                <div className="flex flex-row justify-between items-center border-r mr-1 pr-1 transition-all transform hover:scale-105">
                                    <Tippy
                                        content={`Like ${capitalizeWords(
                                            identifier,
                                        )}`}
                                    >
                                        <button
                                            onClick={() => {
                                                handleFeedback('positive')
                                            }}
                                            className="p-1 rounded-full hover:bg-gray-300 transition-all cursor-pointer"
                                        >
                                            😀
                                        </button>
                                    </Tippy>
                                    <Tippy
                                        content={`Dislike ${capitalizeWords(
                                            identifier,
                                        )}`}
                                    >
                                        <button
                                            onClick={() => {
                                                handleFeedback('negative')
                                            }}
                                            className="p-1 rounded-full hover:bg-gray-300 transition-all cursor-pointer"
                                        >
                                            😔
                                        </button>
                                    </Tippy>
                                    <Tippy
                                        content={`Review ${capitalizeWords(
                                            identifier,
                                        )}`}
                                    >
                                        <button
                                            onClick={() =>
                                                handleFeedBackForm('comment')
                                            }
                                            className="p-1 rounded-full hover:bg-gray-300 transition-all cursor-pointer"
                                        >
                                            💬
                                        </button>
                                    </Tippy>
                                </div>
                            )}
                            {page === 2 && (
                                <div className="flex flex-col p-2 transition duration-300 ease-in-out transform absolute top-12 right-0 bg-white border rounded">
                                    <textarea
                                        value={comment}
                                        onChange={inputHandler}
                                        className="m-1 text-xs rounded-md border-gray-300 dark:border-primary  focus:border-primary focus:ring focus:ring-primary focus:ring-opacity-50 caret-gray-700 dark:bg-gray-800 dark:text-gray-50"
                                        rows={6}
                                        cols={50}
                                        maxLength={500}
                                    ></textarea>
                                    <div className="flex justify-end m-1 text-right">
                                        <button
                                            onClick={() => resetState()}
                                            className={`p-2 px-4 m-1 bg-white dark:bg-primary hover:bg-yellow-50 text-gray-600 dark:text-white rounded text-sm font-semibold border border-primary ring-primary hover:bg-secondary-50 disabled:opacity-50`}
                                        >
                                            <ArrowNarrowLeftIcon className="h-6 w-6 text-primary dark:text-white" />
                                        </button>
                                        {(comment && (comment.trim().length > 0)) ? (
                                            <button
                                                onClick={() =>
                                                    handleFeedback('comment')
                                                }
                                                className={`flex no-wrap items-center p-2 px-4 m-1 bg-primary text-white rounded text-sm font-semibold focus:outline-none focus:ring-primary focus:border-secondary-100 hover:bg-secondary-550`}
                                            >
                                                Send
                                                <CheckIcon className="ml-1 h-6 w-6 text-white" />
                                            </button>) : (<button
                                                className={`flex no-wrap items-center p-2 px-4 m-1 bg-primary text-white rounded text-sm font-semibold focus:outline-none focus:ring-primary focus:border-secondary-100 hover:bg-secondary-550 disabled:opacity-50 opacity-50 cursor-not-allowed`}
                                            >
                                                Send
                                                <CheckIcon className="ml-1 h-6 w-6 text-white" />

                                            </button>)}
                                    </div>
                                </div>
                            )}
                            {page === 3 && (
                                <FeedbackMessage onClose={resetState} show />
                            )}
                            {commentHandler && (
                                <div className="flex no-wrap items-center p-2 px-4 m-1 bg-primary text-white rounded text-xs font-semibold">
                                    <p> Processing </p>
                                    <RefreshIcon className="animate-spin ml-1 h-6 w-6 text-white" />
                                </div>
                            )}
                        </Transition.Child>
                    </Transition>
                </>
            )}

            {page === 1 && (
                <Tippy content={`${show ? 'Close Feedback' : 'Page Feedback'}`} >
                    <button
                        className="flex items-center justify-center align-middle transition-all transform hover:scale-105 outline-none"
                        onClick={() => widgetHandler()}
                    >
                        {show ? (
                            <p className="text-xs hover:bg-gray-300 transition-all p-1 rounded-full">
                                ❌
                            </p>
                        ) : (
                            <>
                                <ThumbUpIcon className='w-4 h-4' />
                                <ThumbDownIcon className='w-4 h-4 -ml-0' />
                                {/* <p>👍</p>
                                <p className="-ml-2">👎</p> */}
                            </>
                        )}
                    </button>
                </Tippy>
            )}
        </div>
    )
}
