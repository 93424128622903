export type searchKeywordType = {
    displayText: string
    searchText: string
}

export const getInitials = (fullName: string): string => {
    if (!fullName) {
        return ''
    }
    const namesArray = fullName.trim().split(' ')
    if (namesArray.length === 1) return `${namesArray[0].charAt(0)}`
    else
        return `${namesArray[0].charAt(0)}${namesArray[
            namesArray.length - 1
        ].charAt(0)}`
}

export const getInitialsFromEmail = (fullName: string): string => {
    if (!fullName) {
        return ''
    }
    const namesArray = fullName
        .trim()
        .substring(0, fullName.indexOf('@'))
        .split('.')
   // console.log(namesArray)
    if (namesArray.length === 1) return `${namesArray[0].charAt(0)}`
    else
        return `${namesArray[0].charAt(0)}${namesArray[
            namesArray.length - 1
        ].charAt(0)}`
}

export const diffCalc = (s1: string, s2: string) => {
    let counter = 0
    let lcs = 0
    while (counter < s1.length && counter < s2.length) {
        if (s1.charAt(counter) == s2.charAt(counter)) {
            lcs++
        } else {
            break
        }
        counter++
    }
    return lcs
}

export const closest = (
    string: string,
    matches: searchKeywordType[],
): number => {
    const measurementDictionary = []
    if (!string || !matches) return -2
    let matchIndex = -1
    if (matches.length) {
        for (let i = 0; i < matches.length; i++) {
            const candidate = matches[i].displayText
            if (string.toLowerCase() === candidate.toLowerCase()) return -2
            if (candidate.toLowerCase().includes(string.toLowerCase()))
                return -2
            const measurement: number = diffCalc(
                string.toLowerCase(),
                candidate.toLowerCase(),
            )
            const percentageMatch =
                string.length <= candidate.length
                    ? Number((measurement / string.length) * 100)
                    : Number((measurement / candidate.length) * 100)
            measurementDictionary.push(percentageMatch)
            if (
                (percentageMatch > 50 && matchIndex === -1) ||
                (matchIndex >= 0 &&
                    measurementDictionary[matchIndex] < percentageMatch)
            ) {
                matchIndex = i
            }
        }
    }
    //console.log('Match Percentage : ', measurementDictionary)
    if (matchIndex === -1) {
        return -1
    } else if (
        matchIndex > -1 &&
        matches[matchIndex].displayText.length < string.length
    ) {
        return matchIndex
    } else {
        return -2
    }
}

export const convertUnicode = (input: string): string => {
    return input.replace(/\\u[\dA-F]{4}/gi, function (match) {
        return String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16))
    })
}

export const splitWordsFromText = (
    text: string,
    numberOfwords: number,
): string => {
    if (!text) return ''
    else {
        const textArray = text.split(' ')
        if (textArray.length > numberOfwords) {
            return textArray.slice(0, numberOfwords).join(' ')
        } else {
            return text
        }
    }
}

export const numberToKAndMFormat = (num: number): string | number => {
    if (num > 999 && num < 1000000) {
        return (num / 1000).toFixed(0) + 'K' // convert to K for number from > 1000 < 1 million
    } else if (num > 1000000) {
        return (num / 1000000).toFixed(0) + 'M' // convert to M for number from > 1 million
    }
    return num // if value < 1000, nothing to do
}

export const validateEmail = (email: string) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        )
}

export const uuidv4 = (): string => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
        /[xy]/g,
        function (c) {
            const r = (Math.random() * 16) | 0
            const v = c == 'x' ? r : (r & 0x3) | 0x8
            return v.toString(16)
        },
    )
}

export const capitalizeWords = (str: string): string => {
    return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    })
}

export const stripHtmlTags = (str: string) => {
    return str.replace(/(<([^>]+)>)/gi, '')
}

export const highlightText = (str: string, word: string) => {
    const regex = new RegExp(`(${word})`, 'gi')
    const parts = str.split(regex)
    const highlighted = parts.map(part => {
        if (regex.test(part)) {
            return <span className="font-bold">{part}</span>
        }
        return part
    })
    return highlighted
}
