import React from 'react'
import { IntermixLinkProps, ISideNavMenuOptions } from './types'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import Portal from '@/hoc/Portal'
import useAnalyticsEventTracker from '@/hooks/useAnalyticsEventTracker'
import { GAAction, GAEventCategory } from '@/types/GAnalyticsTypes'
import { useAppLinkOpener } from '@/hooks/useAppLinkOpener'
import { SearchCategory } from '../blocks/SearchInput/SearchCategory'

type VisiableNavListProps = {
    onToggleSideNav: () => void
    displayLinks: IntermixLinkProps[]
    SideNavComponent: React.ComponentType<ISideNavMenuOptions>
    linkUri: string
    droppableId: string
}
const PORTAL_LINK_TITLE = 'Konnect Portal';
export const VisiableNavList: React.FC<VisiableNavListProps> = ({
    displayLinks,
    SideNavComponent,
    linkUri,
    droppableId,
    onToggleSideNav,
}: VisiableNavListProps) => {
    const gaEventTracker = useAnalyticsEventTracker(GAEventCategory.LEFT_MENU_APP_CLICK);
    const { recordUseHistory } = useAppLinkOpener()
    return (
        <>
            {displayLinks
                .filter(i => i.title.toLowerCase() === PORTAL_LINK_TITLE.toLowerCase())
                .map(
                    (
                        {
                            id,
                            title,
                            path,
                            isAction,
                            icon,
                            appName,
                            iframe,
                            iframeURL,
                            pinned,
                            isExternalApp,
                            openInNewTab
                        }: IntermixLinkProps,
                        index,
                    ) => {
                        const finalPath =
                            iframe && iframeURL && appName
                                ? path + '?app=' + appName
                                : path

                        return (
                            <SideNavComponent
                                title={title}
                                path={finalPath}
                                key={title}
                                active={decodeURI(
                                    linkUri.toLowerCase(),
                                ).startsWith(finalPath.toLowerCase())}
                                icon={icon}
                                isExternalApp={isExternalApp}
                                openInNewTab={openInNewTab}
                                pinned={pinned}
                                 resourceId={id.toString()}
                                 name={appName}
                                onClick={() => {
                                    recordUseHistory({ id, name: appName, url: path, category: SearchCategory.LEFT_MENU_APP })
                                    gaEventTracker(GAAction.OPEN_APP, title)
                                    onToggleSideNav()
                                }}
                            />
                        )
                    },
                )}
            <Droppable droppableId={droppableId}>
                {provided => (
                    <div {...provided.droppableProps} ref={provided.innerRef} style={{ minHeight: "2px" }}>
                        {displayLinks
                            .filter(i => i.title.toLowerCase() !== PORTAL_LINK_TITLE.toLowerCase())
                            .map(
                                (
                                    {
                                        title,
                                        id,
                                        path,
                                        icon,
                                        appName,
                                        iframe,
                                        iframeURL,
                                        isExternalApp,
                                        openInNewTab,
                                        pinned,
                                    }: IntermixLinkProps,
                                    index,
                                ) => {
                                    const finalPath =
                                        iframe && iframeURL && appName
                                            ? path + '?app=' + appName
                                            : path

                                    return (
                                        <Draggable
                                            key={title}
                                            draggableId={title}
                                            index={index}
                                        >
                                            {(provided, snapshot) => {
                                                const { style, ...rest } =
                                                    provided.draggableProps
                                                const dragStyle = {
                                                    ...style,
                                                    background: '#323335',
                                                    zIndex: 99999,
                                                }
                                                const row = (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...rest}
                                                        {...provided.dragHandleProps}
                                                        style={dragStyle}
                                                    >
                                                        <SideNavComponent
                                                            title={title}

                                                            path={finalPath}
                                                            active={decodeURI(
                                                                linkUri.toLowerCase(),
                                                            ).startsWith(
                                                                finalPath.toLowerCase(),
                                                            )}
                                                            icon={icon}
                                                            isExternalApp={isExternalApp}
                                                            openInNewTab={openInNewTab}
                                                            pinned={pinned}
                                                            resourceId={id.toString()}
                                                            name={appName}
                                                            onClick={() => {
                                                                recordUseHistory({ id, name: appName, url: path, openInNewTab, category: SearchCategory.LEFT_MENU_APP })
                                                                gaEventTracker(GAAction.OPEN_APP, title)
                                                                onToggleSideNav()

                                                            }}
                                                        />
                                                    </div>
                                                )

                                                if (snapshot.isDragging) {
                                                    return (
                                                        <Portal>{row}</Portal>
                                                    )
                                                } else {
                                                    return row
                                                }
                                            }}
                                        </Draggable>
                                    )
                                },
                            )}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </>
    )
}
