import { DashboardLayouts } from '@intermix-dashboard/core'
import create from 'zustand'
import { persist } from 'zustand/middleware'
import { devtools } from 'zustand/middleware'
import { Notification, Feedback } from '@/services/UOPService'
import defaultDashJSON from './data/default.json'
import { closest } from '../utilities/contents'
import { searchKeywordType } from '../utilities/contents'

export type Store = {
    infoMode: boolean
    intermixEditMode: boolean
    activeNavLink: string
    notifications: Notification[]
    feedbackList: Feedback[]
    setInfoMode: (infoMode: boolean) => void
    setIntermixEditMode: (value: boolean) => void
    setNotifications: (notifications: Notification[]) => void
    setFeedbackList: (feedback: Feedback[]) => void
    addNotification: (notification: Notification) => void
    removeNotification: (id: string) => void
    markReadNotification: (id: string) => void
    markUnReadNotification: (id: string) => void
    getUnreadNotifications: () => Notification[]
    setActiveNavLink: (value: string) => void
}

/**
 * Profile store
 * This store is responsible for managing the profile search results
 * It will set the profile selected post search and this context can then be used where applicable
 * For eg: the InnovatorProfile Iframe component will use this store to get the selected profile
 */
export type ProfileStore = {
    selectedInnovatorProfileEmail: string // Selected Profile Email
    restoreDefaults: () => void // Defaults to "me"
    showMessages: boolean // Show Message Chat Widget
    setSelectedInnovatorProfileEmail: (value: string) => void
    toggleShowMessages: () => void
}

export type SearchKeywordStore = {
    searchKeywords: searchKeywordType[]
    addKeyword: (keyword: string, searchText: string) => void
}

export type IntermixStore = {
    layout: DashboardLayouts | undefined
    resetLayout: () => void
    setLayout: (layout: DashboardLayouts) => void
}

const initialSearchKeywords: any[] = []

const initialNotifications: Notification[] = []

export interface ApplicationTileProps {
    imgSrc: string
    imgAlt: string
    redirectTo: string
}

export type ApplicationsStore = {
    applications: ApplicationTileProps[]
    setApplications: (applications: ApplicationTileProps[]) => void
    getURLDetailsByApplicationName: (
        appName: string,
    ) => { url: string; title: string } | null
}

export interface MessageOfTheDayProps {
    url: string
    alt: string
    src: string
    tracker: string
}

export type MessageOfTheDayStore = {
    messages: MessageOfTheDayProps[]
    setMessagesOfTheDay: (messages: MessageOfTheDayProps[]) => void
    getMessageOfTheDay: () => MessageOfTheDayProps
}

export type NavHistoryStore = {
    forwardCount: number
    backwardCount: number,
    setForwardCount: (n: number) => void,
    setBackwardCount: (n: number) => void,
    updateForwardState: () => void,
    updateBackwardState: () => void,
    updatePushState: () => void,
}

export type NPSFilters = {
    fromDate: string,
    toDate: string,
    nameEmail: string,
    comment: string,
}

export type NPSFiltersStore = {
    filters: NPSFilters,
    updateFilter: (filters: NPSFilters) => void
}

const initialMessagesOfTheDay: MessageOfTheDayProps[] = [
    {
        url: 'https://anheuserbuschinbev.sharepoint.com/sites/ASK/',
        alt: 'ASK Portal',
        src: '/images/message-of-day/ask-1.png',
        tracker: 'ask-1',
    },
    {
        url: 'https://anheuserbuschinbev.sharepoint.com/sites/ASK/',
        alt: 'ASK Portal',
        src: '/images/message-of-day/ask-2.png',
        tracker: 'ask-2',
    },
    {
        url: 'https://anheuserbuschinbev.sharepoint.com/sites/ASK/',
        alt: 'ASK Portal',
        src: '/images/message-of-day/ask-3.png',
        tracker: 'ask-3',
    },
    {
        url: 'https://anheuserbuschinbev.sharepoint.com/sites/ASK/',
        alt: 'ASK Portal',
        src: '/images/message-of-day/ask-4.png',
        tracker: 'ask-4',
    },
]

const initialApplications: ApplicationTileProps[] = [
    {
        imgSrc: '/icons/sharepoint/IconSafetyTracker.png',
        imgAlt: 'Safety & Security Tracker',
        redirectTo:
            'https://anheuserbuschinbev.sharepoint.com/teams/Global-Supply/SSC/GlobalITD/Communities/GITec Facility/SitePages/SafetyTracker.aspx',
    },
    {
        imgSrc: '/icons/sharepoint/IconPurchaseRequest.png',
        imgAlt: 'Purchase Request',
        redirectTo:
            'https://anheuserbuschinbev.sharepoint.com/teams/Global-Procurement/GPSS/GHQ_Portal/SitePages/Home-Page.aspx',
    },
    {
        imgSrc: '/icons/sharepoint/IconPackagingPallet.png',
        imgAlt: 'Logistics Request',
        redirectTo:
            'https://anheuserbuschinbev.sharepoint.com/teams/Global-Supply/SSC/GlobalITD/LogisticRequest/SitePages/LogisticRequest.aspx',
    },
    {
        imgSrc: '/icons/sharepoint/IconTargets.png',
        imgAlt: 'Targets',
        redirectTo:
            'https://anheuserbuschinbev.sharepoint.com/teams/Global-Supply/SSC/GlobalITD/Management Pillar/11Target setting and cascading/Forms/AllItems.aspx',
    },
    {
        imgSrc: '/icons/sharepoint/IconTeam.png',
        imgAlt: 'Team Spaces',
        redirectTo:
            'https://anheuserbuschinbev.sharepoint.com/teams/Global-Supply/SSC/GlobalITD/SitePages/Team.aspx',
    },
    {
        imgSrc: '/icons/sharepoint/BadgeRequest.png',
        imgAlt: 'Badge Request',
        redirectTo:
            'https://anheuserbuschinbev.sharepoint.com/sites/GITECFacility/SitePages/BadgeRequest.aspx',
    },
    {
        imgSrc: '/icons/sharepoint/IconMCRS.png',
        imgAlt: 'MCRS',
        redirectTo:
            'https://anheuserbuschinbev.sharepoint.com/teams/Global-Supply/SSC/GlobalITD/Management Pillar/07Routine Review Process/Forms/AllItems.aspx',
    },
    {
        imgSrc: '/icons/sharepoint//budgettingIcon.png',
        imgAlt: 'Budgetting Tool',
        redirectTo:
            'https://anheuserbuschinbev.sharepoint.com/teams/Global-Supply/SSC/GlobalITD/PPMBGTOOL/SitePages/Home.aspx',
    },
    {
        imgSrc: '/icons/sharepoint//Procurement_icon.png',
        imgAlt: 'GITEC Procurement',
        redirectTo:
            'https://anheuserbuschinbev.sharepoint.com/teams/Global-Supply/SSC/GlobalITD/GITECProcurement/SitePages/Procurement GITEC.aspx',
    },
    {
        imgSrc: '/icons/sharepoint/LogoBestPractices.png',
        imgAlt: 'Best Practice Portal',
        redirectTo:
            'https://anheuserbuschinbev.sharepoint.com/teams/Global-Supply/SSC/GlobalITD/BestPractice/BestPracticeWikiPageLibrary/Best Practice Home.aspx',
    },
    {
        imgSrc: '/icons/sharepoint//Icon_ITSupport.png',
        imgAlt: 'IT Reporting',
        redirectTo:
            'https://anheuserbuschinbev.sharepoint.com/teams/Global-Supply/SSC/GlobalITD/SitePages/ITReporting.aspx',
    },
    {
        imgSrc: '/icons/sharepoint/IconBudget.png',
        imgAlt: 'Budget',
        redirectTo:
            'https://anheuserbuschinbev.sharepoint.com/teams/Global-Supply/SSC/GlobalITD/Management Pillar/14CapexOpexbudget/Forms/AllItems.aspx',
    },
    {
        imgSrc: '/icons/sharepoint/IconPMO.png',
        imgAlt: 'PMO Libraries',
        redirectTo:
            'https://anheuserbuschinbev.sharepoint.com/teams/Global-Supply/SSC/GlobalITD/Projects/SitePages/Home.aspx ',
    },
    {
        imgSrc: '/icons/sharepoint/IconFacilityManagement.png',
        imgAlt: 'Facility',
        redirectTo:
            'https://anheuserbuschinbev.sharepoint.com/teams/Global-Supply/SSC/GlobalITD/Communities/GITec Facility/SitePages/FacilityHome.aspx',
    },
    {
        imgSrc: '/icons/sharepoint//icon_IntellectualProperty.png',
        imgAlt: 'IP Management',
        redirectTo:
            'https://anheuserbuschinbev.sharepoint.com/teams/Global-Supply/SSC/GlobalITD/SitePages/IP Management.aspx',
    },
    {
        imgSrc: '/icons/sharepoint//shop-512.png',
        imgAlt: 'Tech Scout',
        redirectTo:
            'https://anheuserbuschinbev.sharepoint.com/teams/Global-Supply/SSC/GlobalITD/SitePages/Tech Scout.aspx',
    },
    {
        imgSrc: '/icons/sharepoint/IconSuep.png',
        imgAlt: 'I&T Pillar',
        redirectTo:
            'https://anheuserbuschinbev.sharepoint.com/teams/Global-Supply/SSC/GlobalITD/TD Pillar/SitePages/Home.aspx',
    },
    {
        imgSrc: '/icons/sharepoint/IconKnowledgeCenters.png',
        imgAlt: 'Knowledge Centers',
        redirectTo:
            'https://anheuserbuschinbev.sharepoint.com/teams/Global-Supply/SSC/GlobalITD/Knowledge Center/SitePages/KnowledgeCentersNew.aspx',
    },
    {
        imgSrc: '/icons/sharepoint/IconIPSupport.png',
        imgAlt: 'Toolkits',
        redirectTo:
            'https://anheuserbuschinbev.sharepoint.com/teams/Global-Supply/SSC/GlobalITD/IandTPillar/SitePages/Toolkits.aspx',
    },
    {
        imgSrc: '/icons/sharepoint/IconPeople.png',
        imgAlt: 'People',
        redirectTo:
            'https://anheuserbuschinbev.sharepoint.com/teams/Global-Supply/SSC/GlobalITD/People Pillar/SitePages/People.aspx',
    },
    {
        imgSrc: '/icons/sharepoint/IconManagementPillar.png',
        imgAlt: 'Management Pillar',
        redirectTo:
            'https://anheuserbuschinbev.sharepoint.com/teams/Global-Supply/SSC/GlobalITD/Management Pillar/SitePages/Home.aspx',
    },
    {
        imgSrc: '/icons/sharepoint/IconUsefullLinks.png',
        imgAlt: 'Useful Links',
        redirectTo:
            'https://anheuserbuschinbev.sharepoint.com/teams/Global-Supply/SSC/GlobalITD/SitePages/UsefulLinks.aspx',
    },
    {
        imgSrc: '/icons/sharepoint//risk-management-icon.png',
        imgAlt: 'Policies',
        redirectTo:
            'https://anheuserbuschinbev.sharepoint.com/teams/Global-Supply/SSC/GlobalITD/People Pillar/SitePages/Policies.aspx',
    },
    {
        imgSrc: '/icons/sharepoint/IconDashboard.png',
        imgAlt: 'My Dashboard',
        redirectTo:
            'https://anheuserbuschinbev.sharepoint.com/teams/Global-Supply/SSC/GlobalITD/MyDashBoard',
    },
]

export type ApplicationMessage = {
    id: string
    messageType: "success" | "error" | "info",
    message: string | undefined
}
export type ApplicationShellStore = {
    messages: ApplicationMessage[]
    addMessage: (message: ApplicationMessage) => void
    setMessages: (messages: ApplicationMessage[]) => void
    removeMessage: (id: string) => void
}

export const useApplicationShellStore = create<ApplicationShellStore>(
    devtools((set, get) => ({
        messages: [],
        setMessages: (messages: ApplicationMessage[]) => {
            set(() => ({
                messages,
            }))
        },
        addMessage: (message: ApplicationMessage) => {
            set((state) => ({
                messages: [message, ...state.messages]
            }))
        },
        removeMessage: (id: string) => {
            set((state) => ({
                messages: [...state.messages.filter(item => item.id !== id)]
            }))
        }
    })),
)

// Application User Configurable Settings
export type ApplicationPreferencesStore = {
    showTourOnStartup: boolean
    themeSelection: boolean // if true then dark and light mode is enabled, else only light mode is enabled
    pageFeedbackExpanded: boolean // if true then page feedback widget is expanded by default
    currentAppViewed: string | undefined // last app viewed by user
    setShowTourOnStartup: (showTourOnStartup: boolean) => void
    setThemeSelection: (themeSelection: boolean) => void
    setPageFeedbackExpanded: (pageFeedbackExpanded: boolean) => void
    setCurrentAppViewed: (currentAppViewed: string | undefined) => void
}

export const useApplicationPreferencesStore =
    create<ApplicationPreferencesStore>(
        devtools(
            persist(
                set => ({
                    themeSelection: false,
                    pageFeedbackExpanded: false,
                    currentAppViewed: undefined,
                    showTourOnStartup: true,
                    setShowTourOnStartup: (showTourOnStartup: boolean) => {
                        set(() => ({
                            showTourOnStartup,
                        }))
                    },
                    setThemeSelection: (themeSelection: boolean) => {
                        set(() => ({
                            themeSelection,
                        }))
                    },
                    setPageFeedbackExpanded: (
                        pageFeedbackExpanded: boolean,
                    ) => {
                        set(() => ({
                            pageFeedbackExpanded,
                        }))
                    },
                    setCurrentAppViewed: (
                        currentAppViewed: string | undefined,
                    ) => {
                        set(() => ({
                            currentAppViewed,
                        }))
                    },
                }),
                {
                    name: 'portal-preferences',
                },
            ),
        ),
    )
export const useMessageOfTheDayStore = create<MessageOfTheDayStore>(
    devtools((set, get) => ({
        messages: initialMessagesOfTheDay,
        setMessagesOfTheDay: (messages: MessageOfTheDayProps[]) => {
            set(() => ({
                messages,
            }))
        },
        getMessageOfTheDay: () => {
            const mods = get().messages
            return mods[Math.floor(Math.random() * mods.length)]
        },
    })),
)

export const useApplicationsStore = create<ApplicationsStore>(
    devtools((set, get) => ({
        applications: initialApplications,
        setApplications: (applications: ApplicationTileProps[]) => {
            set(() => ({
                applications,
            }))
        },
        getURLDetailsByApplicationName: (appName: string) => {
            const application = get().applications.find(
                app => app.imgAlt === appName,
            )
            if (application) {
                return {
                    title: application.imgAlt,
                    url: application.redirectTo || '',
                }
            }
            return null
        },
    })),
)

export const useProfileStore = create<ProfileStore>(
    devtools(set => ({
        selectedInnovatorProfileEmail: 'me',
        showMessages: false,
        restoreDefaults: () =>
            set(state => ({
                ...state,
                selectedInnovatorProfileEmail: 'me',
                showMessages: false,
            })),
        setSelectedInnovatorProfileEmail: (value: string) =>
            set(state => ({
                ...state,
                selectedInnovatorProfileEmail: value,
            })),
        toggleShowMessages: () =>
            set(state => ({
                ...state,
                showMessages: !state.showMessages,
            })),
    })),
)

export const useIntermixStore = create<IntermixStore>(
    devtools(
        persist(
            set => ({
                layout: defaultDashJSON,
                resetLayout: () =>
                    set(state => ({ ...state, layout: defaultDashJSON })),
                setLayout: (layout: DashboardLayouts) => {
                    set({ layout })
                },
            }),
            {
                name: 'konnect-intermix',
            },
        ),
    ),
)

export const useSearchKeywordsStore = create<SearchKeywordStore>(
    devtools(
        persist(
            (set, get) => ({
                searchKeywords: initialSearchKeywords,
                addKeyword: (keyword: string, searchText: string) => {
                    // new code
                    const index = closest(keyword, get().searchKeywords)
                    if (index === -1) {
                        //set data no closest match found
                        set(state => ({
                            searchKeywords: [
                                {
                                    displayText: keyword,
                                    searchText: searchText,
                                },
                                ...state.searchKeywords.slice(0, 4),
                            ],
                        }))
                    } else if (index > -1) {
                        //overwrite the matched one with new text on top
                        const edited = get().searchKeywords
                        edited.splice(index, 1)
                        set(state => ({
                            searchKeywords: [
                                {
                                    displayText: keyword,
                                    searchText: searchText,
                                },
                                ...edited,
                            ],
                        }))
                    } else {
                        // do nothing if same string exists
                    }
                    //new code
                },
            }),
            {
                name: 'konnect-searchKeywords-v2',
            },
        ),
    ),
)

export const useStore = create<Store>(
    devtools((set, get) => ({
        intermixEditMode: false,
        activeNavLink: "home",
        notifications: initialNotifications,
        feedbackList: [],
        infoMode: false,
        setInfoMode: (infoMode: boolean) => {
            set(() => ({
                infoMode,
            }))
        },
        setFeedbackList: (feedback: Feedback[]) => {
            set({
                feedbackList: feedback,
            })
        },
        setNotifications: (notifications: Notification[]) => {
            set({ notifications })
        },
        addNotification(notification: Notification) {
            set(state => ({
                ...state,
                notifications: [
                    ...state.notifications,
                    {
                        ...notification,
                        read: false,
                        eventTime: new Date().toString(),
                    },
                ],
            }))
        },
        setIntermixEditMode(value: boolean) {
            set(state => ({
                ...state,
                intermixEditMode: value,
            }))
        },
        setActiveNavLink(value: string) {
            set(state => ({
                ...state,
                activeNavLink: value,
            }))
        },
        removeNotification(id: string) {
            set(state => ({
                ...state,
                notifications: state.notifications.filter(n => n.id === id),
            }))
        },
        markReadNotification(id: string) {
            set(state => ({
                ...state,
                notifications: updateNotificationAsRead(
                    state.notifications || ([] as Notification[]),
                    id,
                ),
            }))
        },
        markUnReadNotification(id: string) {
            set(state => ({
                ...state,
                notifications: updateNotificationAsUnRead(
                    state.notifications || ([] as Notification[]),
                    id,
                ),
            }))
        },
        getUnreadNotifications() {
            const notifications = get().notifications
            return notifications && notifications.length > 0
                ? notifications.filter(n => n.read === false)
                : []
        },
    })),
)

function updateNotificationAsRead(
    notifications: Notification[],
    id: string,
): Notification[] {
    return notifications.map(n => {
        if (n.id === id) {
            n.read = true
        }
        return n
    })
}

function updateNotificationAsUnRead(
    notifications: Notification[],
    id: string,
): Notification[] {
    return notifications.map(n => {
        if (n.id === id) {
            n.read = false
        }
        return n
    })
}

export const useNavHistory = create<NavHistoryStore>(devtools((set, get) => ({
    forwardCount: 0,
    backwardCount: 0,
    setBackwardCount: (n) => { set({ backwardCount: n }) },
    setForwardCount: (n) => { set({ forwardCount: n }) },
    updatePushState: () => {
        const back = get().backwardCount + 1;
        set({ backwardCount: back, forwardCount: 0 });
    },
    updateForwardState: () => {
        const back = get().backwardCount + 1;
        const forward = get().forwardCount > 0 ? get().forwardCount - 1 : 0;
        set({ backwardCount: back, forwardCount: forward });
    },
    updateBackwardState: () => {
        const back = get().backwardCount > 0 ? get().backwardCount - 1 : 0;
        const forward = get().forwardCount + 1;
        set({ backwardCount: back, forwardCount: forward });
    }
}))
)

export const useNpsFilter = create<NPSFiltersStore>(devtools((set, get) => ({
    filters: {
        fromDate: '',
        toDate: '',
        nameEmail: '',
        comment: '',
    },
    updateFilter: (filters: NPSFilters) => {
        set({ filters });
    }
}))
)

export type CalendarTaskUpdateStore = {
    newEventFlag: boolean,
    setNewEventFlag:(n:boolean)=>void,
    newTaskFlag: boolean,
    setNewTaskFlag:(n:boolean)=>void
    newMailTaskFlag: boolean,
    setNewMailTaskFlag:(n:boolean)=>void
}

export const useCalendarTaskUpdate = create<CalendarTaskUpdateStore>(devtools((set, get) => (
    {
        newEventFlag: false,
        setNewEventFlag: (n) => { set({ newEventFlag: n }) },
        newTaskFlag: false,
        setNewTaskFlag: (n) => { set({ newTaskFlag: n }) },
        newMailTaskFlag: false,
        setNewMailTaskFlag: (n) => { set({ newMailTaskFlag: n }) },
    }
))
)

export type ResourceHistoryStore = {
    appList: any[],
    resourceList: any[],
    searchHistory: any[],
    setApplicationList: (list: any[]) => void,
    setResourceList: (list: any[]) => void,
    setSearchHistory: (list: any[]) => void
}

export const useResourceHistory = create<ResourceHistoryStore>(devtools((set, get) => ({
    appList: [],
    resourceList: [],
    searchHistory: [],
    setApplicationList: (appList: any[]) => {
        set({ appList });
    },
    setResourceList: (resourceList: any[]) => {
        set({ resourceList });
    },
    setSearchHistory: (searchHistory: any[])=>{
        set({ searchHistory });
    }
}))
)