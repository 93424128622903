import * as React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useIsAuthenticated } from '@azure/msal-react'
import { useAppConfig } from '@/hooks/useAppConfig'
import { clearAuthenticatedUserfromLS } from '@/utilities/localstorage'

export const PrivateRoute = React.memo(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ({ component: Component, roles, ...rest }: any) => {
        const { byPassAuth } = useAppConfig()
        const isAuthenticated = byPassAuth || useIsAuthenticated()
        return (
            <Route
                {...rest}
                component={React.memo(props => {
                    if (!isAuthenticated) {
                        clearAuthenticatedUserfromLS()
                        return (
                            <Redirect
                                to={{
                                    pathname: '/login',
                                    state: { from: props.location },
                                }}
                            />
                        )
                    }
                    if (
                        isAuthenticated &&
                        props.location.pathname === '/login'
                    ) {
                        return <Redirect to={{ pathname: '/' }} />
                    }

                    // authorised so return component
                    return <Component {...props} />
                })}
            />
        )
    },
)
