import { useEffect } from 'react'

export const useOutsideComponent = (ref: any, cb: () => void) => {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event: MouseEvent) {                   
            if (ref.current && !ref.current.contains(event.target) ) {
                cb()
            }
        }  
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [ref])
}
