export const randomColor = (): string => {
    let color = '#'
    for (let i = 0; i < 6; i++) {
        const random = Math.random()
        const bit = (random * 16) | 0
        color += bit.toString(16)
    }
    return color
}

export const randomPastelColor = (): string => {
    const hue = Math.floor(190 * Math.random()) + 0
    const sat = 100
    const light = Math.floor(5 * Math.random()) + 90

    return `hsl(${hue}, ${sat}%, ${light}%)`
}

export const textColorForPastelColor = (hsl: string): string => {
    if (hsl) {
        const [hue, sat, light] = getHSLElementsFromString(hsl)
        return `hsl(${hue}, ${sat}%, ${light - 60}%)`
    } else {
        return 'black'
    }
}

const getHSLElementsFromString = (hsl: string): number[] => {
    const HSLVALUESSTART = 4
    const HSLVALUESEND = 1
    const hslValues: string = hsl.substr(
        HSLVALUESSTART,
        hsl.length - (HSLVALUESSTART + HSLVALUESEND),
    )
    const hue = Number(hslValues.split(',')[0])
    const sat = Number(hslValues.split(',')[1].replace('%', ''))
    const light = Number(hslValues.split(',')[2].replace('%', ''))
    return [hue, sat, light]
}

export const convertHSLToRGB = (hsl: string): string => {
    let [hue, sat, light] = getHSLElementsFromString(hsl)
    sat = sat / 100
    light = light / 100

    let t2: number
    hue = hue / 60
    if (light <= 0.5) {
        t2 = light * (sat + 1)
    } else {
        t2 = light + sat - light * sat
    }
    const t1 = light * 2 - t2
    const r = (hueToRgb(t1, t2, hue + 2) * 255).toFixed(0)
    const g = (hueToRgb(t1, t2, hue) * 255).toFixed(0)
    const b = (hueToRgb(t1, t2, hue - 2) * 255).toFixed(0)
    return `rgb(${r},${g},${b})`
}

const hueToRgb = (t1: number, t2: number, hue: number) => {
    if (hue < 0) hue += 6
    if (hue >= 6) hue -= 6
    if (hue < 1) return (t2 - t1) * hue + t1
    else if (hue < 3) return t2
    else if (hue < 4) return (t2 - t1) * (4 - hue) + t1
    else return t1
}
