import { useApplicationPreferencesStore } from '@/store/store'
import { XIcon } from '@heroicons/react/solid'
import { useCallback, useEffect } from 'react'
export const TourToolTip = ({
    continuous,
    index,
    step,
    backProps,
    isLastStep,
    closeProps,
    primaryProps,
    tooltipProps,
}: {
    continuous: boolean
    index: number
    isLastStep: boolean
    step: any
    backProps: any
    closeProps: any
    primaryProps: any
    tooltipProps: any
}) => {
    const showTourOnStartup = useApplicationPreferencesStore(
        state => state.showTourOnStartup,
    )
    const setShowTourOnStartup = useApplicationPreferencesStore(
        useCallback(state => state.setShowTourOnStartup, []),
    )
    console.log("debug", step.title, step.content)

    return (

        <div className="flex flex-col bg-white text-gray-700 rounded relative">
            {index === 0 && (
                <div className="flex flex-col w-full">
                    <div className="flex-1 font-bold text-center m-2">
                        Welcome to the Konnect Portal !!
                    </div>
                    <small className="flex justify-end text-xs mx-2 align-middle text-center">
                        Press 'Esc' or 'X' to exit anytime
                    </small>
                </div>
            )}
            <div
                {...tooltipProps}
                className="bg-white text-gray-700 rounded p-2 flex w-full flex-col  align-middle items-center"
            >
                {step.title && (
                    <div className="font-bold text-primary text-center min-w-full text-md">
                        {step.title}
                    </div>
                )}
                <ul className=" list-inside list-disc w-full m-1 text-sm border-b pb-2">

                    {step.content.map((item: any, index: number) => {
                        return (
                            (typeof item === 'string') ? <li key={index}>{item}</li> : (
                            <li key={index}>{item.text}
                                {item.subText.length > 0 && <ol className=' list-inside  pl-10' style={{listStyleType: 'circle'}}>{
                                    item.subText.map((text: string, i: number) => (<li style={{}} key={i}>{text}</li>))}</ol>}
                            </li>)


                        )
                    })}
                </ul>
                <div className="flex w-full align-middle items-center">
                    <small className="text-xs flex-wrap w-44">
                        Press{' '}
                        <b>
                            <kbd>Enter</kbd>
                        </b>{' '}
                        or use Navigation Buttons to proceed
                    </small>
                    <div className="flex-1 flex w-full justify-end align-middle items-center space-x-1 mt-1 ">
                        {index > 0 && (
                            <button
                                {...backProps}
                                className={`p-1 bg-gray-700 text-white rounded text-sm font-semibold focus:outline-none focus:ring-gray-700 focus:border-gray-200 hover:bg-gray-800 disabled:opacity-50`}
                            >
                                Back
                            </button>
                        )}
                        {continuous && (
                            <button
                                {...primaryProps}
                                className={`p-1  bg-primary text-white rounded text-sm font-semibold focus:outline-none focus:ring-primary focus:border-secondary-100 hover:bg-secondary-570 disabled:opacity-50`}
                            >
                                {isLastStep ? 'Finish' : 'Next'}
                            </button>
                        )}
                        {
                            <button
                                {...closeProps}
                                className="w-4 h-4 absolute top-1 right-1 hover:text-primary"
                            >
                                <XIcon />
                            </button>
                        }
                    </div>
                </div>
                {index === 0 && (
                    <label className="flex items-center text-sm justify-end text-right w-full">
                        <input
                            type={'checkbox'}
                            checked={showTourOnStartup}
                            className="focus:ring-primary h-4 w-4 text-primary border-gray-300 rounded"
                            onChange={e => {
                                setShowTourOnStartup(
                                    e.target.checked ? true : false,
                                )
                            }}
                        />
                        <span className="text-gray-700 mt-2 ml-2">
                            Show Tour On Startup
                        </span>
                    </label>
                )}
            </div>
        </div>
    )
}
