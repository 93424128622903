import * as React from 'react'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import {
    useIsAuthenticated,
    UnauthenticatedTemplate,
    useMsal,
} from '@azure/msal-react'
import ABILogo from '../assets/ABI_Logo_FullColor-Black.svg'
import { useAppContext } from '../AppContext'
import { InteractionStatus } from '@azure/msal-browser'

const LoginPage: React.FC<RouteComponentProps> = props => {
    const isAuthenticated = useIsAuthenticated()
    const { inProgress } = useMsal()
    const app = useAppContext()

    React.useEffect(() => {
        if (
            inProgress === InteractionStatus.None &&
            !isAuthenticated &&
            app.signIn
        ) {
            app.signIn()
        }
    }, [inProgress, isAuthenticated])
    if (isAuthenticated) {
        return (
            <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )
    }

    return (
        <div className="flex items-center justify-center align-top flex-1 h-screen bg-auth bg-center bg-cover transition duration-500">
            <div className="bg-white dark:bg-black text-gray-700 font-bold rounded-lg border shadow-lg flex flex-col space-y-4 max-w-5xl max-h-96 w-full m-16 h-full p-16 ">
                <img
                    src={ABILogo}
                    alt="logo"
                    className="object-contain h-full w-auto"
                />
                <div className="text-center font-bold text-xl text-gray-800">
                    Konnect Portal!
                </div>
                {inProgress !== InteractionStatus.Logout && (
                    <div className="flex items-center justify-center space-x-2 animate-pulse m-2">
                        <div className="w-3 h-3 bg-primary rounded-full"></div>
                        <div className="w-3 h-3 bg-primary rounded-full"></div>
                        <div className="w-3 h-3 bg-primary rounded-full"></div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default LoginPage
