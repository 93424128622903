import { useAppContext } from '@/AppContext'
import { useApplicationPreferencesStore, useStore } from '@/store/store'
import React, { useCallback, useEffect } from 'react'
import { TourDetails } from './TourDetails'

export const ProductTour: React.FC = () => {
    const app = useAppContext()
    const infoMode = useStore(state => state.infoMode)
    const setInfoMode = useStore(useCallback(state => state.setInfoMode, []))
    const setShowTourOnStartup = useApplicationPreferencesStore(
        useCallback(state => state.setShowTourOnStartup, []),
    )
    const showTourOnStartup = useApplicationPreferencesStore(
        state => state.showTourOnStartup,
    )

    useEffect(() => {
        if (app.user && showTourOnStartup) {
            setInfoMode(true)
            setShowTourOnStartup(false)
        }
    }, [app.user])

    const handleClose = (isLastStep: boolean) => {
        setInfoMode(false)
        if (isLastStep && showTourOnStartup) {
            setShowTourOnStartup(false)
        }
    }
    return (
        <>{infoMode && <TourDetails onClose={handleClose} run={infoMode} />}</>
    )
}
