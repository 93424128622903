import { getAppConfig } from "@/Config"
import { convertUnicode } from '@/utilities/contents'

export enum ProjectType {
    TIF = "Pegasus TIF",
    CIF = "Pegasus CIF",
    IPM = "IPM"
}

const PORTAL_BASE_URL = getAppConfig().portalBaseURL
const IPM_ENDPOINT = process.env.REACT_APP_PORTAL_SEARCH_IPM_URI
const PEGASUS_BASE_URI = getAppConfig().pegasusBaseURL
const PEGASUS_CIF_ENDPOINT = `/default/projectscif/stageview`
const PEGASUS_TIF_ENDPOINT = `/default/projectstif/stageview`
const IPM_URL = `${PORTAL_BASE_URL}${IPM_ENDPOINT}`
const Pegasus_CIF = `${PEGASUS_BASE_URI}${PEGASUS_CIF_ENDPOINT}`
const Pegasus_TIF = `${PEGASUS_BASE_URI}${PEGASUS_TIF_ENDPOINT}`
const Pegasus_Project = "Pegasus" + " " + "-" + " "
const IPM_Project = "IPM" + " " + "-" + " "

export const projecturl = (src: any, id: string, phaseId: string, pmisProjectId = '') => {
    switch (src) {
        case ProjectType.CIF:
            return `${Pegasus_CIF}/${phaseId}`
        case ProjectType.TIF:
            return `${Pegasus_TIF}/${phaseId}`
        case ProjectType.IPM:
            return `${IPM_URL}?id=${id}&pmisProjectId=${pmisProjectId}`
    }
}

export const projectname = (src: any, name: string) => {
    switch (src) {
        case ProjectType.CIF:
            return Pegasus_Project + convertUnicode(name)
        case ProjectType.TIF:
            return Pegasus_Project + convertUnicode(name)
        case ProjectType.IPM:
            return IPM_Project + convertUnicode(name)
    }
}