export const clearLS = async () => {
    if (window && window.localStorage) {
        await Object.entries(window.localStorage)
            .map(
                x => x[0], // get keys
            )
            .filter(x => x.includes('_intermix-'))
            .map(async x => await localStorage.removeItem(x))
    }
}

export const setAuthenticatedUserinLS = (data: any) => {
    localStorage.setItem('authenticatedUser', JSON.stringify(data))
    localStorage.setItem('contentOnly', 'true')
}

export const isAuthenticatedKonnectUser = (): boolean => {
   const user = localStorage.getItem('authenticatedUser')
   return typeof user !== 'undefined' && user !== null
}

export const clearAuthenticatedUserfromLS = () => {
    localStorage.removeItem('authenticatedUser')
    localStorage.removeItem('contentOnly')
}

export const setThemeinLS = (theme: 'dark' | 'light'): void => {
    localStorage.setItem('theme', theme)
}

export const getThemefromLS = (): 'dark' | 'light' => {
    if (
        localStorage.theme === 'dark' ||
        (!('theme' in localStorage) &&
            window.matchMedia('(prefers-color-scheme: dark)').matches)
    ) {
        return 'dark'
    } else {
        return 'light'
    }
}

export const setRedirectUrl = (data: string): void => {
    sessionStorage.setItem('konnect-redirectUrl', data)
}

export const getRedirectUrl = (): string => {
    return sessionStorage.getItem('konnect-redirectUrl') || '/'
}
