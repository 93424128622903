import { createContext } from 'react'
import { Chat, Message } from './MessagesList'

type messagesContextType = {
    chats: Chat[]
    setChats: (chats: Chat[]) => void
    ctxChat: (chat: Chat[`id`]) => Chat
    loading: boolean
    setLoading: (loading: boolean) => void
    openChat: (chat: Chat[`id`]) => void
    closeChat: (chat: Chat[`id`]) => void
    maximizeChat: (chat: Chat[`id`]) => void
    minimizeChat: (chat: Chat[`id`]) => void
    loadMessages: (chat: Chat[`id`]) => void
    getMessages: (chat: Chat[`id`]) => Message[]
    sendMessage: (chat: Chat[`id`], message: string) => void
    createChat: (personId: string) => void
}

export const MessagesContext = createContext<messagesContextType>({
    chats: [],
    setChats: (chats): void => {
        chats
    },
    loading: false,
    ctxChat: (chatId): Chat => {
        return { id: chatId } as Chat
    },
    setLoading: (loading): void => {
        loading
    },
    openChat: (chatId): void => {
        chatId
    },
    closeChat: (chatId): void => {
        chatId
    },
    maximizeChat: (chatId): void => {
        chatId
    },
    minimizeChat: (chatId): void => {
        chatId
    },
    loadMessages: (chatId): void => {
        chatId
    },
    getMessages: (chatId): Message[] => {
        return [{ id: chatId }] as Message[]
    },
    sendMessage: (chatId, message): void => {
        chatId
        message
    },
    createChat: (personId): void => {
        personId
    },
})
