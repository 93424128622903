export interface AppConfigProps {
    appId: string
    authority: string
    redirectUri: string
    scopes: string[]
    portalBaseURL: string
    messagesBaseURL: string
    pegasusBaseURL: string
    gaTrackingId: string
    mocks: boolean
    sharePointTempDriveID: string
    sharePointAskDriveId: string
    sharePointAskFolderId: string
}
export const getAppConfig = (): AppConfigProps => {
    const config = {
        portalBaseURL: process.env.REACT_APP_PORTAL_BASE_URL || '',
        pegasusBaseURL: process.env.REACT_APP_PEGASUS_BASE_URL || '',
        messagesBaseURL: process.env.REACT_APP_MESSAGES_BASE_URL || '',
        appId: process.env.REACT_APP_APP_ID || '',
        authority: process.env.REACT_APP_AUTHORITY || '',
        redirectUri: process.env.REACT_APP_REDIRECT_URI || '',
        scopes:
            (process.env.REACT_APP_SCOPES &&
                process.env.REACT_APP_SCOPES.split(',')) ||
            [],
        mocks:
            process.env.NODE_ENV === 'development' &&
            process.env.REACT_APP_MOCKS === 'true',
        gaTrackingId: process.env.REACT_APP_GA_TRACKING_ID || '',
        sharePointTempDriveID: process.env.REACT_APP_SHAREPOINT_TEMP_DRIVE_ID || '',
        sharePointAskDriveId: process.env.REACT_APP_SHAREPOINT_ASK_DOC_DRIVE_ID || '',
        sharePointAskFolderId: process.env.REACT_APP_SHAREPOINT_ASK_DOC_FOLDER_ID || '',
    }
    return config
}
