import { searchInfo } from '../utilities/SearchResults';
import { AuthCodeMSALBrowserAuthenticationProvider } from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser'

const REACT_APP_SEARCH_API_BASE_URL = process.env.REACT_APP_SEARCH_API_BASE_URL;
const REACT_APP_SUGGESTION_API_URI = process.env.REACT_APP_SUGGESTION_API_URI;
const REACT_APP_SEARCH_API_URI = process.env.REACT_APP_SEARCH_API_URI;

const searchConfig = {
    getSuggestionApiUrl: `${REACT_APP_SEARCH_API_BASE_URL}${REACT_APP_SUGGESTION_API_URI}`,
    getSearchApiUrl: `${REACT_APP_SEARCH_API_BASE_URL}${REACT_APP_SEARCH_API_URI}`,
    savedSearchedApiUrl: `${REACT_APP_SEARCH_API_BASE_URL}/api/v1/save-state`,
    npsFeedbackUrl: `${REACT_APP_SEARCH_API_BASE_URL}/api/v1/nps`,
    applicationUrl: `${REACT_APP_SEARCH_API_BASE_URL}/api/v1/application`,
    reportsUrl: `${REACT_APP_SEARCH_API_BASE_URL}/api/v1/reports`,
    resourceHistoryUrl: `${REACT_APP_SEARCH_API_BASE_URL}/api/v1/resource-history`,
    searchHistoryUrl: `${REACT_APP_SEARCH_API_BASE_URL}/api/v1/recent-search`,
}

let abortController = new AbortController();

export const abortSuggestionAPI = () => {
    abortController.abort();
}

// <Get Search Suggestions>
export async function getSuggestion(
    accessToken: string,
    query: string,
    authProvider: AuthCodeMSALBrowserAuthenticationProvider,
): Promise<any[]> {
    /** will call the suggest API */
    abortController.abort();
    abortController = new AbortController();
    const signal = abortController.signal;
    return new Promise(async (resolve, reject) => {
        try {
            const response: any = await fetch(searchConfig.getSuggestionApiUrl, {
                method: 'POST',
                headers: {
                    Authorization: accessToken,
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ "query": query }),
                signal
            });
            response.json().then((result: any) => {
                (result.statusCode === 200) ? resolve(result.data) : resolve([]);
            });
        } catch (err: any) {
            console.log(err);
            if (err.name === 'AbortError') {
                reject(err);
            }
            resolve([]);
        }
    })
}
// </Get Search Suggestions>

// <Get Search Results>
export async function getSearchResults(
    accessToken: string,
    query: string,
    page: number,
    authProvider: AuthCodeMSALBrowserAuthenticationProvider,
    email: string,
    category = '',
    recordPerPage = 10,
    searchAllSites = false,


): Promise<any[]> {
    /** will call the search API */
    return new Promise(async (resolve, reject) => {
        /** Portal Search API */
        const SearchPromise: any = new Promise(async (innerResolve, innerReject) => {
            try {
                const response: any = await fetch(searchConfig.getSearchApiUrl, {
                    method: 'POST',
                    headers: {
                        Authorization: accessToken, Accept: 'application/json', 'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ "query": query, "page": page, "email": email, type: category, size: recordPerPage, searchAllSites })
                });
                response.json().then((result: any) => {
                    (result.statusCode === 200) ? innerResolve(result.data) : innerResolve([]);
                })
            } catch (err) {
                console.log("Error for search service", err);
                innerResolve([]);
            }

        });

        /** MS graph API to fetch searched people */
        const PeoplePromise: any = new Promise(async (innerResolve) => {
            /*As we are not getting people results while searching with whole name
             we are splitting the name with first two words*/
            //const response: any = getUserDetailsByQuery(authProvider, email || splitWordsFromText(query, 2), '')
            innerResolve([]);
        });

        const [searchResults, peopleResults] = await Promise.all([SearchPromise, PeoplePromise]);
        const results = searchInfo(searchResults, peopleResults);
        resolve(results);
    })
}
// </Get Search Results>


// < Save Search Data >
export async function saveSearchedState(
    accessToken: string,
    payload: any,
): Promise<any[]> {
    /** will call the suggest API */
    return new Promise(async (resolve, reject) => {
        try {
            const response: any = await fetch(searchConfig.savedSearchedApiUrl, {
                method: 'POST',
                headers: {
                    Authorization: accessToken,
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload)
            });
            response.json().then((result: any) => {
                (result.statusCode === 200) ? resolve(result.data) : resolve([]);
            });
        } catch (err) {
            console.log(err);
            resolve([]);
        }
    })
}

export async function getSavedSearchList(
    accessToken: string,
): Promise<any[]> {

    return new Promise(async (resolve, reject) => {
        try {
            const response: any = await fetch(searchConfig.savedSearchedApiUrl, {
                method: 'GET',
                headers: {
                    Authorization: accessToken,
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                }
            });
            response.json().then((result: any) => {
                (result.data) ? resolve(result.data) : resolve([]);
            });
        } catch (err) {
            console.log(err);
            resolve([]);
        }
    })
}

export async function checkFeedbackGivenLast60days(
    accessToken: string,
): Promise<any[]> {
    return new Promise(async (resolve) => {
        try {
            const response: any = await fetch(`${searchConfig.npsFeedbackUrl}/check`, {
                method: 'GET',
                headers: {
                    Authorization: accessToken,
                    Accept: 'application/json'
                }
            });
            response.json().then((result: any) => {
                resolve(result.data);
            });
        } catch (err) {
            console.log(err);
            resolve([]);
        }
    })
}

export async function postNpsFeedback(
    accessToken: string,
    payload: any,
): Promise<any> {
    return new Promise(async (resolve) => {
        try {
            const response: any = await fetch(searchConfig.npsFeedbackUrl, {
                method: 'POST',
                headers: {
                    Authorization: accessToken,
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload)
            });
            response.json().then((result: any) => {
                (result.message) ? resolve(result) : resolve({});
            });
        } catch (err) {
            console.log(err);
            resolve({});
        }
    })
}

export async function getNPSGraphData(
    accessToken: string,
    payload: any
): Promise<any> {
    return new Promise(async (resolve) => {
        try {
            const response: any = await fetch(`${searchConfig.npsFeedbackUrl}/monthly`, {
                method: 'POST',
                headers: {
                    Authorization: accessToken,
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload)
            });
            response.json().then((result: any) => {
                (result.data) ? resolve(result.data) : resolve({});
            });
        } catch (err) {
            console.log(err);
            resolve({});
        }
    })
}

export async function getNPSAllData(
    accessToken: string,
    payload: any,
): Promise<any> {
    return new Promise(async (resolve) => {
        try {
            const response: any = await fetch(`${searchConfig.npsFeedbackUrl}/all`, {
                method: 'POST',
                headers: {
                    Authorization: accessToken,
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload)
            });
            response.json().then((result: any) => {
                (result.data) ? resolve(result.data) : resolve({});
            });
        } catch (err) {
            console.log(err);
            resolve({});
        }
    })
}


export async function getAllApplications(
    accessToken: string
): Promise<any> {
    return new Promise(async (resolve) => {
        try {
            const response: any = await fetch(searchConfig.applicationUrl, {
                method: 'GET',
                headers: {
                    Authorization: accessToken,
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                }
            });
            response.json().then((result: any) => {
                (result.data) ? resolve(result.data) : resolve([]);
            });
        } catch (err) {
            console.log(err);
            resolve([]);
        }
    })
}

export async function setApplication(
    accessToken: string,
    payload: any,
): Promise<any> {
    return new Promise(async (resolve) => {
        try {
            const response: any = await fetch(searchConfig.applicationUrl, {
                method: 'POST',
                headers: {
                    Authorization: accessToken,
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload)
            });
            response.json().then((result: any) => {
                (result.data) ? resolve(result.data) : resolve({});
            });
        } catch (err) {
            console.log(err);
            resolve({});
        }
    })
}

export async function getPowerBIReportsList(
    accessToken: string,
): Promise<any[]> {
    return new Promise(async (resolve) => {
        try {
            const response: any = await fetch(searchConfig.reportsUrl, {
                method: 'GET',
                headers: {
                    Authorization: accessToken,
                    Accept: 'application/json'
                }
            });
            response.json().then((result: any) => {
                result.data ? resolve(result.data) : resolve([]);
            });
        } catch (err) {
            console.log(err);
            resolve([]);
        }
    })
}

export async function addReportMenu(
    accessToken: string,
    payload: any,
): Promise<any> {
    return new Promise(async (resolve) => {
        try {
            const response: any = await fetch(searchConfig.reportsUrl, {
                method: 'POST',
                headers: {
                    Authorization: accessToken,
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload)
            });
            response.json().then((result: any) => {
                (result) ? resolve(result) : resolve({});
            });
        } catch (err) {
            console.log(err);
            resolve({});
        }
    })
}

export async function updateMenuOrder(
    accessToken: string,
    payload: any,
): Promise<any> {
    return new Promise(async (resolve, reject) => {
        try {
            const response: any = await fetch(`${searchConfig.reportsUrl}/re-order`, {
                method: 'POST',
                headers: {
                    Authorization: accessToken,
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload)
            });
            response.json().then((result: any) => {
                (response.ok) ? resolve(result) : reject({message:response.statusText});
            });
        } catch (err:any ) {
            console.log(err);
            reject({message: err.message})
        }
    })
}

export async function updateReportMenu(
    accessToken: string,
    id: string,
    payload: any,
): Promise<any> {
    return new Promise(async (resolve) => {
        try {
            const response: any = await fetch(`${searchConfig.reportsUrl}/${id}`, {
                method: 'PUT',
                headers: {
                    Authorization: accessToken,
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload)
            });
            response.json().then((result: any) => {
                (result) ? resolve(result) : resolve({});
            });
        } catch (err) {
            console.log(err);
            resolve({});
        }
    })
}


export async function deleteReportMenu(
    accessToken: string,
    id: string,
    deleteSubMenu: boolean
): Promise<any> {
    return new Promise(async (resolve) => {
        try {
            const response: any = await fetch(`${searchConfig.reportsUrl}/${id}?deleteSubMenu=${deleteSubMenu ? '1' : '0'}`, {
                method: 'DELETE',
                headers: {
                    Authorization: accessToken,
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                }
            });
            response.json().then((result: any) => {
                (result) ? resolve(result) : resolve({});
            });
        } catch (err) {
            console.log(err);
            resolve({});
        }
    })
}

export async function recordHistory(
    accessToken: string,
    payload: any,
): Promise<any> {
    return new Promise(async (resolve) => {
        try {
            const response: any = await fetch(searchConfig.resourceHistoryUrl, {
                method: 'POST',
                headers: {
                    Authorization: accessToken,
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload)
            });
            response.json().then((result: any) => {
                (result) ? resolve(result) : resolve({});
            });
        } catch (err) {
            console.log(err);
            resolve({});
        }
    })
}

export async function getResourceHistoryList(
    accessToken: string,
): Promise<any[]> {
    return new Promise(async (resolve) => {
        try {
            const response: any = await fetch(`${searchConfig.resourceHistoryUrl}`, {
                method: 'GET',
                headers: {
                    Authorization: accessToken,
                    Accept: 'application/json'
                }
            });
            response.json().then((result: any) => {
                result.data ? resolve(result.data) : resolve([]);
            });
        } catch (err) {
            console.log(err);
            resolve([]);
        }
    })
}

export async function getSearchHistoryList(
    accessToken: string,
): Promise<any[]> {
    return new Promise(async (resolve) => {
        try {
            const response: any = await fetch(`${searchConfig.searchHistoryUrl}`, {
                method: 'GET',
                headers: {
                    Authorization: accessToken,
                    Accept: 'application/json'
                }
            });
            response.json().then((result: any) => {
                result ? resolve(result.data) : resolve([]);
            });
        } catch (err) {
            console.log(err);
            resolve([]);
        }
    })
}

export async function deleteSavedSearch(
    accessToken: string,
    id: string,
): Promise<any> {
    return new Promise(async (resolve) => {
        try {
            const response: any = await fetch(`${searchConfig.savedSearchedApiUrl}/${id}`, {
                method: 'DELETE',
                headers: {
                    Authorization: accessToken,
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                }
            });
            response.json().then((result: any) => {
                (result) ? resolve(result) : resolve({});
            });
        } catch (err) {
            console.log(err);
            resolve({});
        }
    })

}

export async function postRecentSearch(
    accessToken: string,
    payload: any,
): Promise<any> {
    return new Promise(async (resolve) => {
        try {
            const response: any = await fetch(searchConfig.searchHistoryUrl, {
                method: 'POST',
                headers: {
                    Authorization: accessToken,
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload)
            });
            response.json().then((result: any) => {
                (result.message) ? resolve(result) : resolve({});
            });
        } catch (err) {
            console.log(err);
            resolve({});
        }
    })
}