import * as React from 'react'
import { ISideNavMenuOptions, ISideNavContainer } from './types'
import { Link } from 'react-router-dom'
import { baseSideNavConfig } from './IntermixDashboardShell'
import { useAppContext } from '@/AppContext'
import { PinnedApp } from '../blocks/HomeTab/PinnedApp'
import { SearchCategory } from '../blocks/SearchInput/SearchCategory'

export const SideNavMenu: React.FC<ISideNavMenuOptions> = React.memo(
    ({ title, path, active, icon, onClick, isExternalApp, openInNewTab, pinned, resourceId, name }) => {
        const { navLinks } = useAppContext()
        const appList = navLinks.map(i => i.shortName).join(",")
        
        if (isExternalApp) {
            return (
                <a
                target={openInNewTab ? '_blank' : '_self' }
                    href={`${path}?floatingMenu=true&apps=${appList}`}
                    className={`flex item-center h-12 pl-2.5 pr-3 py-3.5 mt-2 group
                 align-middle text-center transition-all duration-200 rounded-md ml-2 mr-2 hover:bg-baby-orange-50 hover:text-white dark:hover:bg-primary dark:text-gray-200 ${active
                            ? `bg-baby-gray-100 dark:bg-primary text-gray-700`
                            : `bg-transparent text-gray-700`
                        } `}
                    onClick={() => {
                        onClick && onClick()
                    }}
                >
                     <div>
                    <PinnedApp category={SearchCategory.LEFT_MENU_APP}
                        url={path} name={name} id={resourceId} pinned={pinned} />
                </div>
                    <div className="filter invert-50 group-hover:filter-none">{icon}</div>
                    <div className="text-left ml-5  flex items-center">
                        {title}
                    </div>
                </a>
            )
        }

        return (
            <Link
                id={title}
                draggable={false}
                to={path}
                onClick={() => {
                    onClick && onClick()
                }}
                className={`flex item-center h-12 pl-2.5 pr-3 py-3.5 mt-2 group
                 align-middle text-center transition-all duration-200 rounded-md ml-2 mr-2 hover:bg-baby-orange-50 hover:text-white dark:hover:bg-primary dark:text-gray-200 ${active
                        ? `bg-baby-gray-100 dark:bg-primary text-gray-700`
                        : `bg-transparent text-gray-700`
                    } `}
            >
                <div>
                    <PinnedApp category={SearchCategory.LEFT_MENU_APP}
                        url={path} name={name} id={resourceId} pinned={pinned} />
                </div>
                <div className="filter invert-50 group-hover:filter-none">{icon}</div>
                <div className="text-left ml-5  flex items-center">
                    {title}
                </div>
            </Link>
        )
    },
)

export const sideNavConfig: ISideNavContainer = {
    name: 'Main App',
    menu: {
        component: SideNavMenu,
        links: [],
        ...baseSideNavConfig,
    },
    customComponent: () => (
        <div className="bg-gray-50 dark:bg-gray-700 dark:text-gray-50 flex h-full align-middle text-center items-center justify-center rounded-md">
            <p className="text-gray-700 dark:text-secondary-100 text-center">
                Search Filter
            </p>
        </div>
    ),
}
