import { Loading } from '@/components/atoms/Loading'
import { useContext, useEffect, useState } from 'react'
import { Message } from './Message'
import { MessagesContext } from './MessageContext'
import { Chat, Message as MessageType } from './MessagesList'

type messagesProps = {
    chatId: Chat[`id`]
}

export const Messages: React.FC<messagesProps> = ({
    chatId,
}: messagesProps) => {
    const messagesContext = useContext(MessagesContext)

    useEffect(() => {
        const interval = setInterval(() => {
            messagesContext.loadMessages(chatId)
        }, 5000)
        return () => clearInterval(interval)
    }, [])

    return (
        <MessagesContext.Consumer>
            {({ getMessages }) => {
                return (
                    <div className="w-full h-96 flex flex-col-reverse justify-start items-center px-3 pb-2 overflow-y-auto overflow-x-hidden transition-all">
                        {getMessages(chatId)
                            .filter(c => c.deletedDateTime == null)
                            .map((message: MessageType, index: number) => (
                                <Message
                                    key={message.id}
                                    message={message}
                                    prevMessage={getMessages(chatId)[index + 1]}
                                />
                            ))}
                        {getMessages(chatId).length === 1 && (
                            <div className="px-2 py-1 bg-gray-100 rounded mb-2">
                                <Loading />
                            </div>
                        )}
                        {getMessages(chatId).length === 0 && (
                            <div className="px-2 py-1 bg-gray-100 rounded mb-2">
                                No messages yet 😕
                            </div>
                        )}
                    </div>
                )
            }}
        </MessagesContext.Consumer>
    )
}
