import { Chat, Message } from '@/components/blocks/messages/MessagesList'
import { TodoTask } from 'microsoft-graph'
const taskAPIBaseURL = process.env.REACT_APP_TASK_API_BASE_URL || ''
const notificationsAPIBaseURL =
    process.env.REACT_APP_NOTIFICATIONS_API_BASE_URL || ''
const messagesAPIBaseURL = process.env.REACT_APP_MESSAGES_API_BASE_URL || ''
const feedbackAPIBaseURL =
    process.env.REACT_APP_FEEDBACK_TOOLS_API_BASE_URL || ''
const taskAPIURL = process.env.REACT_APP_TASK_API_URI || ''
const feedbackAPIURL = process.env.REACT_APP_FEEDBACK_TOOLS_API_URI || ''
const notificationAPIURL = process.env.REACT_APP_NOTIFICATIONS_API_URI || ''
const messagesAPIURL = process.env.REACT_APP_MESSAGES_API_URL || ''
const insightsAPIURL = process.env.REACT_APP_INSIGHTS_API_URL || ''
const innovatorAPIURL = process.env.REACT_APP_INNOVATOR_API_URL || ''

export const graphConfig = {}

export const tasksEndpoints = {
    getAllTasksEndpoint: `${taskAPIBaseURL}${taskAPIURL}/v1/taskList/getAlltaskslist`,
    getAllTasksPlannerEndpoint: `${taskAPIBaseURL}${taskAPIURL}/v1/msplanner/tasks`,
    getAllTasksPMEndpoint: `${taskAPIBaseURL}${taskAPIURL}/v1/ipm/task`,
    getOnetaskslistEndpoint: `${taskAPIBaseURL}${taskAPIURL}/v1/taskList/getOnetaskslist`,
    createtaskslistEndpoint: `${taskAPIBaseURL}${taskAPIURL}/v1/taskList/createtaskslist`,
    updatetaskslistEndpoint: `${taskAPIBaseURL}${taskAPIURL}/v1/taskList/updatetaskslist`,
    deletetaskslistEndpoint: `${taskAPIBaseURL}${taskAPIURL}/v1/taskList/deletetaskslist`,
    allTasksEndpoint: `${taskAPIBaseURL}${taskAPIURL}/v1/task/getAllTasks`,
    createtasksEndpoint: `${taskAPIBaseURL}${taskAPIURL}/v1/task/createtasks`,
    updatetasksEndpoint: `${taskAPIBaseURL}${taskAPIURL}/v1/task/updatetasks`,
    deletetasksEndpoint: `${taskAPIBaseURL}${taskAPIURL}/v1/task/deletetasks`,
    getOnetaskEndpoint: `${taskAPIBaseURL}${taskAPIURL}/v1/task/getOnetask`,
    createPlannerTask: `${taskAPIBaseURL}${taskAPIURL}/v1/msplanner/tasks`
}

export const feedbackEndpoints = {
    postFeedbackEndpoint: `${feedbackAPIBaseURL}${feedbackAPIURL}/`,
    getFeedbackListEndpoint: `${feedbackAPIBaseURL}${feedbackAPIURL}/`,
}

export const notificationsEndpoints = {
    getAllNotificationsEndpoint: `${notificationsAPIBaseURL}${notificationAPIURL}/0`,
    postNotificationEndpoint: `${notificationsAPIBaseURL}${notificationAPIURL}/`,
    pathNotificationAsReadEndpoint: `${notificationsAPIBaseURL}${notificationAPIURL}/markAsRead`,
}

export const messagesEndpoints = {
    getAllConversationsEndpoint: `${messagesAPIBaseURL}${messagesAPIURL}/getChats`,
    getAllMessagesEndpoint: `${messagesAPIBaseURL}${messagesAPIURL}/getChat`,
    sendMessagesEndpoint: `${messagesAPIBaseURL}${messagesAPIURL}`,
    getGraphContent: `${messagesAPIBaseURL}${messagesAPIURL}`,
    createChatEndpoint: `${messagesAPIBaseURL}${messagesAPIURL}/createChat`,
}

export const insightsEndpoints = {
    getInsightsFiles: `${insightsAPIURL}/v1/relatedsharepointitens`,
}

export const innovatorEndpoints = {
    searchPeople: `${innovatorAPIURL}/search`,
}

export enum NotificationType {
    TaskEvent = 'TaskEvent',
    ProjectEvent = 'ProjectEvent',
    CalendarEvent = 'CalendarEvent',
    GeneralEvent = 'GeneralEvent',
}

export type Notification = {
    id: string
    title: string
    description: string
    link: string
    from: string
    recipient: string
    priority: number
    category: string
    type?: NotificationType
    triggerDate: Date
    validThru: Date
    read?: boolean
}

export type Feedback = {
    identifier: string
    type: 'positive' | 'negative' | 'comment'
    comment?: string
    userEmail?: string
}

export const callSendNotification = async (
    accessToken: string,
    notification: Notification,
) => {
    if (notificationsAPIBaseURL === '') {
        return null
    }
    return fetch(notificationsEndpoints.postNotificationEndpoint, {
        method: 'POST',
        headers: {
            Authorization: accessToken,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(notification),
    })
        .then(response => response.json())
        .catch(error => console.log(error))
}

export const callGetAllNotifications = async (accessToken: string) => {
    if (notificationsAPIBaseURL === '') {
        return null
    }
    return fetch(notificationsEndpoints.getAllNotificationsEndpoint, {
        method: 'GET',
        headers: {
            Authorization: accessToken,
        },
    })
        .then(response => response.json())
        .catch(error => console.log(error))
}

export const callMarkNoficationAsRead = async (
    accessToken: string,
    notificationId: string,
) => {
    if (notificationsAPIBaseURL === '') {
        return null
    }
    const endpoint = notificationsEndpoints.pathNotificationAsReadEndpoint
    const body = { notificationId }
    return fetch(endpoint, {
        method: 'PATCH',
        headers: {
            Authorization: accessToken,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    })
        .then(response => response.json())
        .catch(error => console.log(error))
}

//#region Tasks API

export const callAllTaskLists = async (source: string, accessToken: string) => {
    if (taskAPIBaseURL === '') {
        return null
    }

    let endpoint: string = tasksEndpoints.getAllTasksEndpoint

    switch (source) {
        case `Microsoft To Do`:
            endpoint = tasksEndpoints.getAllTasksEndpoint
            break
        case `Microsoft Planner`:
            endpoint = tasksEndpoints.getAllTasksPlannerEndpoint
            break
        case `Project Management`:
            endpoint = tasksEndpoints.getAllTasksPMEndpoint
            break

        default:
            break
    }

    return fetch(endpoint, {
        method: 'GET',
        headers: {
            Authorization: accessToken,
        },
    })
        .then(response => response.json())
        .catch(error => console.log(error))
}

export const callGetOneTasklist = async (
    accessToken: string,
    taskListId: string,
) => {
    if (taskAPIBaseURL === '') {
        return null
    }
    const endpoint =
        tasksEndpoints.getOnetaskslistEndpoint +
        '?' +
        new URLSearchParams({
            taskListId: taskListId,
        })
    return fetch(endpoint, {
        method: 'GET',
        headers: {
            Authorization: accessToken,
        },
    })
        .then(response => response.json())
        .catch(error => console.log(error))
}

export const callCreateTaskList = async (
    accessToken: string,
    displayName: string,
) => {
    if (taskAPIBaseURL === '') {
        return null
    }
    const body = { displayName }
    return fetch(tasksEndpoints.createtaskslistEndpoint, {
        method: 'POST',
        headers: {
            Authorization: accessToken,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    })
        .then(response => response.json())
        .catch(error => console.log(error))
}

export const callFeedbackList = async (accessToken: string) => {
    if (feedbackAPIBaseURL === '') {
        return null
    }
    return fetch(feedbackEndpoints.getFeedbackListEndpoint, {
        method: 'GET',
        headers: {
            Authorization: accessToken,
        },
    })
        .then(response => response.json())
        .catch(error => console.log(error))
}

export const callPostFeedback = async (
    accessToken: string,
    feedback: Feedback,
) => {
    if (feedbackAPIBaseURL === '') {
        return null
    }
    const body = { ...feedback }
    return fetch(feedbackEndpoints.postFeedbackEndpoint, {
        method: 'POST',
        headers: {
            Authorization: accessToken,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    })
        .then(response => response.json())
        .catch(error => console.log(error))
}

export const callUpdateTaskList = async (
    accessToken: string,
    taskListId: string,
    displayName: string,
) => {
    if (taskAPIBaseURL === '') {
        return null
    }
    const endpoint = tasksEndpoints.updatetaskslistEndpoint
    const body = { id: taskListId, displayName: displayName }
    return fetch(endpoint, {
        method: 'PATCH',
        headers: {
            Authorization: accessToken,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    })
        .then(response => response.json())
        .catch(error => console.log(error))
}

export const callDeleteTaskList = async (
    accessToken: string,
    taskListId: string,
) => {
    if (taskAPIBaseURL === '') {
        return null
    }
    const endpoint =
        tasksEndpoints.deletetaskslistEndpoint +
        '?' +
        new URLSearchParams({
            taskListId: taskListId,
        })
    return fetch(endpoint, {
        method: 'DELETE',
        headers: {
            Authorization: accessToken,
        },
    })
        .then(response => response.json())
        .catch(error => console.log(error))
}

export const callAllTaskListTask = async (
    accessToken: string,
    listId: string,
) => {
    if (taskAPIBaseURL === '') {
        return null
    }
    const endpoint =
        tasksEndpoints.allTasksEndpoint +
        '?' +
        new URLSearchParams({
            taskListId: listId,
        })
    return fetch(endpoint, {
        method: 'GET',
        headers: {
            Authorization: accessToken,
        },
    })
        .then(response => response.json())
        .catch(error => console.log(error))
}

export const callAllTaskListTaskV2 = async (
    accessToken: string,
    listId: string,
    timeZone: string,
) => {
    if (taskAPIBaseURL === '') {
        return null
    }
    const endpoint =
        tasksEndpoints.allTasksEndpoint +
        '?' +
        new URLSearchParams({
            taskListId: listId,
        }) + '&orderby=status,importance desc,lastModifiedDateTime desc'
    return fetch(endpoint, {
        method: 'GET',
        headers: {
            Authorization: accessToken,
            // TODO: ENABLE When Available   Prefer: 'outlook.timezone="' + timeZone + '"',
        },
    })
        .then(response => response.json())
        .catch(error => console.log(error))
}

export const callOneTaskListTask = async (
    accessToken: string,
    taskListId: string,
    taskId: string,
) => {
    if (taskAPIBaseURL === '') {
        return null
    }
    const endpoint =
        tasksEndpoints.getOnetaskEndpoint +
        '?' +
        new URLSearchParams({
            taskListId: taskListId,
            taskId: taskId,
        })
    return fetch(endpoint, {
        method: 'GET',
        headers: {
            Authorization: accessToken,
        },
    })
        .then(response => response.json())
        .catch(error => console.log(error))
}

export const callCreateTask = async (
    accessToken: string,
    todoTaskListId: string,
    task: TodoTask,
) => {
    if (taskAPIBaseURL === '') {
        return null
    }
    const endpoint =
        tasksEndpoints.createtasksEndpoint +
        '?' +
        new URLSearchParams({
            todoTaskListId: todoTaskListId,
        })
    return fetch(endpoint, {
        method: 'POST',
        headers: {
            Authorization: accessToken,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(task),
    })
        .then(response => response.json())
        .catch(error => console.log(error))
}

export const callUpdateTask = async (
    accessToken: string,
    todoTaskListId: string,
    task: TodoTask,
) => {
    if (taskAPIBaseURL === '') {
        return null
    }
    const endpoint =
        tasksEndpoints.updatetasksEndpoint +
        '?' +
        new URLSearchParams({
            todoTaskListId: todoTaskListId,
        })
    return fetch(endpoint, {
        method: 'PATCH',
        headers: {
            Authorization: accessToken,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(task),
    })
        .then(response => response.json())
        .catch(error => console.log(error))
}

export const callDeleteTask = async (
    accessToken: string,
    taskListId: string,
    taskId: string,
) => {
    if (taskAPIBaseURL === '') {
        return null
    }
    const endpoint =
        tasksEndpoints.deletetasksEndpoint +
        '?' +
        new URLSearchParams({
            taskListId: taskListId,
            taskId: taskId,
        })
    return fetch(endpoint, {
        method: 'DELETE',
        headers: {
            Authorization: accessToken,
        },
    })
        .then(response => response.json())
        .catch(error => console.log(error))
}

//#endregion

//#region Messages API

export const callGetConversations = async (
    accessToken: string,
): Promise<Chat[] | null> => {
    if (messagesAPIBaseURL === '') {
        return null
    }
    return fetch(messagesEndpoints.getAllConversationsEndpoint, {
        method: 'GET',
        headers: {
            Authorization: accessToken,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    })
        .then(response => response.json())
        .catch(error => console.log(error))
}

export const callGetMessages = async (
    chatId: Chat['id'],
    accessToken: string,
): Promise<Message[]> => {
    if (messagesAPIBaseURL === '' || chatId === '') {
        return []
    }
    return fetch(
        `${messagesEndpoints.getAllMessagesEndpoint}/${chatId}/messages`,
        {
            method: 'GET',
            headers: {
                Authorization: accessToken,
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        },
    )
        .then(response => response.json())
        .catch(error => console.log(error))
}

export const callSendMessage = async (
    chatId: Chat['id'],
    message: string,
    accessToken: string,
): Promise<any> => {
    if (messagesAPIBaseURL === '' || chatId === '') {
        return ''
    }
    return fetch(`${messagesEndpoints.sendMessagesEndpoint}/${chatId}/send`, {
        method: 'POST',
        headers: {
            Authorization: accessToken,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ message }),
    })
        .then((response: any) => response.json())
        .catch(error => console.log(error))
}

export const callCreateChat = async (
    userId: string,
    accessToken: string,
): Promise<any> => {
    if (messagesAPIBaseURL === '' || userId === '') {
        return ''
    }
    return fetch(`${messagesEndpoints.createChatEndpoint}/`, {
        method: 'POST',
        headers: {
            Authorization: accessToken,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ personId: userId }),
    })
        .then((response: any) => response.json())
        .catch(error => console.log(error))
}

//#endregion

//#region Insights API

export const getInsights = async (accessToken: string) => {
    if (insightsAPIURL === '') return null

    return fetch(insightsEndpoints.getInsightsFiles, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    })
        .then(response => response.json())
        .catch(error => console.log(error))
}

//#endregion

//#region Innovator API
export const callSearchPeople = async (
    name: string,
    accessToken: string,
): Promise<any> => {
    return fetch(`${innovatorEndpoints.searchPeople}?name=${name}`, {
        method: 'GET',
        headers: {
            Authorization: accessToken,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    })
        .then((response: any) => response.json())
        .catch(error => console.log(error))
}
//#endregion

export const createPlannerTask = async (
    accessToken: string,
    data: any,
) => {
    if (taskAPIBaseURL === '') {
        return null
    }
    const body = { ...data }
    return fetch(tasksEndpoints.createPlannerTask, {
        method: 'POST',
        headers: {
            Authorization: accessToken,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    })
        .then(response => response.json())
        .catch(error => console.log(error))
}

export const updatePlannerTask = async (
    accessToken: string,
    data: any,
) => {
    if (taskAPIBaseURL === '') {
        return null
    }
    const body = {...data}
    return fetch(tasksEndpoints.createPlannerTask, {
        method: 'PATCH',
        headers: {
            Authorization: accessToken,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    })
        .then(response => response.json())
        .catch(error => console.log(error))
}

export const deletePlannerTask = async (
    accessToken: string,
    data: any,
) => {
    if (taskAPIBaseURL === '') {
        return null
    }
    const body = { ...data }
    return fetch(tasksEndpoints.createPlannerTask, {
        method: 'DELETE',
        headers: {
            Authorization: accessToken,
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    })
        .then(response => response.json())
        .catch(error => console.log(error))
}
