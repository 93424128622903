import { Avatar } from '@/components/atoms/Avatar'
import { useMsal } from '@azure/msal-react'
import { Message as MessageType } from './MessagesList'
import { formatDistanceToNowStrict, differenceInMilliseconds } from 'date-fns'
import { formatRelative } from 'date-fns/esm'

type messageProps = {
    message: MessageType
    prevMessage: MessageType
}

export const Message: React.FC<messageProps> = ({
    message,
    prevMessage,
}: messageProps) => {
    const userOid = useMsal().accounts[0].localAccountId ?? ``

    const lastMessageItsOneMinAfterOrNotSameSender = () => {
        if (!prevMessage) {
            return false
        }
        const diff = differenceInMilliseconds(
            new Date(prevMessage.createdDateTime),
            new Date(message.createdDateTime),
        )
        const notSameSender =
            prevMessage.from?.user?.id !== message.from.user?.id

        return diff > 60000 || notSameSender
    }

    const getMessageContentSanitezed = (content: string): string => {
        const imgPattern = /<img [^>]*src="([^"]+)"[^>]*>/gi
        const urlPattern =
            /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=$]*)/gi

        const imgMatches: RegExpMatchArray | null = content.match(imgPattern)

        if (imgMatches) {
            imgMatches?.forEach(async (imgTag: string) => {
                if (imgTag.includes(`emoticon`)) return

                content = content.replace(
                    imgTag,
                    imgTag
                        .replace(/height="[\d]+(\.)?[\d]+"/, `height="100%"`)
                        .replace(/width="[\d]+(\.)?[\d]+"/, `width="100%"`)
                        .replace(/height:[\d]+(\.)?[\d]+px/, `width="100%"`)
                        .replace(/width:[\d]+(\.)?[\d]+px/, `width="100%"`)
                        .replace('src=','authSrc='),
                )
                content = content.replaceAll('img','img is="auth-img"') //change img to custom auth-img element 
            })
        }
        return content
    }

    return (
        <>
            {message.from?.user?.id === userOid && (
                <div
                    className={`w-full flex flex-row justify-end my-1 ${
                        !lastMessageItsOneMinAfterOrNotSameSender() &&
                        'mb-px mt-px'
                    }`}
                >
                    <div
                        className={`px-2 py-1 bg-blue-50 dark:bg-blue-800 dark:text-white overflow-x-auto rounded-lg ${
                            lastMessageItsOneMinAfterOrNotSameSender() &&
                            'rounded-tr-none'
                        }`}
                        style={{ maxWidth: '80%' }}
                    >
                        <div className="flex flex-row justify-between items-center">
                            <p className="text-xs font-bold">
                                {lastMessageItsOneMinAfterOrNotSameSender() && (
                                    <>
                                        {formatDistanceToNowStrict(
                                            new Date(message.createdDateTime),
                                            {
                                                addSuffix: true,
                                            },
                                        )}
                                    </>
                                )}
                            </p>
                            <ReactionsComponent reactions={message.reactions} />
                        </div>
                        <span
                            dangerouslySetInnerHTML={{
                                __html: getMessageContentSanitezed(
                                    message.body.content,
                                ),
                            }}
                        ></span>
                    </div>
                </div>
            )}
            {message.from && message.from.user?.id !== userOid && (
                <div
                    className={`w-full flex flex-row justify-start my-1 ${
                        !lastMessageItsOneMinAfterOrNotSameSender() &&
                        'mb-px mt-px'
                    }`}
                >
                    <div className="text-xs font-bold">
                        <Avatar
                            name={
                                message.from.user?.displayName ??
                                message.from.application?.displayName
                            }
                            userId={
                                message.from.user?.id ??
                                message.from.application?.id
                            }
                            className="w-6 h-6"
                        />
                    </div>
                    <div
                        className={`px-2 py-1 ml-1 bg-gray-100 dark:bg-gray-800 dark:text-gray-50 text-gray-900 overflow-x-auto rounded-lg ${
                            lastMessageItsOneMinAfterOrNotSameSender() &&
                            'rounded-tl-none'
                        }`}
                        style={{ maxWidth: '80%' }}
                    >
                        <div className="flex flex-row justify-between items-center">
                            <p className="text-xs font-bold">
                                {lastMessageItsOneMinAfterOrNotSameSender() && (
                                    <>
                                        {formatDistanceToNowStrict(
                                            new Date(message.createdDateTime),
                                            {
                                                addSuffix: true,
                                            },
                                        )}
                                    </>
                                )}
                            </p>
                            <ReactionsComponent reactions={message.reactions} />
                        </div>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: getMessageContentSanitezed(
                                    message.body.content,
                                ),
                            }}
                        ></p>
                    </div>
                </div>
            )}
            {!message.from && (
                <>
                    {message.eventDetail['@odata.type'] ===
                        '#microsoft.graph.membersJoinedEventMessageDetail' && (
                        <div className="w-full flex flex-row justify-start items-center p-1 bg-gray-50 rounded my-2 border-l-4 border-purple-500 text-sm text-gray-700">
                            <div className="flex flex-row -space-x-2">
                                {message.eventDetail.members?.map(
                                    (member, index) => (
                                        <Avatar
                                            name="Non Applicable"
                                            userId={member.id}
                                            className={`w-6 h-6 transform border-2 border-gray-50 ${
                                                index !== 0 ?? '-translate-x-2'
                                            }`}
                                        />
                                    ),
                                )}
                            </div>
                            <p className="ml-2">were added to the meeting</p>
                        </div>
                    )}
                    {message.eventDetail['@odata.type'] ===
                        '#microsoft.graph.membersLeftEventMessageDetail' && (
                        <div className="w-full flex justify-start items-center p-1 bg-gray-50 rounded my-2 border-l-4 border-purple-500 text-sm text-gray-700">
                            <div className="flex flex-row -space-x-1">
                                {message.eventDetail.members?.map(
                                    (member, index) => (
                                        <Avatar
                                            name="Non Applicable"
                                            userId={member.id}
                                            className={`w-6 h-6 transform ${
                                                index !== 0 ?? '-translate-x-2'
                                            }`}
                                        />
                                    ),
                                )}
                            </div>
                            <p className="ml-2">
                                no longer has access to the chat
                            </p>
                        </div>
                    )}
                    {message.eventDetail['@odata.type'] ===
                        '#microsoft.graph.chatRenamedEventMessageDetail' && (
                        <div className="w-full flex justify-start items-center p-1 bg-gray-50 rounded my-2 border-l-4 border-purple-500 text-sm text-gray-700">
                            Chat renamed to{' '}
                            {message.eventDetail.chatDisplayName}
                        </div>
                    )}
                    {message.eventDetail['@odata.type'] ===
                        '#microsoft.graph.callStartedEventMessageDetail' && (
                        <div className="w-full flex justify-start items-center p-1 bg-gray-50 rounded my-2 border-l-4 border-purple-500 text-sm text-gray-700">
                            {formatRelative(
                                new Date(message.createdDateTime),
                                new Date(),
                            )}{' '}
                            {message.eventDetail.callEventType} started
                        </div>
                    )}
                    {message.eventDetail['@odata.type'] ===
                        '#microsoft.graph.callEndedEventMessageDetail' && (
                        <div className="w-full flex justify-start items-center p-1 bg-gray-50 rounded my-2 border-l-4 border-purple-500 text-sm text-gray-700">
                            {formatRelative(
                                new Date(message.createdDateTime),
                                new Date(),
                            )}{' '}
                            {message.eventDetail.callEventType} ended:{' '}
                            {message.eventDetail.callDuration
                                ?.replace('PT', '')
                                .replace('M', 'Min ')
                                .replace('S', 'Sec')}
                        </div>
                    )}
                </>
            )}
        </>
    )
}

type reactionsProps = {
    reactions: MessageType[`reactions`]
}
const ReactionsComponent: React.FC<reactionsProps> = ({
    reactions,
}: reactionsProps) => {
    const groupReactions = (list: reactionsProps[`reactions`]) => {
        return list.reduce(
            (groups, item) => ({
                ...groups,
                [item.reactionType]: [
                    ...(groups[item.reactionType] || []),
                    item,
                ],
            }),
            {},
        )
    }

    const groupedReactions = groupReactions(reactions)

    return (
        <div className="flex flex-row justify-end items-center text-sm">
            {groupedReactions.like && (
                <div>👍{groupedReactions.like?.length}</div>
            )}
            {groupedReactions.heart && (
                <div>❤️{groupedReactions.heart?.length}</div>
            )}
            {groupedReactions.laugh && (
                <div>😁{groupedReactions.laugh?.length}</div>
            )}
            {groupedReactions.surprised && (
                <div>😮{groupedReactions.surprised?.length}</div>
            )}
            {groupedReactions.sad && (
                <div>😟{groupedReactions.sad?.length}</div>
            )}
            {groupedReactions.angry && (
                <div>😡{groupedReactions.angry?.length}</div>
            )}
        </div>
    )
}
