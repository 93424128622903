import * as React from 'react'
import * as ReactDOM from 'react-dom'
import ReactGA from 'react-ga4'
import {
    PublicClientApplication,
    EventType,
    EventMessage,
    AuthenticationResult,
    LogLevel,
} from '@azure/msal-browser'

import App from './App'
import { getAppConfig } from './Config'
import reportWebVitals from './reportWebVitals'
import AuthImg from './components/webComponent/AuthImage'

import '@fontsource/roboto'
import 'tippy.js/dist/tippy.css'
import './index.css'

if (process.env.NODE_ENV === 'development') {
    import('./mocks/browser').then(m => {
        const { worker } = m
        worker.start()
    })
}
const config = getAppConfig()

if (process.env.NODE_ENV === 'production') {
    ReactGA.initialize([
        {
            trackingId: config.gaTrackingId,
        },
    ])
}

// <MsalInstanceSnippet>
const msalInstance = new PublicClientApplication({
    auth: {
        clientId: config.appId,
        redirectUri: config.redirectUri,
        authority: config.authority,
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: true,
        secureCookies: true,
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message)
                        return
                    case LogLevel.Info:
                        //         console.info(message)
                        return
                    case LogLevel.Verbose:
                        //        console.debug(message)
                        return
                    case LogLevel.Warning:
                        //        console.warn(message)
                        return
                }
            },
        },
    },
})

// Check if there are already accounts in the browser session
// If so, set the first account as the active account
const accounts = msalInstance.getAllAccounts()
if (accounts && accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0])
}

msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        // Set the active account - this simplifies token acquisition
        const authResult = event.payload as AuthenticationResult
        msalInstance.setActiveAccount(authResult.account)
    }
})
// </MsalInstanceSnippet>

ReactDOM.render(
    <React.StrictMode>
        <App pca={msalInstance} />
    </React.StrictMode>,
    document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

// Register auth-image as custom webcomponent
window.customElements.define('auth-img', AuthImg, {extends: 'img'});
