import {
    ChevronDownIcon,
    ChevronUpIcon,
    ExternalLinkIcon,
    PaperAirplaneIcon,
    XIcon,
} from '@heroicons/react/solid'
import { useState } from 'react'
import { MessagesContext } from './MessageContext'
import { MessageMembersAvatar } from './MessageMembersAvatar'
import { MessageMembersTitle } from './MessageMembersTitle'
import { Messages } from './Messages'
import { Chat } from './MessagesList'

type conversationProps = {
    chatId: Chat[`id`]
}

export const Conversation: React.FC<conversationProps> = ({
    chatId,
}: conversationProps) => {
    const [message, setMessage] = useState<string>('')

    return (
        <MessagesContext.Consumer>
            {({
                closeChat,
                maximizeChat,
                minimizeChat,
                ctxChat,
                sendMessage,
            }) => {
                return (
                    <div className="flex flex-col justify-start items-center w-72 bg-white dark:bg-gray-900 dark:text-white shadow rounded-t mx-1 py-1 pb-0 transition-all z-50">
                        <div className=" flex flex-row justify-between items-center px-1 cursor-pointer w-full border-b dark:border-gray-700 pb-1">
                            <div className="flex flex-row justify-start items-center hover:bg-gray-100 dark:hover:bg-gray-800  transition-all rounded p-1 pr-2 ">
                                <MessageMembersAvatar
                                    className="w-8 h-8 mr-2"
                                    chat={ctxChat(chatId)}
                                />
                                <p className="font-bold text-gray-700 capitalize">
                                    {ctxChat(chatId).topic ?? (
                                        <MessageMembersTitle
                                            members={ctxChat(chatId).members}
                                            lastMessage={
                                                ctxChat(chatId).messages[0] ??
                                                []
                                            }
                                            chatType={ctxChat(chatId).chatType}
                                        />
                                    )}
                                </p>
                            </div>
                            <div className="flex flex-row justify-center items-center">
                                <a
                                    href={`https://teams.microsoft.com/_#/conversations/${chatId}?ctx=chat`}
                                    target="_blank"
                                >
                                    <ExternalLinkIcon className="w-5 h-5 mr-1 text-gray-600 border border-transparent hover:border-gray-200 rounded hover:bg-gray-200" />
                                </a>

                                {ctxChat(chatId).minimized ? (
                                    <ChevronUpIcon
                                        onClick={() => {
                                            maximizeChat(chatId)
                                        }}
                                        className="w-6 h-6 mr-1 text-gray-600 border border-transparent hover:border-gray-200 rounded hover:bg-gray-200"
                                    />
                                ) : (
                                    <ChevronDownIcon
                                        onClick={() => {
                                            minimizeChat(chatId)
                                        }}
                                        className="w-6 h-6 mr-1 text-gray-600 border border-transparent hover:border-gray-200 rounded hover:bg-gray-200"
                                    />
                                )}

                                <XIcon
                                    onClick={() => {
                                        closeChat(chatId)
                                    }}
                                    className="w-5 h-5 mr-2 text-gray-600 border-2 border-transparent hover:border-gray-200 rounded hover:bg-gray-200"
                                />
                            </div>
                        </div>
                        {!ctxChat(chatId).minimized && (
                            <div className="w-72 h-full flex flex-col content-end items-center">
                                <Messages chatId={chatId} />
                                <form
                                    onSubmit={e => {
                                        e.preventDefault()
                                        sendMessage(chatId, message)
                                        setMessage('')
                                    }}
                                    className="w-full flex flex-row content-between items-center border-t dark:border-gray-700 p-1 "
                                >
                                    <input
                                        className="w-11/12 bg-gray-100 dark:bg-gray-700 rounded border-0 ring-2 ring-transparent focus:ring-2 focus:ring-primary focus:bg-gray-200 outline-none text-sm transition-all"
                                        type="text"
                                        placeholder="Aa"
                                        value={message}
                                        onChange={e => {
                                            setMessage(e.target.value)
                                        }}
                                    />
                                    <button
                                        type="submit"
                                        className="w-9 h-9 m-1 mr-0 flex justify-center items-center cursor-pointer rounded transition-all hover:bg-orange-100 dark:hover:bg-deep-orange-900  focus:bg-orange-100 outline-none"
                                    >
                                        <PaperAirplaneIcon className="w-5 h-5 text-primary transform rotate-45" />
                                    </button>
                                </form>
                            </div>
                        )}
                    </div>
                )
            }}
        </MessagesContext.Consumer>
    )
}
